import {
  MOST_RECENT_HEALTH_DATA,
  UPDATE_HEALTH_DATA,
  GET_PATIENT_MED_HISTORY,
  UPDATE_PATIENT_MED_HISTORY,
} from '../actions/types';
import {
  mapHealthDataTypeNumToVarName,
  mapDiabetesTypeStringToNum,
  mapDRPresenceTypeStringtoNum,
  mapMEPresenceTypeStringtoNum,
} from '../utils/conversions';
import {
  DIABETES_ONSET,
  DIABETES_TYPE,
  DIABETES_TYPE_UNKNOWN,
  DR_PRESENCE,
  MACULAR_EDEMA_PRESENCE,
} from '../constants/medicalRecords';

const initialState = {
  mostRecentHealthData: {},
  medHistory: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MOST_RECENT_HEALTH_DATA:
      return {
        ...state,
        mostRecentHealthData: { ...action.payload },
      };

    case UPDATE_HEALTH_DATA:
      const healthDataStored = state.mostRecentHealthData[Object.keys(action.payload)[0]];
      let newData = { ...state.mostRecentHealthData };
      if (
        !healthDataStored?.recorded_date ||
        healthDataStored?.recorded_date === '-' ||
        new Date(healthDataStored.recorded_date) <=
          new Date(action.payload[Object.keys(action.payload)[0]].recorded_date)
      )
        newData = Object.assign({ ...state.mostRecentHealthData }, action.payload);
      return {
        ...state,
        mostRecentHealthData: newData,
      };
    case GET_PATIENT_MED_HISTORY:
      let newMedHistory = { ...action.payload };
      for (const key of Object.keys(newMedHistory)) {
        // receives string from backend, hence need to convert to its constant value
        // but backend doesnt provide string for unknown (empty) diabetes type, it provides the UNKNOWN key
        if (parseInt(key) === DIABETES_TYPE && parseInt(newMedHistory[key].values) !== DIABETES_TYPE_UNKNOWN) {
          newMedHistory[key].values = mapDiabetesTypeStringToNum(newMedHistory[key].values);
        } else if (parseInt(key) === DR_PRESENCE) {
          newMedHistory[key].values = mapDRPresenceTypeStringtoNum(newMedHistory[key].values);
        } else if (parseInt(key) === MACULAR_EDEMA_PRESENCE) {
          newMedHistory[key].values = mapMEPresenceTypeStringtoNum(newMedHistory[key].values);
        } else {
          if (newMedHistory[key].values === '-') newMedHistory[key].values = null;
          else if (parseInt(key) === DIABETES_ONSET && newMedHistory[key].values !== null) {
            newMedHistory[key].values = new Date(newMedHistory[key].values);
          }
        }
        // change the key name (from number to variable name)
        delete Object.assign(newMedHistory, { [mapHealthDataTypeNumToVarName(key)]: newMedHistory[key] })[key];
      }
      return {
        ...state,
        medHistory: { ...newMedHistory },
      };
    case UPDATE_PATIENT_MED_HISTORY:
      let updatedMedHistory = { ...state.medHistory };
      Object.keys(action.payload).forEach((medHistoryPropNum) => {
        let dataToUpdate = action.payload[medHistoryPropNum];
        if (parseInt(medHistoryPropNum) === DIABETES_TYPE)
          dataToUpdate.values = mapDiabetesTypeStringToNum(dataToUpdate.values);
        if (parseInt(medHistoryPropNum) === DR_PRESENCE)
          dataToUpdate.values = mapDRPresenceTypeStringtoNum(dataToUpdate.values);
        if (parseInt(medHistoryPropNum) === MACULAR_EDEMA_PRESENCE)
          dataToUpdate.values = mapMEPresenceTypeStringtoNum(dataToUpdate.values);
        if (parseInt(medHistoryPropNum) === DIABETES_ONSET) dataToUpdate.values = Date.parse(dataToUpdate.values);
        Object.assign(updatedMedHistory, { [mapHealthDataTypeNumToVarName(medHistoryPropNum)]: dataToUpdate });
      });
      return {
        ...state,
        medHistory: { ...updatedMedHistory },
      };
    default:
      return state;
  }
}
