import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Modal from '../../components/layout/templates/Modal';
import { ArrowRightIcon, GreenCheckIcon, PlusIcon, RedXIcon } from '../../resources/icons';
import { FormControl, FormGroup, Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { DR_RISK, SERVICES_DESCR, SERVICE_STR_ENUM, SERVICES_WITH_ICON, STDR_RISK } from '../../constants/constants';
import YellowCheckbox from '../../components/layout/atoms/YellowCheckbox';
import ServiceFormControlLabel from '../../components/layout/atoms/ServiceFormControlLabel';
import { handleModalOpen } from '../../actions/controls';
import LargeButton from '../../components/layout/atoms/LargeButton';
import InfoToolTip from '../../components/layout/atoms/InfoToolTip';
import NestedList from '../../components/layout/atoms/NestedList';
import { useTranslation } from 'react-i18next';

const PREFIX = 'AddRiskService';

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
  switch: `${PREFIX}-switch`,
  modal: `${PREFIX}-modal`,
  buttonPlacement: `${PREFIX}-buttonPlacement`,
  spacing: `${PREFIX}-spacing`,
  marginLeft: `${PREFIX}-marginLeft`,
  riskParams: `${PREFIX}-riskParams`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`&.${classes.container}`]: {
    flexGrow: 1,
    alignItems: 'stretch',
    overflow: 'hidden',
  },

  [`&.${classes.form}`]: {
    margin: theme.spacing(2),
  },

  [`&.${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`&.${classes.submit}`]: {
    width: '30%',
  },

  [`&.${classes.switch}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`&.${classes.modal}`]: {
    fullWidth: true,
  },

  [`&.${classes.buttonPlacement}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
    marginBottom: '20px',
  },

  [`&.${classes.spacing}`]: {
    marginTop: '10px',
    marginBottom: '25px',
  },

  [`&.${classes.marginLeft}`]: {
    marginLeft: theme.spacing(1),
  },

  [`&.${classes.riskParams}`]: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
}));

const AddRiskService = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  const renderSubmitButton = (visible) => (
    <div>
      <LargeButton color="blue" onClick={props.submit} disabled={visible}>
        {staticWords.Confirm} &nbsp; <ArrowRightIcon />
      </LargeButton>
    </div>
  );

  // Render tool tip when the confirm button is disabled
  const renderTooltipWithButton = () => {
    // If there are no services selected tell the user they have to select one
    if (props.checkedServices.size === 0) {
      return (
        <InfoToolTip
          title={staticWords.Submission_not_allowed_}
          content={staticWords.Please_select_a_service_to_continue_}
        >
          {renderSubmitButton(true)}
        </InfoToolTip>
      );
    } else {
      // If we are here the submit button should be enabled
      return renderSubmitButton(false);
    }

    // else if (props. < total oSpend) {
    //     return (
    //         <InfoToolTip
    //             title="Insufficient cre_dits."
    //             content="Please top up your  account to purchase these services."
    //         >
    //             {renderSubmitButton(true)}
    //         </InfoToolTip>
    //     )
  };

  // Render whether the patient has the necessary medical information
  const renderRisk = (service, med_parameter) => {
    if (med_parameter != 'Error') {
      return (
        <Grid container direction="row" spacing={1} key={med_parameter} className={classes.riskParams}>
          <Grid item>
            {/* if the patient has the medical information display gren check otherwise red x */}
            {props.serviceDisableMessage.get(service)[med_parameter] ? <RedXIcon /> : <GreenCheckIcon />}
          </Grid>
          <Grid item>
            <Typography>{med_parameter}</Typography>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <StyledModal
      title={staticWords.Add_Risk_Prediction}
      icon={<PlusIcon />}
      modalName="AddRiskService"
      description={staticWords.You_may_select_more_services_to_add_to_calculate_different_risks_}
      descriptionSpacing={classes.spacing}
      maxWidth="md"
    >
      <FormControl component="fieldset">
        <FormGroup row>
          <Grid container className={classes.container} spacing={3}>
            {/* Render each service for selection, enable the service if all the necessary parameters are present */}
            {[STDR_RISK, DR_RISK].map((service) => {
              return (
                <Grid item xs={12} sm={6} key={service}>
                  <ServiceFormControlLabel
                    key={service}
                    buttonCheck={props.checkedServices.has(service)}
                    checked={props.checkedServices.has(service)}
                    control={
                      <YellowCheckbox
                        checked={props.checkedServices.has(service)}
                        onChange={(e) => props.handleChange(e, service)}
                        disabled={props.disabledServices?.has(service)}
                      />
                    }
                    logo={SERVICES_WITH_ICON?.[service]}
                    disabled={props.serviceDisable.has(service)}
                    label={SERVICE_STR_ENUM[service]}
                    subLabel={props.servicePrices && props.servicePrices[service]?.units}
                    toolTipContent={SERVICES_DESCR[service].content}
                    toolTipTitle={SERVICES_DESCR[service].title}
                  />
                </Grid>
              );
            })}

            <Grid container item xs={12} className={classes.marginLeft}>
              <Grid item xs={12} sm={12} className={classes.marginLeft}>
                <Typography className={classes.text}>
                  {staticWords.Medical_History_and_Health_Data_required_to_run_each_service_}
                </Typography>
              </Grid>
              {/* Render the parameters if they are not all present */}
              {[STDR_RISK, DR_RISK].map((service) => {
                return (
                  <Grid item xs={12} sm={6} key={service}>
                    {/* Only render parameter information if at least one is missing otherwise null is rendered */}
                    <NestedList
                      label={SERVICE_STR_ENUM[service]}
                      listItem={
                        props.serviceDisableMessage.has(service)
                          ? Object.keys(props.serviceDisableMessage.get(service)).map((med_parameter) => {
                              return renderRisk(service, med_parameter);
                            })
                          : null
                      }
                    ></NestedList>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      <div className={classes.buttonPlacement}>{renderTooltipWithButton()}</div>
    </StyledModal>
  );
};

AddRiskService.propTypes = {
  patientDetails: PropTypes.object,
  checkedServices: PropTypes.instanceOf(Set).isRequired,
  servicePrices: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  serviceDisable: PropTypes.instanceOf(Set),
  serviceDisableMessage: PropTypes.instanceOf(Map),
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  handleModalOpen,
})(AddRiskService);
