import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import * as loadingAnimationData from '../../../../resources/animations/files/loading_darker.json';
import { useLottie } from 'lottie-react';

const Root = styled('div', {
  shouldForwardProp(propName) {
    return !['isFullScreen', 'isFullViewport', 'marginTop'].includes(propName);
  },
})(({ isFullScreen = false, isFullViewport = false, marginTop = 'auto' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: isFullScreen ? '100vh' : isFullViewport ? '60vh' : 'auto',
  margin: marginTop,
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const sizeToPx = {
  lg: 100,
  md: 80,
  sm: 50,
  xs: 30,
};

const Loading = (props) => {
  const { size = 'lg' } = props;

  const LoadingStyle = {
    height: sizeToPx[size],
    width: sizeToPx[size],
    margin: 'auto',
  };

  const { View } = useLottie(defaultOptions, LoadingStyle);
  return (
    <Root {...props} aria-label="progressbar">
      {View}
    </Root>
  );
};

Loading.propTypes = {
  isFullScreen: PropTypes.bool,
  isFullViewport: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

export default Loading;
