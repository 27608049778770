import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';

import logger from 'redux-logger';

const initialState = {};

let devTools = true;
let getMiddleware = (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false });

// development or testing
if (!process.env.REACT_APP_STAGE || ['development', 'test'].includes(process.env.REACT_APP_STAGE)) {
  getMiddleware = (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger);
}
if (process.env.REACT_APP_STAGE === 'production') {
  devTools = false;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getMiddleware,
  devTools: devTools,
  preloadedState: initialState,
});

export default store;
