import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Card from '../../../components/layout/atoms/Card';
import Modal from '../../../components/layout/templates/Modal';
import { CheckSquareIcon, UploadCloudIcon } from '../../../resources/icons';
import { transferValueGetKey } from '../../../utils/helpers';
import {
  ASSIGNED,
  AUDIT_SVC,
  EXAM_ORDERED,
  POOLED,
  RETINO_SCAN_PLUS_STR,
  RETINO_SCAN_PACKAGES,
  RETINO_SCAN_STR,
  REVIEW_SUSPECTED_CASES,
} from '../../../constants/constants';
import theme from '../../../styles/theme';

const ConfirmExamModal = (props) => {
  const {
    orderedServices,
    selectedAuditorFullname,
    imagesCount,
    onSubmit,
    selectedAuditorId,
    currentSubscription,
    billing,
    modalName = 'ConfirmPurchase',
    examStatus,
    auditAccessType,
  } = props;

  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const { t, i8n } = useTranslation();

  const packageType = currentSubscription?.price?.product?.name?.includes('Premium')
    ? RETINO_SCAN_PLUS_STR
    : RETINO_SCAN_STR;
  const serviceLabels = [packageType, REVIEW_SUSPECTED_CASES];
  const servicePackagesMapToLabels = [...RETINO_SCAN_PACKAGES, [AUDIT_SVC]];

  // filter out labels for exam services not ordered
  const orderedServiceLabels = serviceLabels.filter((label, index) =>
    servicePackagesMapToLabels[index].every((service) => orderedServices?.includes(service))
  );
  const balance =
    billing?.total_exam_balance === 'unlimited'
      ? staticWords[transferValueGetKey(billing.total_exam_balance)]
      : billing?.total_exam_balance || 0;

  const renderServiceDetails = (title, description) => (
    <Grid item container>
      <Grid item xs={5}>
        <Typography component="span" variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7} justifyContent="space-between">
        <Typography variant="body1" color={description === 0 ? 'warning.dark' : 'textPrimary'} align="right">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      title={staticWords.Order_Summary}
      icon={<CheckSquareIcon />}
      modalName={modalName}
      onSubmit={(e) => onSubmit(e)}
      buttonText={staticWords.Submit_for_Grading}
    >
      <Card
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          boxShadow: 'none',
          padding: (theme) => theme.spacing(3),
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              1 x {staticWords.Eye_Exam}
            </Typography>
          </Grid>
          {orderedServiceLabels.map((label, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant="subtitle1" color="#8988a8d4">
                <span>1 x </span>
                {staticWords[transferValueGetKey(label)]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Grid container mt={2}>
        {renderServiceDetails(staticWords.Eye_Images, imagesCount)}
        {!!orderedServices?.includes(AUDIT_SVC) &&
          auditAccessType === ASSIGNED &&
          renderServiceDetails(
            staticWords.Reviewed_by,
            selectedAuditorId === POOLED
              ? staticWords.The_next_available_eye_reviewer
              : `Dr. ${selectedAuditorFullname}`
          )}
        <Grid item xs={12}>
          <Divider sx={{ marginY: (theme) => theme.spacing(2) }} />
        </Grid>
        <Grid item xs={12}>
          {renderServiceDetails(staticWords.Current_Balance, balance)}
          {renderServiceDetails(staticWords.Balance_after_Order, typeof balance === 'number' ? balance - 1 : balance)}
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmExamModal.propTypes = {
  orderedServices: PropTypes.array,
  servicePrices: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  imagesCount: PropTypes.number,
  modalName: PropTypes.oneOf(['ConfirmPurchase', 'ConfirmSubmission']),
  selectedAuditorId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentSubscription: state.subscriptions?.currentSubscription,
  examStatus: state?.exams?.exam_details?.process?.status,
  billing: state.subscriptions.billing,
});

export default connect(mapStateToProps)(ConfirmExamModal);
