import React from 'react';
import { styled } from '@mui/material/styles';
import { ListItemText, ListItem, ListItemIcon, List, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AlertTriangleIcon } from '../../../resources/icons';

const PREFIX = 'NestedList';
const classes = {};

const StyledList = styled(List)(({ theme }) => ({}));

const NestedList = (props) => {
  const { listItem, label, missingParameters } = props;
  const [open, setOpen] = React.useState(true);

  const handleClick = (e) => {
    setOpen({ [e]: !open[e] });
  };

  return (
    <StyledList>
      <ListItem button key={listItem} onClick={handleClick.bind(open, listItem)}>
        {/* <ListItemIcon>
                    <AlertTriangleIcon />
                </ListItemIcon> */}
        <ListItemText primary={label} />
        {!open[listItem] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={!open[listItem]} timeout="auto" unmountOnExit>
        {listItem}
      </Collapse>
    </StyledList>
  );
};

// NestedList.propTypes = {
//     listItem: PropTypes.isRequired,
// }

export default NestedList;
