import { combineReducers } from 'redux';
import { AUTH_ERROR, LOGIN_FAIL, LOGOUT_SUCCESS } from '../actions/types';

import patients from './patients';
import auth from './auth';
import controls from './controls';
import exams from './exams';
import overview from './overview';
import audits from './audits';
import healthData from './healthData';
import payments from './payments';
import manageUsers from './manageUsers';
import subscriptions from './subscriptions';
import handlingTranslation from './handlingTranslation';
const appReducer = combineReducers({
  patients,
  auth,
  controls,
  overview,
  exams,
  audits,
  healthData,
  payments,
  manageUsers,
  subscriptions,
  handlingTranslation,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if ([LOGOUT_SUCCESS, AUTH_ERROR, LOGIN_FAIL].includes(action.type)) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
