import React from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import theme from '../../../styles/theme';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />, {
  shouldForwardProp: (propName) => !['maxWidth'].includes(propName),
})(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth ? maxWidth : '300px',
    borderRadius: theme.shape.borderRadiusMedium,
    padding: theme.spacing(2),
    color: 'inherit',
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

//leave open unless it is required for it to be open=false (closed by default)
const InfoToolTip = (props) => {
  return (
    <StyledTooltip
      {...props}
      arrow
      maxWidth={props.maxWidth}
      placement={props.placement}
      title={
        <>
          {props.title && (
            <Typography variant="h4" gutterBottom>
              {props.title}
            </Typography>
          )}
          {props.content}
        </>
      }
      {...props.conditional}
    >
      <Grid>{props.children}</Grid>
    </StyledTooltip>
  );
};

InfoToolTip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  open: PropTypes.bool,
};

export default InfoToolTip;
