import { Alert, Snackbar } from '@mui/material';

const AlertSnackBar = (props) => {
  const { autoHideDuration, open, onClose, elevation, severity, message } = props;
  return (
    <Snackbar
      sx={{
        '& .MuiAlert-root': { color: 'white' },
      }}
      open={open}
      autoHideDuration={5000 || autoHideDuration}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert elevation={6 || elevation} variant="filled" severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default AlertSnackBar;
