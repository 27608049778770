import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const getVariant = (theme, variant) => {
  switch (variant) {
    case 'warning':
      return {
        border: `2px solid ${theme.palette.warning.main}`,
      };
    case 'outline':
      return {
        border: theme.outline.card,
      };
    case 'thicker':
      return {
        border: theme.outline.thicker,
      };
    default:
      return {};
  }
};

const Root = styled('div', {
  shouldForwardProp(propName) {
    return !['customPadding', 'obviousShadowed', 'dark', 'size', 'border', 'maxWidth'].includes(propName);
  },
})(({ theme, customPadding, obviousShadowed, dark, size, variant, maxWidth, disabled }) => ({
  width: size === 'fill' ? '100%' : 'auto',
  height: size === 'fill' && '100%',
  backgroundColor: dark ? theme.palette.grey[100] : theme.palette.common.white,
  borderRadius: dark ? theme.shape.borderRadiusMedium : theme.shape.borderRadiusLarge,
  padding: customPadding ? customPadding : theme.spacing(2),
  boxShadow: obviousShadowed ? theme.shadows[1] : theme.shadows[0],
  maxWidth: maxWidth ? maxWidth : '100%',
  position: 'relative',
  pointerEvents: disabled ? 'none' : 'auto',

  ...getVariant(theme, variant),
}));

const Card = (props) => {
  const { customPadding, obviousShadowed, dark, size, variant, style, maxWidth, sx, ...rest } = props;
  return (
    <Root
      style={style}
      customPadding={customPadding}
      obviousShadowed={obviousShadowed}
      dark={dark}
      size={size}
      variant={variant}
      maxWidth={maxWidth}
      sx={sx}
      {...rest}
      disabled={props.disabled}
    >
      {props.children}
    </Root>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  obviousShadowed: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  dark: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  noPadding: PropTypes.bool,
  customPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // maxWidth: PropTypes.number | PropTypes.string,
};

export default Card;
