import { customLanguagestyles } from '../translation/translationUiStyles';
import { applyTranslationToValues } from '../utils/helpers';
import i18n from 'i18next';

const initialState = {
  styles: customLanguagestyles[i18n.resolvedLanguage] || customLanguagestyles.en,
  words: applyTranslationToValues(),
  message: false,
};
export default function handlingTranslation(state = initialState, action) {
  switch (action.type) {
    case 'en':
      return {
        ...state,
        styles: customLanguagestyles.en,
        words: applyTranslationToValues(),
      };
    case 'de':
      return {
        ...state,
        styles: customLanguagestyles.de,
        words: applyTranslationToValues(),
      };
    case 'langCode': {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
