import React from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import StatusTag from '../atoms/StatusTag';
import SmallButton from '../atoms/SmallButton';
import { ChevronDownIcon, DownloadCloudIcon } from '../../../resources/icons';
import {
  AWAITING_RESULT,
  GENDER_FEMALE,
  GENDER_MALE,
  PENDING_ACTION,
  PENDING_REVIEW,
  REPORT_READY,
} from '../../../constants/constants';

import maleAvatar from '../../../resources/icons/files/male-avatar.svg';
import femaleAvatar from '../../../resources/icons/files/female-avatar.svg';
import otherAvatar from '../../../resources/icons/files/other-avatar.svg';

const PREFIX = 'ExamsAccordion';

const classes = {
  summary: `${PREFIX}-summary`,
  accordion: `${PREFIX}-accordion`,
  urgent: `${PREFIX}-urgent`,
  expanded: `${PREFIX}-expanded`,
};

const Root = styled('div', {
  shouldForwardProp(propName) {
    return !['expandable'].includes(propName);
  },
})(({ theme, expandable, selected }) => ({
  margin: `${theme.spacing(2)} 0`,
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadiusLarge,

  '& .MuiAccordion-root': {
    '& .MuiButtonBase-root.MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: expandable ? 'flex' : 'none',
      },
    },
  },

  // Classnames for Accordion component
  [`& .${classes.summary}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.accordion}`]: {
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadiusLarge,
    backgroundColor: selected && `${theme.palette.background.dark} !important`,

    '&:hover': {
      backgroundColor: theme.palette.background.grey,
      boxShadow: 'none',
    },

    '&$expanded': {
      boxShadow: theme.shadows[8],
      margin: 'auto',
    },
  },

  [`& .${classes.urgent}`]: {
    margin: theme.spacing(0.5),
    boxShadow: `0px 0px 3px ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadiusLarge,
    backgroundColor: selected ? `${theme.palette.background.dark} !important` : theme.palette.urgent.main,

    '&:hover': {
      backgroundColor: theme.palette.background.grey,
      boxShadow: 'none',
    },

    '&$expanded': {
      boxShadow: `0px 0px 3px ${theme.palette.error.dark}`,
      margin: 'auto',
    },
  },
}));

const ExamsAccordion = ({ expandable = true, ...props }) => {
  const { words: staticWords, styles: lanStyle } = useSelector((state) => state.handlingTranslation);

  return (
    <Root
      expandable={expandable}
      selected={props.selected}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseLeave={props.onMouseLeave}
    >
      <Accordion
        classes={{
          root: props.urgent ? classes.urgent : classes.accordion,
          expanded: classes.expanded,
        }}
        expanded={expandable ? undefined : false}
      >
        <AccordionSummary
          expandIcon={props.urgent ? null : <ChevronDownIcon />}
          classes={{ content: classes.summary }}
          aria-controls="exam-content"
          id="exam-header"
          disabled={props.auditorIsViewing}
        >
          <Grid container wrap="nowrap" m={1}>
            {props.avatar && (
              <Grid item container alignItems="center" mr={2} sx={{ maxWidth: 40 }}>
                {
                  <Avatar
                    alt="avatar"
                    src={
                      props.avatar === GENDER_MALE
                        ? maleAvatar
                        : props.avatar === GENDER_FEMALE
                          ? femaleAvatar
                          : otherAvatar
                    }
                  />
                }
              </Grid>
            )}
            <Grid container item justifyContent="space-between" alignItems="center">
              <Grid
                container
                item
                alignItems="center"
                xs={props.urgent ? 12 : lanStyle.component_ExamAccordion.All_Exams?.xs?.sectionOne} // Review date and patient name takes up full width for urgent exams
                xl={lanStyle.component_ExamAccordion.All_Exams?.xl?.sectionOne}
              >
                <Grid
                  item
                  xs={12}
                  md={props.urgent ? 12 : lanStyle.component_PatientExams.label_1.width}
                  alignItems="center"
                >
                  {/* Date */}
                  <Typography variant="subtitle1">{props.dateString}</Typography>
                </Grid>
                {props.patientFullName && (
                  <Grid item xs={12} md={props.urgent ? 12 : lanStyle.component_PatientExams.label_2.width}>
                    {/* Patient Full Name */}
                    <Typography fontWeight="bold">{props.patientFullName}</Typography>
                  </Grid>
                )}
                {props.operator && (
                  <Grid container item xs={12} md={lanStyle.component_PatientExams.label_2.width}>
                    {/* Practitioner */}
                    <Typography fontWeight="bold" textTransform="capitalize">
                      {props.operator.first_name || '-'}
                      {props.operator.branch && ` (${props.operator.branch})`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {props.status && (
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  xs={lanStyle.component_ExamAccordion.All_Exams?.xs?.sectionTwo}
                  xl={lanStyle.component_ExamAccordion.All_Exams?.xl?.sectionTwo}
                >
                  {/* Status */}
                  {<StatusTag status={props.status} />}
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="center">
            <SmallButton disabled noMargin endIcon={<DownloadCloudIcon />}>
              {staticWords.Download_Report}
            </SmallButton>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

ExamsAccordion.propTypes = {
  dateString: PropTypes.string.isRequired,
  status: PropTypes.oneOf([PENDING_ACTION, AWAITING_RESULT, REPORT_READY, PENDING_REVIEW]),
  onClick: PropTypes.func.isRequired,
  patientAccountNumber: PropTypes.string,
  patientFullName: PropTypes.string,
};

export default ExamsAccordion;
