export const missingTranslation = {
  en: {
    Admin: 'Admin',
    GP: 'GP',
    MARKETING: 'MARKETING',
    Successfully_logged_in_: 'Successfully logged in!',
    Processing: 'Processing',
    Canceled: 'Canceled',
    QA_not_assigned_to_image_: 'QA not assigned to image.',
    Thank_you_for_verifying_your: 'Thank you for verifying your',
    Please_enter_your_e_mail_: 'Please enter your e-mail.',
    Assigned_reviews: 'Assigned reviews',
    resubmit: 'resubmit',
    Cases_that_must_be_referred_to_a_reviewer_in_a_week_or_earlier__as_recommended_in_the_eye_screening_audit_:
      'Cases that must be referred to a reviewer in a week or earlier, as recommended in the eye screening audit.',
    Ref_No_: 'Ref No.',
    medicare_reference_NO_: 'medicare reference No.',
    Invalid_Phone_Number: 'Invalid Phone Number',
    PENDING: 'PENDING',
    NOT_ADDED: 'NOT ADDED',
    Comment: 'Comment',
    Send_Report_Link: 'Send Report Link',
    Nikon: 'Nikon',
    Topcon: 'Topcon',
    DRS: 'DRS',
    TeleEye_Scan: 'TeleEye Scan',
    Upload_and_select_images_to_be_sent_for_grading__with_several:
      'Upload and select images to be sent for grading with several',
    Pending_for_image_upload: 'Pending for image upload',
    Waiting_for_results_from_services: 'Waiting for results from services',
    Pending_for_eye_professional_review: 'Pending for eye professional review',
    Report_ready_to_download: 'Report ready to download',
    Service_hasn_t_been_selected: "Service hasn't been selected",
    _Add_on_screenings_will_be_valid_for_3_months__If_you_upgrade_your_subscriptions__your_add_on_screenings_will_be_removed_:
      '*Add-on screenings will be valid for 3 months. If you upgrade your subscriptions, your add-on screenings will be removed.',
    Add_free_credits_to_individual_accounts__users_that_are_not_a_part_of_any_organisations__below_:
      'Add free credits to individual accounts (users that are not a part of any organisations) below.',
    Please_wait: 'Please wait',
    before_trying_again: 'before trying again',
    Email_last_sent_: 'Email last sent:',
    SMS_last_sent: 'SMS last sent:',
    American_Academy_of_Ophthalmology__AAO__Standard: 'American Academy of Ophthalmology (AAO) Standard',
    Findings_Observable_upon_Dilated_Ophthalmoscopy: 'Findings Observable upon Dilated Ophthalmoscopy',
    Severity_Level: 'Severity Level',
    International_Clinical_Diabetic_Macular_Edema_Disease_Severity_Scale:
      'International Clinical Diabetic Macular Edema Disease Severity Scale',
    International_Clinical_Diabetic_Retinopathy_Disease_Severity_Scale:
      'International Clinical Diabetic Retinopathy Disease Severity Scale',
    No_abnormalities: 'No abnormalities',
    Microaneurysms_only: 'Microaneurysms only',
    More_than_just_microaneurysms_but_less_than_Severe_NPDR: 'More than just microaneurysms but less than Severe NPDR',
    Any_of_the_following: 'Any of the following',
    More_than_20_intraretinal_hemorrhages_in_each_of_4_quadrants:
      'More than 20 intraretinal hemorrhages in each of 4 quadrants',
    Definite_venous_beading_in_2__quadrants: 'Definite venous beading in 2+ quadrants',
    Prominent_IRMA_in_1__quadrant: 'Prominent IRMA in 1+ quadrant',
    And_no_signs_of_proliferative_retinopathy: 'And no signs of proliferative retinopathy',
    One_or_more_of_the_following: 'One or more of the following',
    No_retinal_thickening_or_hard_exudates_in_posterior_pole:
      'No retinal thickening or hard exudates in posterior pole',
    Some_retinal_thickening_or_hard_exudates_in_posterior_pole_but_distant_from_the_center_of_the_macula:
      'Some retinal thickening or hard exudates in posterior pole but distant from the center of the macula',
    Retinal_thickening_or_hard_exudates_approaching_the_center_of_the_macula_but_not_involving_the_center:
      'Retinal thickening or hard exudates approaching the center of the macula but not involving the center',
    Retinal_thickening_or_hard_exudates_involving_the_center_of_the_macula:
      'Retinal thickening or hard exudates involving the center of the macula',
    If_your_account_is_created_by_your_organisation_s_admin__you_are_able_to_log_in_now_Otherwise__you_would_need_to_wait_for_our_verification_and_approval_before_you_can_log_in_An_email_will_be_sent_to_you_once_your_account_has_been_approved:
      "If your account is created by your organisation's admin, you are able to log in now.Otherwise, you would need to wait for our verification and approval before you can log in.An email will be sent to you once your account has been approved.",
    Create_Password: 'Create Password',
    Diseases_Summary: 'Diseases Summary',
    check_OTP_usage_for_: 'check OTP usage for:',
    You_will_be_joining: 'You will be joining',
    Next_billed_on: 'Next billed on',
    Diabetic__Retinopathy: 'Diabetic\n Retinopathy',
    Type_or_paste_email_addresses_and_press: 'Type or paste email addresses and press',
    ENTER: 'ENTER',
    has_already_been_added_: 'has already been added.',
    is_not_a_valid_email_address_: 'is not a valid email address.',
    Comments_for_practitioners_and_reviewers__This_will_not_appear_on_the_patient_s_exam_report:
      'Comments for practitioners and reviewers. This will not appear on the patient’s exam report',
    Upload_up_to: 'Upload up to',
    images: 'images',
    per_eye__for_grading__ensuring_they_meet_the: 'per eye) for grading, ensuring they meet the',
    one: 'one',
    two: 'two',
    four: 'four',
    specified_requirements: 'specified requirements',
    No_image_upload_limit_found_for_the_region: 'No image upload limit found for the region',
    Please_upload_images_to_continue: 'Please upload images to continue',
    image: 'image',
    per_eye_is_allowed_for_submission: 'per eye is allowed for submission',
    Only_a_maximum_of: 'Only a maximum of',
    You_can_only_upload_a_maximum_of: 'You can only upload a maximum of',
    Manage_Subscriptions: 'Manage Subscriptions',
    You_have_successfully_subscribed_to: 'You have successfully subscribed to',
    You_can_manage_your_subscriptions_here_: 'You can manage your subscriptions here.',
    Only: 'Only',
    Diabetic_Retinopathy__DR__Severity: 'Diabetic Retinopathy (DR) Severity',
    Diabetic_Retinopathy__DR__: 'Diabetic Retinopathy (DR)',
    Site_Language: 'Site Language',
    By_default__Mild_NPDR_is_Non_Referable__Select_Referable_if_necessary_:
      'By default, Mild NPDR is Non-Referable. Select Referable if necessary.',
    Prac_: 'Prac.',
    Patient: 'Patient',
    OPTOM: 'OPTOM',
    Rev_: 'Rev.',
    Org_Ad: 'Org Ad',
    TMC_Ad: 'TMC Ad',
    Review_Date: 'Review Date',
    Patient_Account_No_: 'Patient Account No.',
    Successfully_added_new_data_of: 'Successfully added new data of',
    Successfully_updated_medical_history_: 'Successfully updated medical history.',
    No_matching_patients_records_found_: 'No matching patients records found.',
    Month: 'Month',
    Year: 'Year',
    Invoice: 'Invoice',
    Date: 'Date',
    Total_Amount: 'Total_Amount',
    Email_is_required: 'Email is required',
    Invalid_Email_Address: 'Invalid Email Address',
    Required: 'Required',
    Cardholder_Name: 'Cardholder Name',
    Credit: 'Credit',
    Bank: 'Bank',
    Try_again: 'Try again',
    Qty: 'Qty',
    Exam_Plans: 'Exam Plans',
    Billing: 'Billing',
    Purchase_Summary: 'Purchase Summary',
    Glaucoma_and_AMD_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Glaucoma and AMD indications are solely based on independent Healthcare Provider review.',
    Diabetic_Macular_Edema_and_Media_Opacity_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Diabetic Macular Edema and Media Opacity indications are solely based on independent Healthcare Provider review.',
    Clinical_investigational_use_only: 'Clinical investigational use only',
    Disclaimer: 'Disclaimer',
    The_clinician_must_review_the_AI_results_below__make_an_independent_clinical_assessment__and_adjust_the_results_accordingly_:
      'The clinician must review the AI results below, make an independent clinical assessment, and adjust the results accordingly.',
    The_final_clinical_assessment_was_completed_by_a_clinician_and_is_not_reliant_upon_any_AI_:
      'The final clinical assessment was completed by a clinician and is not reliant upon any AI.',
    Monthly_Subscription: 'Monthly Subscription',
    Prepaid: 'Prepaid',
    Payment_Success: 'Payment Success',
    Payment_Unsuccessful: 'Payment Unsuccessful',
    Neovascularization: 'Neovascularization',
    Vitreous_preretinal_hemorrhage: 'Vitreous/preretinal hemorrhage',
    ///// Payment gateway texts start /////
    Diabetic_Retinopathy_detection_Nonreferable_Referable_result:
      'Diabetic Retinopathy detection (Non-referable / Referable result)',
    Exam_Report_provided_to_patient_by_Health_Care_Professional:
      'Exam Report provided to patient by Health Care Professional',
    Diabetic_Retinopathy_Glaucoma_and_AMD_detection_Nonreferable_Referable_result:
      'Diabetic Retinopathy, Glaucoma and AMD detection (Non-referable / Referable result)',
    Diabetic_Retinopathy_Severity_Grading: 'Diabetic Retinopathy Severity Grading',
    Facility_for_Eye_Health_Practitioner_to_review_and_verify_results:
      'Facility for Eye Health Practitioner to review and verify results',
    CHOOSE_AN_AI_PLAN: 'CHOOSE AN AI PLAN',
    PURCHASE_EXAMS: 'PURCHASE EXAMS',
    Onetime_payment_valid_6_months: 'One-time payment, valid 6 months',
    End_of_month_billing_cycle: 'End of month billing cycle',
    Exams: 'Exams',
    month: 'month',
    Please_select_an_AI_Plan_above_before_continuing: 'Please select an AI Plan above before continuing.',
    Choose_your_preferred_prepaid_amount: 'Choose your preferred prepaid amount:',

    Choose_your_preferred_monthly_plan: 'Choose your preferred monthly plan*:',
    _A_minimum_subscription_period_of_3_months_is_required_for_first_time_purchase:
      '* A minimum subscription period of 3 months is required for first time purchase.',
    PURCHASE: 'PURCHASE',
    Monthly_Subscription_2_Exams_150month_GST: 'Monthly Subscription* 2 Exams - $150/month + GST',
    Monthly_Subscription_2_Exams: 'Monthly Subscription - 2 Exams',
    GST: 'GST',
    Total_onetime_payment: 'Total (one-time payment)',
    CONTINUE_TO_PAYMENT: 'CONTINUE TO PAYMENT',
    UPDATE_SUBSCRIPTION: 'UPDATE SUBSCRIPTION',
    OFF: 'OFF',
    Prepaid_2_Exams_4500_GST: 'Prepaid 2 Exams - $4500 + GST',
    Prepaid_2_Exams: 'Prepaid - 2 Exams',
    Total_per_month: 'Total (per month)',
    Go_to: 'Go to',
    Your_payment_was_not_successful_This_may_be_due_to_one_of_the_following_reasons:
      'Your payment was not successful. This may be due to one of the following reasons:',
    Insufficient_funds: 'Insufficient funds',
    Expired_card: 'Expired card',
    Payment_verification_failure: 'Payment verification failure',
    Network_Issues: 'Network Issues',
    TRY_AGAIN: 'TRY AGAIN',
    Payment_Successful: 'Payment Successful',
    Prepaid_250_Exams_2375_GST: 'Prepaid 250 Exams - $2375 + GST',
    OKAY: 'OKAY',
    UNPAID: 'UNPAID',
    PAID: 'PAID',
    OVERDUE: 'OVERDUE',
    CANCELLED: 'CANCELLED',
    CURRENT_EXAM_PLANS: 'CURRENT EXAM PLAN(S)',
    Manage_Subscription: 'Manage Subscription',
    ACTIVE: 'ACTIVE',
    Exam_Balance: 'Exam Balance',
    Purchase_Prepaid: 'Purchase Prepaid',
    Total_Exam_Balance: 'Total Exam Balance',
    No_active_monthly_subscriptions_plans_found: 'No active monthly subscriptions plans found.',
    No_prepaid_plans_found: 'No prepaid plans found.',
    PAYMENT_FAILED: 'PAYMENT FAILED',
    DEACTIVATED: 'DEACTIVATED',
    Incomplete: 'Incomplete',
    Well_retry_payment_every_5_days_Please_update_your_billing_information_here:
      '(We’ll retry payment every 5 days. Please update your billing information here.)',
    Your_subscription_is_currently_deactivated_due_to_a_failed_payment:
      'Your subscription is currently deactivated due to a failed payment',
    FREE_TRIAL: 'FREE TRIAL',
    NO_PURCHASE_HISTORY: 'NO PURCHASE HISTORY',
    You_do_not_have_any_purchase_history_to_display_Once_you_complete_a_transaction_it_will_appear_here:
      'You do not have any purchase history to display. Once you complete a transaction, it will appear here.',
    Purchase_Exam_Plans: 'Purchase Exam Plans',
    YOUR_AI_PLAN: 'YOUR AI PLAN',
    mth: 'mth',
    INCOMPLETE: 'INCOMPLETE',
    PAUSED: 'PAUSED',
    INCOMPLETE_EXPIRED: 'INCOMPLETE EXPIRED',
    DRAFT: 'DRAFT',
    Your_last_transaction_failed__Please_review_and_complete_the_payment:
      'Your last transaction failed. Please review and complete the payment',
    __For_help__contact: '. For help, contact',
    Invoice_No: 'Invoice No.',
    Invoice_Date: 'Invoice Date',
    Exam_Plan: 'Exam Plan',
    Amount: 'Amount',
    Monthly: 'Monthly',
    Download: 'Download',
    Pay_now: 'Pay now',
    Okay: 'Okay',
    we_will_retry_payment_every_5_days_: '(we will retry payment every 5 days)',
    here: 'here',
    This_link_will_remain_active_for_30_minutes__After_that__the_transaction_will_be_canceled__and_a_new_purchase_will_need_to_be_initiated_:
      'This link will remain active for 30 minutes. After that, the transaction will be canceled, and a new purchase will need to be initiated.',
    Billing_history_is_not_displayed_in_test_mode_: 'Billing history is not displayed in test mode.',

    Your_payment_for_Monthly_Subscription_has_been_successful__You_can_view_your_invoices_in:
      'Your payment for Monthly Subscription has been successful. You can view your invoices in',
    Plans_are_not_displayed_in_test_mode_: 'Plans are not displayed in test mode.',
    There_was_a_problem_of_exam_plans__Please_try_again___: 'There was a problem of exam plans. Please try again...',
    Your_subscription_has_been_canceled__please_contact_us_at:
      'Your subscription has been canceled, please contact us at',
    ///// Payment gateway texts end //////
    //// Payment customize keys start ////
    First_bill_: 'First bill:',
    on: 'on',
    Expires: 'Expires:',
    Price_listed_is_in: 'Price listed is in ',
    _and_does_not_include_GST_Applicable_taxes_will_be_added:
      ' and does not include GST. Applicable taxes will be added.',
    Purchased_on_: 'Purchased on:',
    Next_bill_: 'Next bill:',
    Your_current_monthly_subscription_plan_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Your current monthly subscription plan is active. If you would like to make any changes to your subscription, please contact us at',
    Your_monthly_subscription_free_trial_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Your monthly subscription free trial is active. If you would like to make any changes to your subscription, please contact us at',
    Trial_ends_: 'Trial ends',
    Your_subscription_will_be_deactivated_if_it_is_not_paid_before:
      'Your subscription will be deactivated if it is not paid before',
    Please_try_again_If_the_problem_persists_contact_us_on: 'Please try again. If the problem persists, contact us on',
    for_further_assistance: 'for further assistance.',
    Your_payment_for_Prepaid_Exams_has_been_successful_You_can_view_your_invoices_in:
      'Your payment for Prepaid Exams has been successful. You can view your invoices in',

    //// Payment customize keys end ////
    ///// About Page start ////
    About: 'About',
    DR_Grader_and__GCAM_Instructions_of_Use__IFU__Manuals_: 'DR Grader and +GCAM Instructions of Use (IFU) Manuals:',
    View_IFUs: 'View IFUs',
    Name_of_Devices: 'Name of Devices',
    _GCAM: '+GCAM',
    Intended_Purpose: 'Intended Purpose',
    DRS01: 'DRS01',
    GCS01: 'GCS01',
    DR_Grader_is_a_medical_device: 'DR Grader is a medical device',
    _GCAM_is_a_medical_device: '+GCAM is a medical device',
    _01_860007668609_8012__v1_0_1: '(01)860007668609(8012) v1.0.1',
    _01_860007668647_8012__v1_0_1: '(01)860007668647(8012) v1.0.1',
    IFU_is_translated_to_German: 'IFU is translated to German',
    Manufacturer: 'Manufacturer',
    Date_of_Manufacturer_: 'Date of Manufacturer:',
    Country_of_Manufacture_: 'Country of Manufacture:',
    //// Customize keys start ////
    The_DR_Grading_software_is_intended:
      'The DR Grading software is intended to screen and grade Diabetic Retinopathy (DR) in patients/general population through colour fundus retinal images. The DR Grader is indicated for use by health care providers (HCP) to automatically detect and grade diabetic retinopathy in patients who have been diagnosed with diabetes and screen those who have not yet been diagnosed with diabetes.',
    _GCAM_is_software_as_a_medical_device:
      '+GCAM is software as a medical device intended as an aid to a healthcare provider’s (HCP) decision-making in the screening fundus images for probable Glaucoma and Age-related Macular Degeneration (AMD). The software will provide a referable / non-referable recommendation based on the signs detected. The screening result is not intended to be a confirmatory diagnosis or to be definitive in nature. The screening allows for further investigation by the healthcare provider.',
    Sherwood_Road_Toowong_: 'Suite 5, 31 Sherwood Road, Toowong,',
    Brisbane_Queensland_: 'Brisbane, Queensland',
    __Australia: 'Australia',
    Erdkampsweg_43_22335_Hamburg: 'Erdkampsweg 43;22335 Hamburg',
    Phone___61_416220239: 'Phone: +61 416220239',

    //// Customize keys end ////
    ///// About Page end ////
    Are_you_sure_you_want_to_submit_review__The_eye_exam_review_is_not_editable_after_submission_:
      'Are you sure you want to submit review? The eye exam review is not editable after submission.',
    Are_you_sure_you_want_to_submit_exam__The_eye_exam_is_not_editable_after_submission_:
      'Are you sure you want to submit exam? The eye exam is not editable after submission.',
  },
  de: {
    Admin: 'Verwaltung',
    GP: 'GP',
    MARKETING: 'MARKETING',
    Successfully_logged_in_: 'Erfolgreich angemeldet!',
    Processing: 'Verarbeitung',
    Canceled: 'Storniert',
    QA_not_assigned_to_image_: 'Bildzuweisung nicht erfolgt.',
    Thank_you_for_verifying_your: 'Vielen Dank für Ihre Verifizierung',
    Please_enter_your_e_mail_: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    Assigned_reviews: 'Zugewiesene Überprüfungen',
    resubmit: 'erneut einreichen',
    Cases_that_must_be_referred_to_a_reviewer_in_a_week_or_earlier__as_recommended_in_the_eye_screening_audit_:
      'Fälle, die innerhalb einer Woche oder früher an einen Prüfer verwiesen werden müssen, wie im Augenscreening-Audit empfohlen.',
    Ref_No_: 'Referenznummer.',
    medicare_reference_NO_: 'medicare-referenznummer.',
    Invalid_Phone_Number: 'Ungültige Telefonnummer',
    PENDING: 'AUSSTEHENDE',
    NOT_ADDED: 'NICHT HINZUGEFÜGT',
    Comment: 'Kommentar',
    Send_Report_Link: 'Link zum Bericht senden',
    Nikon: 'Nikon',
    Topcon: 'Topcon',
    DRS: 'DRS',
    TeleEye_Scan: 'TeleEye Scan',
    Upload_and_select_images_to_be_sent_for_grading__with_several:
      'Hochladen und Auswahl von Bildern, die zur Bewertung gesendet werden sollen, mit mehreren',
    Pending_for_image_upload: 'Ausstehend für Bildupload',
    Waiting_for_results_from_services: 'Warten auf Ergebnisse von Diensten',
    Pending_for_eye_professional_review: 'Ausstehend zur Überprüfung durch Augenfachpersonal',
    Report_ready_to_download: 'Bericht zum Download bereit',
    Service_hasn_t_been_selected: 'Der Dienst wurde nicht ausgewählt',
    _Add_on_screenings_will_be_valid_for_3_months__If_you_upgrade_your_subscriptions__your_add_on_screenings_will_be_removed_:
      '*Zusatzscreenings sind 3 Monate gültig. Wenn Sie Ihr Abonnement upgraden, werden Ihre Zusatzscreenings entfernt.',
    Add_free_credits_to_individual_accounts__users_that_are_not_a_part_of_any_organisations__below_:
      'Fügen Sie einzelnen Konten kostenlose Credits hinzu (Benutzer, die keiner Organisation angehören) unten.',
    Please_wait: 'Bitte warten',
    before_trying_again: 'bevor Sie es erneut versuchen',
    Email_last_sent_: 'Zuletzt gesendete E-Mail:',
    SMS_last_sent: 'Zuletzt gesendete SMS:',
    American_Academy_of_Ophthalmology__AAO__Standard: 'Standard der Amerikanischen Akademie für Ophthalmologie (AAO)',
    Findings_Observable_upon_Dilated_Ophthalmoscopy: 'Befunde, beobachtbar bei dilatierter Ophthalmoskopie',
    Severity_Level: 'Schweregrad',
    International_Clinical_Diabetic_Macular_Edema_Disease_Severity_Scale:
      'Internationale klinische Skala der Schwere von diabetischem Makulaödem',
    International_Clinical_Diabetic_Retinopathy_Disease_Severity_Scale:
      'Internationale klinische Skala der Schwere von diabetischer Retinopathie',
    No_abnormalities: 'Keine Auffälligkeiten',
    Microaneurysms_only: 'Nur Mikroaneurysmen',
    More_than_just_microaneurysms_but_less_than_Severe_NPDR:
      'Mehr als nur Mikroaneurysmen, aber weniger als schwere NPDR',
    Any_of_the_following: 'Eines der folgenden Befunde',
    More_than_20_intraretinal_hemorrhages_in_each_of_4_quadrants:
      'Mehr als 20 intraretinale Blutungen in jedem der 4 Quadranten',
    Definite_venous_beading_in_2__quadrants: 'Deutliches venöses Beading in 2+ Quadranten',
    Prominent_IRMA_in_1__quadrant: 'Auffällige IRMA in 1+ Quadrant',
    And_no_signs_of_proliferative_retinopathy: 'Und keine Anzeichen von proliferativer Retinopathie',
    One_or_more_of_the_following: 'Eines oder mehrere der folgenden Befunde',
    No_retinal_thickening_or_hard_exudates_in_posterior_pole:
      'Keine Netzhautverdickung oder harte Exsudate im hinteren Pol',
    Some_retinal_thickening_or_hard_exudates_in_posterior_pole_but_distant_from_the_center_of_the_macula:
      'Einige Netzhautverdickung oder harte Exsudate im hinteren Pol, aber entfernt vom Zentrum der Makula',
    Retinal_thickening_or_hard_exudates_approaching_the_center_of_the_macula_but_not_involving_the_center:
      'Netzhautverdickung oder harte Exsudate nähern sich dem Zentrum der Makula, ohne das Zentrum zu involvieren',
    Retinal_thickening_or_hard_exudates_involving_the_center_of_the_macula:
      'Netzhautverdickung oder harte Exsudate, die das Zentrum der Makula betreffen',
    If_your_account_is_created_by_your_organisation_s_admin__you_are_able_to_log_in_now_Otherwise__you_would_need_to_wait_for_our_verification_and_approval_before_you_can_log_in_An_email_will_be_sent_to_you_once_your_account_has_been_approved:
      'Wenn Ihr Konto von dem Admin Ihrer Organisation erstellt wurde, können Sie sich jetzt einloggen. Andernfalls müssen Sie auf unsere Verifizierung und Genehmigung warten, bevor Sie sich einloggen können. Eine E-Mail wird Ihnen gesendet, sobald Ihr Konto genehmigt wurde.',
    Create_Password: 'Passwort erstellen',
    Diseases_Summary: 'Krankheitszusammenfassung',
    check_OTP_usage_for_: 'Überprüfen Sie die OTP-Nutzung für:',
    You_will_be_joining: 'Sie werden teilnehmen',
    Next_billed_on: 'Nächste Rechnungsstellung am',
    Diabetic__Retinopathy: 'Diabetische Retinopathie',
    Type_or_paste_email_addresses_and_press: 'Geben Sie E-Mail-Adressen ein oder fügen Sie sie ein und drücken Sie',
    ENTER: 'EINGABETASTE',
    has_already_been_added_: 'wurde bereits hinzugefügt.',
    is_not_a_valid_email_address_: 'ist keine gültige E-Mail-Adresse.',
    Comments_for_practitioners_and_reviewers__This_will_not_appear_on_the_patient_s_exam_report:
      'Kommentare für Praktiker und Prüfer. Dies wird nicht im Untersuchungsbericht des Patienten erscheinen',
    Upload_up_to: 'Laden Sie bis zu',
    images: 'Bilder hoch',
    per_eye__for_grading__ensuring_they_meet_the: 'pro Auge) zur Bewertung, stellen Sie sicher, dass sie die',
    one: 'eins',
    two: 'zwei',
    four: 'vier',
    specified_requirements: 'spezifizierte Anforderungen',
    No_image_upload_limit_found_for_the_region: 'Kein Bildupload-Limit für die Region gefunden',
    Please_upload_images_to_continue: 'Bitte laden Sie Bilder hoch, um fortzufahren',
    image: 'Bild',
    per_eye_is_allowed_for_submission: 'pro Auge ist für die Einreichung erlaubt',
    Only_a_maximum_of: 'Nur ein Maximum von',
    You_can_only_upload_a_maximum_of: 'Sie können maximal',
    Manage_Subscriptions: 'Abonnements verwalten',
    You_have_successfully_subscribed_to: 'You have successfully subscribed to',
    You_can_manage_your_subscriptions_here_: 'You can manage your subscriptions here.',
    Only: 'Nur',
    Diabetic_Retinopathy__DR__Severity: 'Diabetische Retinopathie (DR) Schweregrad',
    Diabetic_Retinopathy__DR__: 'Diabetische Retinopathie (DR)',
    Site_Language: 'Sprache',
    By_default__Mild_NPDR_is_Non_Referable__Select_Referable_if_necessary_:
      'Standardmäßig ist „Mild NPDR“ nicht referenzierbar. Wählen Sie bei Bedarf „Referenzierbar“ aus.',
    Prac_: 'Ba',
    Patient: 'Patient',
    OPTOM: 'OPTOM',
    Rev_: 'Übr.',
    Org_Ad: 'Org Ad',
    TMC_Ad: 'TMC Ad',
    Review_Date: 'Überprüfungsdatum',
    Patient_Account_No_: 'Patientenkonto-Nr.',
    Successfully_added_new_data_of: 'Neue Daten von erfolgreich hinzugefügt',
    Successfully_updated_medical_history_: 'Krankengeschichte erfolgreich aktualisiert.',
    No_matching_patients_records_found_: 'Es wurden keine passenden Patientendatensätze gefunden.',
    Month: 'Monat',
    Year: 'Jahr',
    Invoice: 'Rechnung',
    Date: 'Datum',
    Total_Amount: 'Gesamtbetrag',
    Email_is_required: 'E-Mail ist erforderlich',
    Invalid_Email_Address: 'Ungültige E-Mail-Adresse',
    Required: 'Erforderlich',
    Cardholder_Name: 'Name des Karteninhabers',
    Credit: 'Guthaben',
    Bank: 'Bank',
    Try_again: 'Erneut versuchen',
    Qty: 'Menge',
    Exam_Plans: 'Prüfungspläne',
    Billing: 'Abrechnung',
    Glaucoma_and_AMD_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Glaukom- und AMD-Diagnosen beruhen ausschließlich auf der Überprüfung durch unabhängige Gesundheitsdienstleister.',
    Diabetic_Macular_Edema_and_Media_Opacity_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Die Angaben zum diabetischen Makulaödem und zur Medientrübung beruhen ausschließlich auf der Überprüfung durch unabhängige Gesundheitsdienstleister.',
    Clinical_investigational_use_only: 'Nur für klinische Untersuchungen',
    Disclaimer: 'Haftungsausschluss',
    The_clinician_must_review_the_AI_results_below__make_an_independent_clinical_assessment__and_adjust_the_results_accordingly_:
      'Der Arzt muss die unten aufgeführten AI-Ergebnisse überprüfen, eine unabhängige klinische Bewertung vornehmen und die Ergebnisse entsprechend anpassen.',
    The_final_clinical_assessment_was_completed_by_a_clinician_and_is_not_reliant_upon_any_AI_:
      'Die abschließende klinische Bewertung wurde von einem Arzt durchgeführt und beruht nicht auf einer künstlichen Intelligenz.',
    Neovascularization: 'Neovaskularisation',
    Vitreous_preretinal_hemorrhage: 'Glaskörper-/präretinale Blutung',

    ////// Payment gateway texts start /////
    Monthly_Subscription: 'Monatliches Abonnement',
    Prepaid: 'Vorausbezahlt',
    Payment_Success: 'Zahlung erfolgreich',
    Payment_Unsuccessful: 'Zahlung nicht erfolgreich',
    Diabetic_Retinopathy_detection_Nonreferable_Referable_result:
      'Erkennung der diabetischen Retinopathie (nicht überweisbares / überweisbares Ergebnis)',
    Exam_Report_provided_to_patient_by_Health_Care_Professional:
      'Der Untersuchungsbericht wird dem Patienten vom Gesundheitsdienstleister ausgehändigt',
    Diabetic_Retinopathy_Glaucoma_and_AMD_detection_Nonreferable_Referable_result:
      'Erkennung von diabetischer Retinopathie, Glaukom und AMD (nicht überweisbares / überweisbares Ergebnis)',
    Diabetic_Retinopathy_Severity_Grading: 'Einstufung des Schweregrads der diabetischen Retinopathie',
    Facility_for_Eye_Health_Practitioner_to_review_and_verify_results:
      'Möglichkeit für den Augenarzt, die Ergebnisse zu überprüfen und zu verifizieren',
    CHOOSE_AN_AI_PLAN: 'WÄHLEN SIE EINEN KI-PLAN',
    PURCHASE_EXAMS: 'PRÜFUNGEN KAUFEN',
    Onetime_payment_valid_6_months: 'Einmalige Zahlung, gültig 6 Monate',
    End_of_month_billing_cycle: 'Ende des monatlichen Abrechnungszyklus',
    Exams: 'Messungen',
    month: 'monat',
    Please_select_an_AI_Plan_above_before_continuing: 'Bitte wählen Sie oben einen KI-Plan aus, bevor Sie fortfahren.',
    Choose_your_preferred_prepaid_amount: 'Wählen Sie den gewünschten vorausbezahlten Betrag:',
    Choose_your_preferred_monthly_plan: 'Wählen Sie Ihren bevorzugten Monatstarif*:',
    _A_minimum_subscription_period_of_3_months_is_required_for_first_time_purchase:
      '* Für den Erstkauf ist eine Mindestabonnementdauer von 3 Monaten erforderlich.',
    PURCHASE: 'KAUFEN',
    Monthly_Subscription_2_Exams_150month_GST: 'Monatsabonnement* 2 Prüfungen - $150/Monat + GST',
    Monthly_Subscription_2_Exams: 'Monatsabonnement - 2 Prüfungen',
    GST: 'MwSt',
    Total_onetime_payment: 'Insgesamt (einmalige Zahlung)',
    CONTINUE_TO_PAYMENT: 'WEITER ZUR ZAHLUNG',
    UPDATE_SUBSCRIPTION: 'UPDATE-ABONNENMENT',
    OFF: 'AUS',
    Prepaid_2_Exams_4500_GST: 'Vorausbezahlte 2 Prüfungen - $4500 + Mehrwertsteuer',
    Prepaid_2_Exams: 'Vorausbezahlt - 2 Prüfungen',
    Total_per_month: 'Insgesamt (pro Monat)',
    Go_to: 'Gehe zu',
    Your_payment_was_not_successful_This_may_be_due_to_one_of_the_following_reasons:
      'Ihre Zahlung war nicht erfolgreich. Dies kann einen der folgenden Gründe haben:',
    Insufficient_funds: 'Unzureichende Mittel',
    Expired_card: 'Abgelaufene Karte',
    Payment_verification_failure: 'Zahlungsüberprüfung fehlgeschlagen',
    Network_Issues: 'Netzwerk-Probleme',

    TRY_AGAIN: 'WIEDERHOLEN',
    Payment_Successful: 'Zahlung erfolgreich',
    Prepaid_250_Exams_2375_GST: 'Vorausbezahlte 250 Prüfungen - $2375 + Mehrwertsteuer',
    OKAY: 'OKAY',
    UNPAID: 'UNBEZAHLT',
    PAID: 'BEZAHLT',
    OVERDUE: 'ÜBERFÄLLIG',
    CANCELLED: 'ABGEBROCHEN',
    CURRENT_EXAM_PLANS: 'AKTUELLE(R) PRÜFUNGSPLAN(E',
    Manage_Subscription: 'Abonnement verwalten',
    ACTIVE: 'AKTIV',
    Exam_Balance: 'Prüfungsbilanz',
    Purchase_Prepaid: 'Kauf im Voraus bezahlt',
    Total_Exam_Balance: 'Prüfungsbilanz insgesamt',
    No_active_monthly_subscriptions_plans_found: 'Keine aktiven Monatsabonnements gefunden.',
    No_prepaid_plans_found: 'Keine Prepaid-Pläne gefunden.',
    PAYMENT_FAILED: 'ZAHLUNG FEHLGESCHLAGEN',
    DEACTIVATED: 'DEAKTIVIERT',
    Incomplete: 'Unvollständig',
    Well_retry_payment_every_5_days_Please_update_your_billing_information_here:
      '(Wir werden die Zahlung alle 5 Tage wiederholen. Bitte aktualisieren Sie hier Ihre Rechnungsdaten).',
    Your_subscription_is_currently_deactivated_due_to_a_failed_payment:
      'Ihr Abonnement ist derzeit aufgrund einer fehlgeschlagenen Zahlung deaktiviert',
    FREE_TRIAL: 'KOSTENLOSE TESTVERSION',
    //// Payment still need to translate start ////
    Purchase_Summary: 'Zusammenfassung der Käufe',
    NO_PURCHASE_HISTORY: 'KEINE KAUFHISTORIEN',
    You_do_not_have_any_purchase_history_to_display_Once_you_complete_a_transaction_it_will_appear_here:
      'Sie haben keine Kaufhistorie, die Sie anzeigen können. Sobald Sie eine Transaktion abgeschlossen haben, wird sie hier angezeigt.',
    Purchase_Exam_Plans: 'Prüfungspläne kaufen',
    INCOMPLETE: 'UNVOLLSTÄNDIG',
    PAUSED: 'PAUSIERT',
    INCOMPLETE_EXPIRED: 'UNVOLLSTÄNDIG ABGELAUFEN',
    DRAFT: 'ENTWURF',
    This_link_will_remain_active_for_30_minutes__After_that__the_transaction_will_be_canceled__and_a_new_purchase_will_need_to_be_initiated_:
      'Dieser Link bleibt für 30 Minuten aktiv. Danach wird die Transaktion storniert und es muss ein neuer Kauf initiiert werden.',
    Billing_history_is_not_displayed_in_test_mode_: 'Die Abrechnungshistorie wird im Testmodus nicht angezeigt.',
    Download: 'Herunterladen',
    Pay_now: 'Jetzt bezahlen',

    YOUR_AI_PLAN: 'IHR KI-PLAN',
    mth: 'Monat',
    Your_last_transaction_failed__Please_review_and_complete_the_payment:
      'Ihre letzte Transaktion ist fehlgeschlagen. Bitte überprüfen Sie die Zahlung und schließen Sie sie ab',
    __For_help__contact: '. Für Hilfe kontaktieren Sie',
    Okay: 'Okay',
    we_will_retry_payment_every_5_days_: '(Wir werden die Zahlung alle 5 Tage wiederholen)',
    here: 'hier',

    Your_payment_for_Monthly_Subscription_has_been_successful__You_can_view_your_invoices_in:
      'Ihre Zahlung für das Monatsabonnement war erfolgreich. Sie können Ihre Rechnungen einsehen in',
    Plans_are_not_displayed_in_test_mode_: 'Im Testmodus werden die Pläne nicht angezeigt.',
    There_was_a_problem_of_exam_plans__Please_try_again___:
      'Es gab ein Problem mit den Prüfungsplänen. Bitte versuchen Sie es erneut ...',
    Your_subscription_has_been_canceled__please_contact_us_at:
      'Ihr Abonnement wurde gekündigt, bitte kontaktieren Sie uns unter',

    //// Payment still need to translate end ////

    //// Payment customize keys start ////
    Expires: 'Läuft ab:',
    First_bill_: 'Erste Rechnung:',
    on: 'am ',
    Price_listed_is_in: 'Die Preise sind in ',
    _and_does_not_include_GST_Applicable_taxes_will_be_added:
      ' angegeben und enthalten keine Mehrwertsteuer. Anwendbare Steuern werden hinzugerechnet.',
    Purchased_on_: 'Gekauft am:',
    Next_bill_: 'Nächste Rechnung:',
    Your_current_monthly_subscription_plan_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Ihr derzeitiger monatlicher Abonnementplan ist aktiv. Wenn Sie Änderungen an Ihrem Abonnement vornehmen möchten, kontaktieren Sie uns bitte unter',
    Your_monthly_subscription_free_trial_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Ihr kostenloses monatliches Probeabonnement ist aktiv. Wenn Sie Änderungen an Ihrem Abonnement vornehmen möchten, kontaktieren Sie uns bitte unter',
    Trial_ends_: 'Probezeit endet',
    Invoice_No: 'Rechnung Nr.',
    Invoice_Date: 'Datum der Rechnung',
    Exam_Plan: 'Prüfungsplan',
    Amount: 'Betrag',
    Monthly: 'Monatlich',
    Your_subscription_will_be_deactivated_if_it_is_not_paid_before:
      'Ihr Abonnement wird deaktiviert, wenn es nicht vor dem',
    Please_try_again_If_the_problem_persists_contact_us_on:
      'Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an',
    for_further_assistance: 'um weitere Unterstützung zu erhalten.',
    Your_payment_for_Prepaid_Exams_has_been_successful_You_can_view_your_invoices_in:
      'Ihre Zahlung für Prepaid-Prüfungen war erfolgreich. Sie können Ihre Rechnungen einsehen in',
    //// Payment customize keys end ////

    ////// Payment gateway texts end //////
    ///// About Page start ////
    About: 'Über',
    DR_Grader_and__GCAM_Instructions_of_Use__IFU__Manuals_: 'DR Grader und +GCAM Gebrauchsanweisungen (IFU):',
    View_IFUs: 'IFUs anzeigen',
    Name_of_Devices: 'Name der Geräte',
    _GCAM: '+GCAM',
    Intended_Purpose: 'Verwendungszweck',
    DRS01: 'DRS01',
    GCS01: 'GCS01',
    DR_Grader_is_a_medical_device: 'DR Grader ist ein Medizinprodukt',
    _GCAM_is_a_medical_device: '+GCAM ist ein Medizinprodukt',
    _01_860007668609_8012__v1_0_1: '(01)860007668609(8012) v1.0.1',
    _01_860007668647_8012__v1_0_1: '(01)860007668647(8012) v1.0.1',
    IFU_is_translated_to_German: 'IFU wird ins Deutsche übersetzt',
    Manufacturer: 'Hersteller',
    Date_of_Manufacturer_: 'Datum des Herstellers:',
    Country_of_Manufacture_: 'Land der Herstellung:',
    //// Customize keys start ////
    The_DR_Grading_software_is_intended:
      'Die DR-Grading-Software ist für das Screening und die Einstufung der diabetischen Retinopathie (DR) bei Patienten/der allgemeinen Bevölkerung anhand von Farbfundus-Netzhautbildern vorgesehen. Der DR Grader ist für die Verwendung durch Gesundheitsdienstleister (HCP) zur automatischen Erkennung und Einstufung der diabetischen Retinopathie bei Patienten, bei denen Diabetes diagnostiziert wurde, und zur Untersuchung von Patienten, bei denen noch kein Diabetes diagnostiziert wurde, vorgesehen.',
    _GCAM_is_software_as_a_medical_device:
      'Bei GCAM handelt es sich um eine Software als Medizinprodukt, das als Entscheidungshilfe für Gesundheitsdienstleister bei der Untersuchung von Fundusbildern auf wahrscheinliches Glaukom und altersbedingte Makuladegeneration (AMD) dienen soll. Die Software gibt auf der Grundlage der erkannten Anzeichen eine Empfehlung für eine Überweisung oder Nicht-Überweisung. Das Screening-Ergebnis ist nicht als Bestätigungsdiagnose gedacht und hat keinen definitiven Charakter. Das Screening ermöglicht eine weitere Untersuchung durch den Gesundheitsdienstleister.',
    Sherwood_Road_Toowong_: 'Suite 5, 31 Sherwood Road, Toowong,',
    Brisbane_Queensland_: 'Brisbane, Queensland',
    __Australia: '4066, Australien',
    Erdkampsweg_43_22335_Hamburg: 'Erdkampsweg 43;22335 Hamburg',
    Phone___61_416220239: 'Telefon: +61 416220239',

    //// Customize keys end ////
    ///// About Page end ////
    Are_you_sure_you_want_to_submit_review__The_eye_exam_review_is_not_editable_after_submission_:
      'Möchten Sie wirklich eine Bewertung abgeben? Die Bewertung zur Augenuntersuchung kann nach dem Absenden nicht mehr bearbeitet werden.',
    Are_you_sure_you_want_to_submit_exam__The_eye_exam_is_not_editable_after_submission_:
      'Möchten Sie die Untersuchung wirklich übermitteln? Die Augenuntersuchung kann nach der Übermittlung nicht mehr bearbeitet werden.',
  },
};

export const uiVisibleTexts = {
  default: [
    //// About Page start ////
    'Submit Review?',
    'Are you sure you want to submit review? The eye exam review is not editable after submission.',
    'Are you sure you want to submit exam? The eye exam is not editable after submission.',
    'Your subscription has been canceled, please contact us at',
    'DR Grader and +GCAM Instructions of Use (IFU) Manuals:',
    'View IFUs',
    'Name of Devices',
    '+GCAM',
    'Intended Purpose',
    'DRS01',
    'GCS01',
    'DR Grader is a medical device',
    '+GCAM is a medical device',
    '(01)860007668609(8012) v1.0.1',
    '(01)860007668647(8012) v1.0.1',
    'IFU is translated to German',
    'Manufacturer',
    'Date of Manufacturer:',
    'Country of Manufacture:',
    'The DR Grading software is intended to screen and grade Diabetic Retinopathy (DR) in patients/general population through colour fundus retinal images. The DR Grader is indicated for use by health care providers (HCP) to automatically detect and grade diabetic retinopathy in patients who have been diagnosed with diabetes and screen those who have not yet been diagnosed with diabetes.',
    '+GCAM is software as a medical device intended as an aid to a healthcare provider’s (HCP) decision-making in the screening fundus images for probable Glaucoma and Age-related Macular Degeneration (AMD). The software will provide a referable / non-referable recommendation based on the signs detected. The screening result is not intended to be a confirmatory diagnosis or to be definitive in nature. The screening allows for further investigation by the healthcare provider.',
    'Suite 5, 31 Sherwood Road, Toowong,',
    'Brisbane, Queensland',
    'Australia',
    'Erdkampsweg 43;22335 Hamburg',
    'Phone: +61 416220239',
    //// About Page end ////

    ////// Payment gateway texts start //////
    'There was a problem of exam plans. Please try again...',
    'Plans are not displayed in test mode.',
    'Your payment for Monthly Subscription has been successful. You can view your invoices in',
    'Billing history is not displayed in test mode.',
    'This link will remain active for 30 minutes. After that, the transaction will be canceled, and a new purchase will need to be initiated.',
    'Download',
    'Pay now',
    'Invoice No.',
    'Purchase Exam Plans',
    'You do not have any purchase history to display. Once you complete a transaction, it will appear here.',
    'NO PURCHASE HISTORY',
    'Diabetic Retinopathy detection (Non-referable / Referable result)',
    'Exam Report provided to patient by Health Care Professional',
    'Diabetic Retinopathy, Glaucoma and AMD detection (Non-referable / Referable result)',
    'Diabetic Retinopathy Severity Grading',
    'Facility for Eye Health Practitioner to review and verify results',
    'Exam Report provided to patient by Health Care Professional',
    'CHOOSE AN AI PLAN',
    'PURCHASE EXAMS',
    'Prepaid',
    'Monthly Subscription',
    'One-time payment, valid 6 months',
    'End of month billing cycle',
    'Exams',
    'month',
    'Please select an AI Plan above before continuing.',
    'Choose your preferred prepaid amount:',
    'Price listed is in SGD$ and does not include GST. Applicable taxes will be added.',
    'Choose your preferred monthly plan*:',
    '* A minimum subscription period of 3 months is required for first time purchase.',
    'PURCHASE',
    'Monthly',
    'Subscription',
    'GST',
    'First bill:',
    'on',
    'Monthly Subscription',
    'Total (one-time payment)',
    'CONTINUE TO PAYMENT',
    'UPDATE SUBSCRIPTION',
    'OFF',
    'Expires:',
    'Total (per month)',
    'Go to',
    'Your current monthly subscription plan is active. If you would like to make any changes to your subscription, please contact us at',
    'Your monthly subscription free trial is active. If you would like to make any changes to your subscription, please contact us at',
    'Payment Unsuccessful',
    'Your payment was not successful. This may be due to one of the following reasons:',
    'Insufficient funds',
    'Expired card',
    'Payment verification failure',
    'Network Issues',
    'Please try again. If the problem persists, contact us on support@telemedc.com for further assistance.',
    'TRY AGAIN',
    'Payment Successful',
    'Your payment for Prepaid Exams has been successful. You can view your invoices in',
    'OKAY',
    'UNPAID',
    'PAID',
    'OVERDUE',
    'CANCELLED',
    'CURRENT EXAM PLAN(S)',
    'Monthly Subscription',
    'Manage Subscription',
    'ACTIVE',
    'Exam Balance',
    'Purchase Prepaid',
    'Purchased on:',
    'Total Exam Balance:',
    'No active monthly subscriptions plans found.',
    'No prepaid plans found.',
    'PAYMENT FAILED',
    'DEACTIVATED',
    'Your subscription will be deactivated if it is not paid before',
    'Incomplete',
    '(We’ll retry payment every 5 days. Please update your billing information here.)',
    'Your subscription is currently deactivated due to a failed payment',
    'FREE TRIAL',
    '30 Exams - Trial ends Jun 22, 2024',
    'Exam Plans',
    'Billing',
    //// Payment gateway texts end //////
    'ADMIN',
    'REVIEWER',
    'GP',
    'MARKETING',
    'Successfully logged in!',
    'Payment Success',
    'Payment Unsuccessful',
    'Purchase Summary',
    'Prepaid',
    'Monthly Subscription',
    'Billing',
    'Exam Plans',
    'Qty',
    'Try again',
    'Credit',
    'Bank',
    'Cardholder Name',
    'Email is required',
    'Invalid Email Address',
    'Required',
    'Total Amount',
    'Date',
    'Invoice',
    'Year',
    'No matching patients records found.',
    'Successfully updated medical history.',
    'Successfully added new data of',
    'Prac.',
    'Patient',
    'OPTOM',
    'Rev.',
    'Org Ad',
    'TMC Ad',
    'Patient Account No.',
    'Review Date',
    'Gp',
    'By default, Mild NPDR is Non-Referable. Select Referable if necessary.',
    'Site Language',
    'Diabetic Retinopathy (DR) Severity',
    'Only',
    'Manage Subscriptions',
    'You have successfully subscribed to',
    'You can manage your subscriptions here.',
    'You can only upload a maximum of',
    'Only a maximum of',
    'per eye is allowed for submission',
    'image',
    'Please upload images to continue',
    'No image upload limit found for the region',
    'specified requirements',
    'Upload up to',
    'images',
    'per eye) for grading, ensuring they meet the',
    'one',
    'two',
    'four',
    'Processing',
    'Lost connection to server, please refresh the page',
    'Unable to load pooled reviews, please try again by refreshing the page.',
    'There was a problem retrieving the audit. Please contact us if this persists.',
    'There was a problem submitting the audit. Please contact us if this persists.',
    'There was a problem assigning the review. Please try to review other exams',
    'There was a problem logging in. Please try again later.',
    'The session is already ended.',
    'You have successfully signed up.',
    'There was a problem signing up. Please try again later.',
    'No email or phone number provided',
    'There was a problem checking the account. Please try again later.',
    'Successfully created!',
    'There was a problem when retrieving Terms and Conditions data. Please refresh to try again.',
    'Thank you for accepting the updated terms of use.',
    'There was a problem when accepting Terms and Conditions. Please contact us if it persists`.',
    'Invitation Link Copied! Valid for 24 hours.',
    'There was a problem joining this organisation, please contact your organisation admin.',
    'Email Verified!',
    'Email or Mobile Number is required',
    'There was a problem creating an exam. Please try again later.',
    'There was a problem retrieving exams. Please refresh the page to try again.',
    'There was a problem retrieving page number for exams, please try again.',
    'Uploading image',
    'Successfully uploaded',
    'There is a problem with uploading. Please refresh page and try again.',
    'There was a problem deleting image. Please try again later.',
    'disconnected websocket for exam',
    "There was a problem retrieving an image's information. Please try again later.",
    'There was a problem retrieving the order details.',
    'There was a problem when getting exam details. Please try to refresh the page.',
    'There was a problem retrieving urgent exams. Please refresh the page to try again.',
    'Please select at least one image to submit.',
    'Image(s) submitted for grading',
    'There was a problem when submitting images. Please try again.',
    'There was a problem assigning the laterality for the image, please try again.',
    'No services selected.',
    'Successfully submitted!',
    'There was a problem submitting for services. Please try again later.',
    'Successfully resubmitted!',
    'There was a problem when resubmitting. Please try again.',
    'There was a problem when submitting comments. Please try again.',
    'Loading report...',
    'Report successfully loaded!',
    'There was an error retrieving auditors. Please try again.',
    'There was an error retrieving health data. Please refresh the page to try again.',
    'There is a problem when adding new health data. Please try again later.',
    'There is a problem when updating medical history. Please try again later.',
    'There was a problem loading users. Please refresh the page to try again.',
    'There was a problem loading organisations. Please refresh the page to try again.',
    'There was a problem adding this organisation. Please try again later.',
    'Successfully approved.',
    'There was a problem approving this user. Please try again later.',
    'Successfully added free credits.',
    'There was a problem adding free credits. Please try again later.',
    'There was a problem retrieving meeting hosts. Please try again.',
    'There was a problem retrieving your contact information. Please try to refresh page.',
    'There was a problem updating your contact information. Please try again.',
    'failed to update contact information',
    'There was a problem retrieving meetings. Please try again.',
    'There was a problem retrieving history meetings. Please try again.',
    'There was a problem retrieving available slots. Please try again.',
    "There was a problem retrieving provider's first available date. Please try again.",
    'There was a problem retrieving provider schedule. Please try again.',
    'Meeting successfully canceled',
    'There was a problem when connecting to server. Please refresh page and try again',
    'There was a problem when canceling meeting. Please try again.',
    'The time is not available, please choose another time',
    'There was a problem when fetch new meeting details. Please try again.',
    'There was a problem when booking meeting. Please try again.',
    'Successfully rescheduled meeting',
    'There was a problem when rescheduling meeting. Please try again.',
    'Your comment has been successfully submitted.',
    'There something wrong when submitting comment, please try again later',
    'The meeting has ended',
    'There was a problem when enter meeting. Please try again.',
    'There was a problem when get meeting details. Please try again.',
    'There was a problem retrieving patients. Please try again.',
    'No patients found.',
    'There was a problem retrieving patient details. Please refresh the page to try again.',
    'Successfully updated your details.',
    'Successfully updated patient details.',
    'There is a problem when updating patient details. Please try again later.',
    "There was a problem when retrieving patient's exams. Please refresh the page to try again.",
    'There was a problem getting your details. Please try again later.',
    'There was a problem creating a new patient. Please try again later.',
    'There was a problem retrieving price groups. Please try again later.',
    'There was a problem retrieving your credits data. Please refresh the page to try again.',
    'There was a problem retrieving the service prices. Please refresh the page to try again.',
    'There was a problem retrieving your purchase history. Please refresh the page to try again.',
    'There was a problem retrieving your payment history. Please refresh the page to try again.',
    'Successfully added STDR Risk Prediction',
    'Successfully added DR Risk Prediction',
    'DR Risk Prediction report ready',
    'Successfully cancelled subscription. Please wait for a few seconds and page will reload automatically',
    'Successfully resumed subscription. Please wait for a few seconds and page will reload automatically',
    'Confirm',
    "We've Updated Our Terms",
    'In order to continue using TeleEye MD, you will need to agree with our latest Terms of Use.',
    'Terms of Use',
    'progressbar',
    'Login',
    'There was a problem connecting to the server. Please check your internet connection and try again.',
    '/scheduling/simply_book/auth/get_tokens',
    'Edit Comment',
    'Submit',
    'script',
    "Patient's eyes bleeding? Image blurry? Let the eye reviewer know by adding a comment!",
    'No comment left',
    "Operator's Comments",
    'Download Report',
    'QA',
    'No Result',
    'Which Eye?',
    'Loading...',
    'Left',
    'Right',
    'Upgrade for',
    'for',
    'Go to Subscriptions',
    'subtitle',
    'Prediction Occurrence:',
    'View Report',
    'Submit for Grading',
    'Order Services',
    'Submission not allowed.',
    'Please subscribe to a plan to start screening',
    'Images uploaded but not selected',
    'Please select images to submit for grading',
    'Free Trial',
    'Free Plan',
    'OPEN MOBILE APP',
    'Log Out',
    'Patient LookUp',
    'Available Exams:',
    'Account No.',
    'Terms',
    'Below is your',
    "Treat it as your identification number when going for any eye screening! You don't need to remember it, as it is also sent to your email.",
    "This is the patient's",
    'Sorry! This page does not exist.',
    'Back to Home',
    'Your password needs to contain at least 8 characters.',
    'Your password can’t be too similar to your other personal information.',
    'Your password can’t be a commonly used password.',
    'Your password can’t be entirely numeric.',
    'Coming soon',
    'This feature is currently still under production.',
    'Please look forward to our future releases!',
    'Cancel',
    'Go Back',
    'Unknown',
    'Visual Acuity (Uncorrected)',
    'Visual Acuity (Best Corrected)',
    'IOP (mmHg)',
    'HbA1c (%)',
    'Blood Pressure (mmHg)',
    'Albuminuria (mg/mmol)',
    'Diabetes Therapy',
    'Postprandial Blood Glucose (mg/dL)',
    'Triglyceride (mg/dL)',
    'Low Density Lipoprotein (mg/dL)',
    'No light perception (NLP)',
    'Light perception (LP)',
    'Hand movements (HM)',
    'Counting fingers at 1 meters (CF)',
    'Overview',
    'All Patients',
    'Register Patient',
    'Subscription',
    'Settings',
    'Pending Reviews',
    'Completed Reviews',
    'My TeleConsults',
    'My Exams',
    'My Profile',
    'Register User',
    'Invite User',
    'All Exams',
    'Approve Users',
    'Organisations',
    'According to Medicare',
    'Country of Service',
    'Medicare No.',
    'Post Code',
    'Ethnicity',
    'NRIC (Last 4 Characters)',
    'According to ID/Passport',
    'ID CARD (Personalausweis)',
    'Individual Health Identifier (IHI) number',
    'NHS Number',
    'Postcode',
    'ID Card Number',
    'REFER TO REPORT',
    'AI Grading...',
    'Not Applicable',
    'Unable to Grade',
    'Non-Referable',
    'Referable',
    'Not Urgent',
    'Urgent',
    'Resolved',
    'Normal',
    'Suspected',
    'Mild',
    'Moderate',
    'Severe',
    'Non-Significant',
    'Significant',
    'Mild NPDR',
    'Moderate NPDR',
    'Severe NPDR',
    'PDR',
    'Suspect DR Disease',
    'Suspect AMD Disease',
    'Suspect AMD disease (High probability AMD, some AMD signs)',
    'Suspect DR disease (High probability, some DR Signs)',
    'Click to update',
    'RetinoScan',
    "RetinoScan analyses retinal images to accurately identify patients' with Diabetic Retinopathy, Glaucoma and/or Age Related Macular Degeneration.",
    'RetinoScan Advanced',
    'For patients with a disease diagnosis, RetinoScan Advanced determines the disease severity and provide lesion monitoring services.',
    'Suspected Cases',
    'Review by Eye Professional',
    'Comprehensive Patient Report',
    'Class II Medical Device',
    'Clinically Validated',
    'Cloud Storage',
    'Patient Data Retention',
    "In accordance to HIPPA compliances, all patients' data will be stored and backed up for 15 years",
    '15 years',
    'Referral Pathway',
    'Coming Soon',
    'Forgot your password?',
    'We can send you an email with the link to reset it.',
    'E-mail',
    'SEND EMAIL',
    'Check your email',
    "If your email exist in our system,the reset link will be sent to your inbox.Please do also check if it's in your spam folder.",
    "Your password can't be entirely numeric.",
    'Invalid password',
    'Create new password',
    'Please enter a new password to reset your password.',
    'New Password',
    'toggle password visibility',
    'Confirm Password',
    'Passwords must be equal',
    'RESET PASSWORD',
    'Password Changed',
    'You have successfully changed your password! Try logging in now!',
    'Password Change Unsuccessful',
    'Sorry! There must be a problem on our side. Please contact customer support to report this problem.',
    'Wrong digit code. Try Again',
    'Enter your Email or Mobile number',
    'NEXT',
    'next',
    'Log in',
    'Login with your Email or Mobile No.',
    'Forgot Password',
    'LOGIN',
    "Don't have an account?",
    'Sign Up',
    'You may now log into your account with your email.',
    'You are already logged in.',
    'If you have already logged in, please log out first and refresh this page.If not, please contact our customer support to report this problem.',
    'LOG OUT',
    'success',
    'Activation Success!',
    'Activation Fail...',
    'Sorry! You might have already been activated, so try to log in. If not,please contact our customer service to report this problem.',
    'Please set your password so that you can use it to log into this platform.',
    'SET PASSWORD',
    'Password Successfully Set!',
    'You may now log into your account with your credentials.',
    'Password Set Unsuccessful...',
    'OTP Verification',
    'Enter the 6 digit code sent to:',
    "Didn't receive the code?",
    'Resend',
    'I am a ...',
    'Already have an account?',
    'Log In',
    'SIGN UP',
    'EMAIL',
    'MOBILE NUMBER',
    'VERIFIED',
    'email address',
    'mobile number',
    'Your TeleEye MD account is now activated.',
    'It will take up to 1 business day for TeleMedC admins to verify your details and activate your account.',
    'Save',
    'Edit Data',
    'Account Number',
    'Not available',
    "Patient's account number is unique and not available for modification.",
    'First Name',
    'Last Name',
    'Date of Birth',
    'Gender',
    'select-gender-label',
    'select-ethnicity-label',
    'These reviews have been assigned to you, please complete them as soon as possible.',
    'There are currently no exams available for review right now.',
    'Leave this Page?',
    'The changes you made will not be saved and the review will be returned to the list of pending reviews.',
    'Reviewed Exam',
    'Review Exam',
    'This Exam Review is from the Pool, it will be reassigned if this page is closed/changed.',
    'Please fill out all required fields',
    'View completed reviews.',
    'View',
    'There is currently no completed reviews.',
    'NON URGENT',
    'URGENT',
    'Eye exam determined as . . .',
    'If this case is URGENT, we recommend you to contact the patient immediately.',
    'Requires referral to ophthalmologist/additional actions:',
    'follow-up-screening-label',
    'Select',
    'Can be viewed only by the patient’s',
    'Write comment here...',
    'Can be viewed by both the patient and practitioners within TeleEye MD and the report',
    "Patient's Profile",
    'All exam reviews are taken, please refresh to see more reviews.',
    'Filter By Group',
    'Total reviews pending',
    'Review (for suspected cases only)',
    'Upload Images',
    'Order Summary',
    'Order Eye Exam',
    'Order exam without eye images',
    'Eye Exam',
    'Reviewed by',
    'The next available eye reviewer',
    'Current Balance',
    'Balance after Order',
    "Eye reviewer's Name",
    "Eye Reviewer's Name",
    'Only suspected cases are reviewed.',
    'Any Eye Reviewer',
    'This exam will be submitted to the pool for eye professionals review as soon as possible.',
    'Bad Quality Images may not be properly graded by DR Lite. Please select DR Grader if you wish to continue.',
    'Left/Right not assigned to image.',
    'Only a maximum of 2 images of left eye is allowed for submission.',
    'Only a maximum of 2 images of right eye is allowed for submission.',
    'Please select your desired services for the eye images.',
    'Possible reasons for bad quality image:',
    '1. Blurred image',
    '2. Low illumination',
    '3. Optic lens reflection',
    'Could be affected by:',
    '1. Optic lens opacity issue',
    '2. Improper operation',
    '3. Dirty camera lens',
    '3. Small pupil',
    'Create Exam',
    'ADDED SERVICES',
    'SELECT REVIEWER',
    'UPLOAD IMAGES (OPTIONAL)',
    'requirements',
    'ADD A COMMENT (OPTIONAL)',
    'Color fundus images',
    'The angle of retina field-of-view (FOV) from the image is between 35 and 50 degrees',
    'Minimum size of the retina FOV to be 1000 x 1000 pixels',
    'Macula-centred images',
    'PNG or JPG format',
    'Auto-Select',
    'Deselect All',
    'You can only upload a maximum of 4 images.',
    'Click or drop an image here to upload',
    'Failed to add services or show exam details. Refresh or try again.',
    'Add More Services',
    'You may choose to select more services to add to your exam.',
    'AI grading is taking longer than expected . . .',
    'You might want to check your network and resubmit it again.',
    'Please contact our support for help',
    'PENDING REVIEW',
    'The eye exam results has been sent to an eye health professional to review.',
    'Please check back after a while.',
    'Email Sent',
    'SMS Sent',
    'Send a report link to the patient through the chosen method.',
    'Email',
    'Invalid email',
    'Send Email',
    'SMS',
    '(Coming Soon)',
    'Send SMS',
    'Reviewed on',
    'yyyy-MM-dd hh:mmaaa',
    'Recommended next eye exam in',
    'Upgrade to proactively participate in your own well-being and care.',
    'Detect the severity of Diabetic Retinopathy & Glaucoma (with Cup-to-disc Ratio)',
    'Download Mobile App to Purchase',
    'Participate in telehealth consultations with healthcare providers for guidance and feedback on your most recent eye exam',
    'Go to My TeleConsult',
    'Eye Exam Results',
    'Report is not ready',
    'The report(s) will be ready for download after all exam are graded and reviewed (suspected cases only)',
    'Download Final Reports',
    'Right Eye',
    'Left Eye',
    'No Eyes Captured',
    'No image to show.',
    'right-eye',
    'left-eye',
    'Enter Cup to Disc Ratio',
    "Leave field blank if it's unable to grade",
    'Status',
    'Invoice Date',
    'DR Lite',
    'DR Grader',
    'C/D Ratio',
    'Your organisation has yet to create any exams.',
    'You may select to view any of your past exams in detail.',
    'You have not completed any exams.',
    'Confirm Submission',
    'Submitting images for grading.',
    'Confirm and Grade',
    'Confirm and Order',
    'Total Image(s)',
    'Ordered Services',
    'Exam will be audited by',
    'The next available auditor',
    'Please select images to continue',
    'Ordered Exam',
    'EYE REVIEWER',
    'Any eye reviewer',
    'Next Steps',
    'UPLOAD IMAGES OR WAIT FOR PATIENT TO VISIT OPTICAL STORE',
    'Upload and select images to be sent for grading, with several&nbsp;',
    'This Report is Protected',
    'To unlock and view report, enter your last name and DOB',
    'Unlock Report',
    'Having issues unlocking report? Contact your eye screening location.',
    'There are no urgent cases to show.',
    'Please enter a number between 0 to 300 inclusive',
    'Please enter a number between 0 to 700 inclusive',
    'Please enter a number between 0 to 400 inclusive',
    'Please enter a number between 0 to 50 inclusive',
    'Please enter a number between 0 to 250 inclusive',
    'Please enter a number between 0 to 20 inclusive',
    'Systolic Blood Pressure',
    'Diastolic Blood Pressure',
    'Health Data Type',
    'select-diabetes-therapy-label',
    'Please select any health data to add.',
    'Recorded Date and Time',
    'Now',
    'New Data',
    'Recorded Date',
    'Data',
    'Medical History',
    'Diabetes Type',
    'select...',
    'No diabetes',
    'Type 1 Diabetes',
    'Type 2 Diabetes',
    'Gestational Diabetes',
    'Diabetes Onset',
    'When were you first diagnosed with diabetes?',
    'Eye Surgery History',
    'Had eye surgery',
    'Never had eye surgery',
    'Heart Disease',
    'Has heart disease',
    'No heart disease',
    'High Blood Pressure',
    'Yes',
    'No',
    'High Cholesterol',
    'No Disease',
    'Mild Retinopathy',
    'Moderate Retinopathy',
    'Severe Retinopathy',
    'Proliferative Retinopathy',
    'Macular Edema Presence',
    'select-macular-edema-presence-label',
    'Absent',
    'Present',
    'Enter account number to retrieve patient.',
    'Dashboard',
    'Disclaimer: statistics are non-realtime and are updated midnightly.',
    'Welcome back',
    'Have a nice day at work',
    'Total Patients',
    'Urgent Cases',
    'Total Eye Exams',
    'Completed',
    'DISEASE DETECTION SUMMARY',
    '* Diseases are those marked as moderate or greater (AMD and DR), or suspect (DME, Glaucoma and Media Opacity)',
    'Total number of images graded by TeleEye MD',
    'URGENT CASES',
    'Select Organisation',
    'Approve User',
    'Create User',
    'Registration Success!',
    'Create another user',
    'Male',
    'Female',
    'Other',
    'Start typing for suggestions',
    'Type of Camera used:',
    'Organisation Name',
    'Which hospital, clinic or establishment do you belong to?',
    'Role',
    'Where do you intend to practice?',
    'Where do you intend to get screened?',
    'Password',
    'I agree to the',
    'Via Invite Link',
    'Copy Link',
    'OR',
    'Email Address',
    'Type a valid email and hit enter or copy and paste multiple emails',
    'Invite Users',
    'Invitation Success!',
    'Almost there! Please ask the user to check their email in their inbox or spam folder and click on the link to complete their registration process.',
    'Add New Organisation',
    'Email or Mobile No.',
    'Mobile Number',
    'Invalid Input',
    'Invalid medicare number',
    'Invalid reference number',
    'Select Country',
    'Country',
    'Passwords do not match',
    'Password must be at least 8 characters',
    'Password must contain at least one letter',
    'Password must contain at least one special character',
    'password_confirm',
    'Please fill up all required fields and check for any errors',
    'Please agree to all terms and conditions',
    'edit',
    'I declare that the above information is true and correct.*',
    'Enter your Details',
    'Booking Successful',
    'BACK TO MEETINGS',
    'There is no meeting available today, please try another day.',
    'First name',
    'Last name',
    'invalid email address',
    'Phone',
    'Teleconsultation',
    'Contact Details',
    'Teleconsultation Details',
    "Today' HH:mm",
    "Tomorrow' HH:mm",
    'no available time',
    'loading...',
    'Details',
    'SimplyBook.me',
    'Add a Note (optional)',
    'Please describe your symptoms or leave your questions',
    'Confirm Booking',
    'Next',
    'Available Meeting Start Times',
    'JOIN',
    'VIEW REPORT',
    'VIEW NOTE',
    'Select a status to display.',
    'The patient did not leave a note',
    'The eye specialist has not left a note yet',
    'Enter any notes from the meeting here . . .',
    'Eye Specialist',
    'Patient Name',
    'Notes',
    'Eye Exam Reports',
    'View Report(s)',
    "The patient doesn't have any history meetings",
    "You don't have any history meetings.",
    'Enable TeleConsult',
    'TeleConsults History',
    'TeleConsults',
    'Feature unavailable',
    'The feature is currently unavailable',
    'You need to finish an eye exam to book a TeleConsult',
    'BOOK',
    'Meeting room will open 5 min before the meeting begins',
    "You don't have any upcoming meetings.",
    'contact us.',
    'New Patient Creation',
    'This patient does not have any existing exams.',
    'Add New Exam',
    'Unable to add new exam',
    'Each patient can only have a maximum of 50 exams.',
    'Please complete and submit the most recent exam to add new exam.',
    'Risk Analysis',
    'Risk Analysis Unavailable',
    'Please add at least one Risk Prediction to view Risk Analaysis',
    'This feature will be coming soon, stay tuned!',
    'Add Risk Prediction',
    'Risk Prediction Services Coming Soon...',
    'Enter name(s) and/or date of birth to retrieve patient.',
    'Please enter some patient information to search',
    'Use Account Number',
    'No Account Number',
    'Your organisation has yet to register any patients.',
    'UPCOMING APPOINTMENTS',
    'Credits',
    'Add Credits',
    'Add free credits to specific organisation below.',
    'Completed By',
    'You have yet to make any purchases.',
    'Payment Transactions',
    'First Time offer!',
    'You pay',
    'Your payment has been cancelled. If there was a problem with the transaction, please contact our customer support or try again.',
    'Total Credits',
    'CREDITS',
    'Credit Transactions',
    'Subscription success!',
    'Unit Price',
    'Max. Exams',
    'Paid By',
    'You have yet to make any payment.',
    'What will be different?',
    'Join',
    'Please select a service to continue.',
    'You may select more services to add to calculate different risks.',
    'Medical History and Health Data required to run each service:',
    'Risk Predictions',
    'Resume Subscription',
    'Cancel Subscription',
    'Current Plan',
    'Cancelled subscription ends on',
    '- Trial ends on',
    'Next bill of',
    'to be issued on',
    'Plan:',
    'Add-On',
    'Start your free trial today',
    'By choosing a subscription package that suits you.',
    'Unable to view Billing History',
    'Billing history is currently unavailable but will be coming soon.',
    'Billing History',
    'We are sad to see you go. If you choose to cancel now,',
    'Cancellation will be effective at the end of your current billing cycle on',
    'You can still use the service until then.',
    'We are here to welcome you back at anytime. If you choose to resume now,',
    'Your next payment will be processed on',
    'Your subscription will continue as before.',
    'Select No. of Exams:',
    'Add-On Purchase Not Available',
    'Finish using current available Add-On exams before purchasing more',
    'One-time Purchase',
    'Subscriptions',
    'Add-On Unavailable',
    'Add-On is only available once you purchase a subscription plan',
    'Purchase',
    'Switch Plans Coming Soon',
    '*For enterprise pricings, please',
    'Error during service worker registration:',
    'content-type',
    'Upgrade your subscription if you wish to select this service',
    'Basic Plan required!',
    'Standard Plan required!',
    'Premium Plan required!',
    'AMD',
    'DME',
    'DR',
    'GLAUCOMA',
    'MEDIA OPACITY',
    'Disclaimer: RetinoScan Glaucoma and AMD is for',
    'research use',
    'only and should not be used for clinical decision making.',
    'Result',
    'Upload and select up to 4 images to be sent for grading, with several',
    'Create',
    'User',
    "Health Condition', 'Right Eye', 'Left Eye",
    'Health Condition',
    'Can be viewed only by the patient’s',
    'health practitioners',
    'within TeleEye MD',
    'You can navigate away from this page while waiting for results, start another exam, and return at any time.',
    'Create Patient',
    'Total Patients Screened',
    'Exam Date',
    'PENDING ACTION',
    'Filter',
    'AWAITING RESULT',
    'Chinese',
    'Indian',
    'Malay',
    'Caucasian',
    'Patient Details',
    'Latest Health Data',
    'Severity',
    'Diabetic\n Retinopathy',
    'Add Comment',
    'Eye Images',
    'DR Presence',
    'By logging in, you agree to our',
    'Reviewer',
    'Reviews within',
    'Oldest First',
    'Newest First',
    'Default (All)',
    'Phone Number',
    'Organisation Admin',
    "If you have the user's email address that is within your organisation, you can invite them by entering their email addres below. Instructions will be sent to their email and once completed, they will be added to the organisation.",
    'Invite another user',
    'Practitioner',
    'Patient',
    'Optometrist',
    'Sign Up with your Email',
    'Branch',
    'Location',
    'Branch/Location',
    'Your practice branch name or location',
    'Age-Related Macular Degeneration',
    'for age',
    'Glaucoma',
    'No Image',
    'DR Severity',
    'Exam Details',
    'Cup to Disc Ratio',
    'Disc Asymmetry',
    'Risk of Glaucoma if',
    'Send Report',
    'REPORT READY',
    'Additional Information',
    'Comment to Health Practitioners Only',
    'Comment to Patient',
    'Accident and Emergency',
    '2 weeks',
    '1 month',
    '3 months',
    '6 months',
    'Normal screening results - continue annual screening',
    'No referable conditions in screening results - Continue annual screening',
    'Extra Parameters',
    'Parameters',
    'Diabetic Macular Edema',
    'Media Opacity',
    'Review Summary',
    'Reviewed at',
    'please login instead',
    'or',
    "To enhance your account's security:",
    'Street Address',
    'Street',
    'Address Line 2: Floor and/or unit number',
    'Suburb/City',
    'City',
    'Town/City',
    'State',
    'Type',
    'An account already exists for this',
    'Neighbourhood',
    'organisation admin',
    'Detected Diseases',
    'UNLIMITED',
    'unlimited',
    'Image Requirements',
    'Start New Exam',
    'Review',
    'Leave',
    'Stay',
    'Health Data',
    'Eye Exams',
    'Add Health Data',
    'Right Uncorrected',
    'Left Uncorrected',
    'Right Best Corrected',
    'Left Best Corrected',
    'Comments (optional)',
    'e.g. Images too blurry, eyes bleeding...',
    'Thank you for verifying your',
    'Please enter your e-mail.',
    'Assigned reviews',
    'resubmit',
    'Cases that must be referred to a reviewer in a wee…rlier, as recommended in the eye screening audit.',
    'Ref No.',
    'medicare reference No.',
    'Invalid Phone Number',
    'PENDING',
    'NOT ADDED',
    'Comment',
    'Send Report Link',
    'Nikon',
    'Topcon',
    'DRS',
    'TeleEye Scan',
    'Upload and select images to be sent for grading with several',
    'Pending for image upload',
    'Waiting for results from services',
    'Pending for eye professional review',
    'Report ready to download',
    "Service hasn't been selected",
    '*Add-on screenings will be valid for 3 months. If …riptions, your add-on screenings will be removed.',
    'Add free credits to individual accounts (users that are not a part of any organisations) below.',
    'Please wait',
    'before trying again',
    'Email last sent:',
    'SMS last sent:',
    'American Academy of Ophthalmology (AAO) Standard',
    'Findings Observable upon Dilated Ophthalmoscopy',
    'Severity Level',
    'International Clinical Diabetic Macular Edema Disease Severity Scale',
    'International Clinical Diabetic Retinopathy Disease Severity Scale',
    'No abnormalities',
    'Microaneurysms only',
    'More than just microaneurysms but less than Severe NPDR',
    'Any of the following',
    'More than 20 intraretinal hemorrhages in each of 4 quadrants',
    'Definite venous beading in 2+ quadrants',
    'Prominent IRMA in 1+ quadrant',
    'And no signs of proliferative retinopathy',
    'One or more of the following',
    'No retinal thickening or hard exudates in posterior pole',
    'Some retinal thickening or hard exudates in posterior pole but distant from the center of the macula',
    'Retinal thickening or hard exudates approaching th…center of the macula but not involving the center',
    'Retinal thickening or hard exudates involving the center of the macula',
    'Canceled',
    'QA not assigned to image.',
    "If your account is created by your organisation's admin, you are able to log in now.Otherwise, you would need to wait for our verification and approval before you can log in.An email will be sent to you once your account has been approved.",
    'Create Password',
    'Diseases Summary',
    'check OTP usage for:',
    'You will be joining',
    'Next billed on',
    'Diabetic\n Retinopathy',
    'Type or paste email addresses and press',
    'has already been added.',
    'is not a valid email address.',
    'Comments for practitioners and reviewers.This will not appear on the patient’s exam report',
    'Month',
    'Glaucoma and AMD indications are solely based on independent Healthcare Provider review.',
    'Diabetic Macular Edema and Media Opacity indications are solely based on independent Healthcare Provider review.',
    'Clinical investigational use only',
    'Disclaimer',
    'The clinician must review the AI results below, make an independent clinical assessment, and adjust the results accordingly.',
    'The final clinical assessment was completed by a clinician and is not reliant upon any AI.',
    'Submit Exam?',
  ],
  en: {
    ...missingTranslation.en,
    Lost_connection_to_server__please_refresh_the_page: 'Lost connection to server, please refresh the page',
    Unable_to_load_pooled_reviews__please_try_again_by_refreshing_the_page_:
      'Unable to load pooled reviews, please try again by refreshing the page.',
    There_was_a_problem_retrieving_the_audit__Please_contact_us_if_this_persists_:
      'There was a problem retrieving the audit. Please contact us if this persists.',
    There_was_a_problem_submitting_the_audit__Please_contact_us_if_this_persists_:
      'There was a problem submitting the audit. Please contact us if this persists.',
    There_was_a_problem_assigning_the_review__Please_try_to_review_other_exams:
      'There was a problem assigning the review. Please try to review other exams',
    There_was_a_problem_logging_in__Please_try_again_later_: 'There was a problem logging in. Please try again later.',
    The_session_is_already_ended_: 'The session is already ended.',
    You_have_successfully_signed_up_: 'You have successfully signed up.',
    There_was_a_problem_signing_up__Please_try_again_later_: 'There was a problem signing up. Please try again later.',
    No_email_or_phone_number_provided: 'No email or phone number provided',
    There_was_a_problem_checking_the_account__Please_try_again_later_:
      'There was a problem checking the account. Please try again later.',
    Successfully_created_: 'Successfully created!',
    There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_:
      'There was a problem when retrieving Terms and Conditions data. Please refresh to try again.',
    Thank_you_for_accepting_the_updated_terms_of_use_: 'Thank you for accepting the updated terms of use.',
    'There_was_a_problem_when_accepting_Terms_and_Conditions__Please_contact_us_if_it_persists`_':
      'There was a problem when accepting Terms and Conditions. Please contact us if it persists`.',
    Invitation_Link_Copied__Valid_for_24_hours_: 'Invitation Link Copied! Valid for 24 hours.',
    There_was_a_problem_joining_this_organisation__please_contact_your_organisation_admin_:
      'There was a problem joining this organisation, please contact your organisation admin.',
    Email_Verified_: 'Email Verified!',
    Email_or_Mobile_Number_is_required: 'Email or Mobile Number is required',
    There_was_a_problem_creating_an_exam__Please_try_again_later_:
      'There was a problem creating an exam. Please try again later.',
    There_was_a_problem_retrieving_exams__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving exams. Please refresh the page to try again.',
    There_was_a_problem_retrieving_page_number_for_exams__please_try_again_:
      'There was a problem retrieving page number for exams, please try again.',
    Uploading_image: 'Uploading image',
    Successfully_uploaded: 'Successfully uploaded',
    There_is_a_problem_with_uploading__Please_refresh_page_and_try_again_:
      'There is a problem with uploading. Please refresh page and try again.',
    There_was_a_problem_deleting_image__Please_try_again_later_:
      'There was a problem deleting image. Please try again later.',
    disconnected_websocket_for_exam: 'disconnected websocket for exam',
    There_was_a_problem_retrieving_an_image_s_information__Please_try_again_later_:
      "There was a problem retrieving an image's information. Please try again later.",
    There_was_a_problem_retrieving_the_order_details_: 'There was a problem retrieving the order details.',
    There_was_a_problem_when_getting_exam_details__Please_try_to_refresh_the_page_:
      'There was a problem when getting exam details. Please try to refresh the page.',
    There_was_a_problem_retrieving_urgent_exams__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving urgent exams. Please refresh the page to try again.',
    Please_select_at_least_one_image_to_submit_: 'Please select at least one image to submit.',
    Image_s__submitted_for_grading: 'Image(s) submitted for grading',
    There_was_a_problem_when_submitting_images__Please_try_again_:
      'There was a problem when submitting images. Please try again.',
    There_was_a_problem_assigning_the_laterality_for_the_image__please_try_again_:
      'There was a problem assigning the laterality for the image, please try again.',
    No_services_selected_: 'No services selected.',
    Successfully_submitted_: 'Successfully submitted!',
    There_was_a_problem_submitting_for_services__Please_try_again_later_:
      'There was a problem submitting for services. Please try again later.',
    Successfully_resubmitted_: 'Successfully resubmitted!',
    There_was_a_problem_when_resubmitting__Please_try_again_:
      'There was a problem when resubmitting. Please try again.',
    There_was_a_problem_when_submitting_comments__Please_try_again_:
      'There was a problem when submitting comments. Please try again.',
    Loading_report___: 'Loading report...',
    Report_successfully_loaded_: 'Report successfully loaded!',
    There_was_an_error_retrieving_auditors__Please_try_again_:
      'There was an error retrieving auditors. Please try again.',
    There_was_an_error_retrieving_health_data__Please_refresh_the_page_to_try_again_:
      'There was an error retrieving health data. Please refresh the page to try again.',
    There_is_a_problem_when_adding_new_health_data__Please_try_again_later_:
      'There is a problem when adding new health data. Please try again later.',
    There_is_a_problem_when_updating_medical_history__Please_try_again_later_:
      'There is a problem when updating medical history. Please try again later.',
    There_was_a_problem_loading_users__Please_refresh_the_page_to_try_again_:
      'There was a problem loading users. Please refresh the page to try again.',
    There_was_a_problem_loading_organisations__Please_refresh_the_page_to_try_again_:
      'There was a problem loading organisations. Please refresh the page to try again.',
    There_was_a_problem_adding_this_organisation__Please_try_again_later_:
      'There was a problem adding this organisation. Please try again later.',
    Successfully_approved_: 'Successfully approved.',
    There_was_a_problem_approving_this_user__Please_try_again_later_:
      'There was a problem approving this user. Please try again later.',
    Successfully_added_free_credits_: 'Successfully added free credits.',
    There_was_a_problem_adding_free_credits__Please_try_again_later_:
      'There was a problem adding free credits. Please try again later.',
    There_was_a_problem_retrieving_meeting_hosts__Please_try_again_:
      'There was a problem retrieving meeting hosts. Please try again.',
    There_was_a_problem_retrieving_your_contact_information__Please_try_to_refresh_page_:
      'There was a problem retrieving your contact information. Please try to refresh page.',
    There_was_a_problem_updating_your_contact_information__Please_try_again_:
      'There was a problem updating your contact information. Please try again.',
    failed_to_update_contact_information: 'failed to update contact information',
    There_was_a_problem_retrieving_meetings__Please_try_again_:
      'There was a problem retrieving meetings. Please try again.',
    There_was_a_problem_retrieving_history_meetings__Please_try_again_:
      'There was a problem retrieving history meetings. Please try again.',
    There_was_a_problem_retrieving_available_slots__Please_try_again_:
      'There was a problem retrieving available slots. Please try again.',
    There_was_a_problem_retrieving_provider_s_first_available_date__Please_try_again_:
      "There was a problem retrieving provider's first available date. Please try again.",
    There_was_a_problem_retrieving_provider_schedule__Please_try_again_:
      'There was a problem retrieving provider schedule. Please try again.',
    Meeting_successfully_canceled: 'Meeting successfully canceled',
    There_was_a_problem_when_connecting_to_server__Please_refresh_page_and_try_again:
      'There was a problem when connecting to server. Please refresh page and try again',
    There_was_a_problem_when_canceling_meeting__Please_try_again_:
      'There was a problem when canceling meeting. Please try again.',
    The_time_is_not_available__please_choose_another_time: 'The time is not available, please choose another time',
    There_was_a_problem_when_fetch_new_meeting_details__Please_try_again_:
      'There was a problem when fetch new meeting details. Please try again.',
    There_was_a_problem_when_booking_meeting__Please_try_again_:
      'There was a problem when booking meeting. Please try again.',
    Successfully_rescheduled_meeting: 'Successfully rescheduled meeting',
    There_was_a_problem_when_rescheduling_meeting__Please_try_again_:
      'There was a problem when rescheduling meeting. Please try again.',
    Your_comment_has_been_successfully_submitted_: 'Your comment has been successfully submitted.',
    There_something_wrong_when_submitting_comment__please_try_again_later:
      'There something wrong when submitting comment, please try again later',
    The_meeting_has_ended: 'The meeting has ended',
    There_was_a_problem_when_enter_meeting__Please_try_again_:
      'There was a problem when enter meeting. Please try again.',
    There_was_a_problem_when_get_meeting_details__Please_try_again_:
      'There was a problem when get meeting details. Please try again.',
    There_was_a_problem_retrieving_patients__Please_try_again_:
      'There was a problem retrieving patients. Please try again.',
    No_patients_found_: 'No patients found.',
    No_matching_patient_records_found_: 'No matching patient records found.',
    There_was_a_problem_retrieving_patient_details__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving patient details. Please refresh the page to try again.',
    Successfully_updated_your_details_: 'Successfully updated your details.',
    Successfully_updated_patient_details_: 'Successfully updated patient details.',
    There_is_a_problem_when_updating_patient_details__Please_try_again_later_:
      'There is a problem when updating patient details. Please try again later.',
    There_was_a_problem_when_retrieving_patient_s_exams__Please_refresh_the_page_to_try_again_:
      "There was a problem when retrieving patient's exams. Please refresh the page to try again.",
    There_was_a_problem_getting_your_details__Please_try_again_later_:
      'There was a problem getting your details. Please try again later.',
    There_was_a_problem_creating_a_new_patient__Please_try_again_later_:
      'There was a problem creating a new patient. Please try again later.',
    There_was_a_problem_retrieving_price_groups__Please_try_again_later_:
      'There was a problem retrieving price groups. Please try again later.',
    There_was_a_problem_retrieving_your_credits_data__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your credits data. Please refresh the page to try again.',
    There_was_a_problem_retrieving_the_service_prices__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving the service prices. Please refresh the page to try again.',
    There_was_a_problem_retrieving_your_purchase_history__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your purchase history. Please refresh the page to try again.',
    There_was_a_problem_retrieving_your_payment_history__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your payment history. Please refresh the page to try again.',
    Successfully_added_STDR_Risk_Prediction: 'Successfully added STDR Risk Prediction',
    Successfully_added_DR_Risk_Prediction: 'Successfully added DR Risk Prediction',
    DR_Risk_Prediction_report_ready: 'DR Risk Prediction report ready',
    Successfully_cancelled_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Successfully cancelled subscription. Please wait for a few seconds and page will reload automatically',
    Successfully_resumed_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Successfully resumed subscription. Please wait for a few seconds and page will reload automatically',
    Confirm: 'Confirm',
    We_ve_Updated_Our_Terms: "We've Updated Our Terms",
    In_order_to_continue_using_TeleEye_MD__you_will_need_to_agree_with_our_latest_Terms_of_Use_:
      'In order to continue using TeleEye MD, you will need to agree with our latest',
    Terms_of_Use: 'Terms of Use',
    progressbar: 'progressbar',
    Login: 'Login',
    There_was_a_problem_connecting_to_the_server__Please_check_your_internet_connection_and_try_again_:
      'There was a problem connecting to the server. Please check your internet connection and try again.',
    _scheduling_simply_book_auth_get_tokens: '/scheduling/simply_book/auth/get_tokens',
    Edit_Comment: 'Edit Comment',
    Submit: 'Submit',
    script: 'script',
    Patient_s_eyes_bleeding__Image_blurry__Let_the_eye_reviewer_know_by_adding_a_comment_:
      "Patient's eyes bleeding? Image blurry? Let the eye reviewer know by adding a comment!",
    No_comment_left: 'No comment left',
    Operator_s_Comments: "Operator's Comments",
    Download_Report: 'Download Report',
    QA: 'QA',
    No_Result: 'No Result',
    Which_Eye_: 'Which Eye?',
    Loading___: 'Loading...',
    Left: 'Left',
    Right: 'Right',
    Upgrade_for: 'Upgrade for',
    for: 'for',
    Go_to_Subscriptions: 'Go to Subscriptions',
    subtitle: 'subtitle',
    Prediction_Occurrence_: 'Prediction Occurrence:',
    View_Report: 'View Report',
    Submit_for_Grading: 'Submit for Grading',
    Order_Services: 'Order Services',
    Submission_not_allowed_: 'Submission not allowed.',
    Please_subscribe_to_a_plan_to_start_screening: 'Please subscribe to a plan to start screening',
    Images_uploaded_but_not_selected: 'Images uploaded but not selected',
    Please_select_images_to_submit_for_grading: 'Please select images to submit for grading',
    Free_Trial: 'Free Trial',
    Free_Plan: 'Free Plan',
    OPEN_MOBILE_APP: 'OPEN MOBILE APP',
    Log_Out: 'Log Out',
    Patient_LookUp: 'Patient LookUp',
    Available_Exams_: 'Available Exams:',
    Account_No_: 'Account No.',
    Terms: 'T&C',
    Below_is_your: 'Below is your',
    Treat_it_as_your_identification_number_when_going_for_any_eye_screening__You_don_t_need_to_remember_it__as_it_is_also_sent_to_your_email_:
      "Treat it as your identification number when going for any eye screening! You don't need to remember it, as it is also sent to your email.",
    This_is_the_patient_s: "This is the patient's",
    Sorry__This_page_does_not_exist_: 'Sorry! This page does not exist.',
    Back_to_Home: 'Back to Home',
    Your_password_needs_to_contain_at_least_8_characters_: 'Your password needs to contain at least 8 characters.',
    Your_password_can_t_be_too_similar_to_your_other_personal_information_:
      "Your password can't be too similar to your other personal information.",
    Your_password_can_t_be_a_commonly_used_password_: "Your password can't be a commonly used password.",
    Your_password_can_t_be_entirely_numeric_: "Your password can't be entirely numeric.",
    Coming_soon: 'Coming soon',
    This_feature_is_currently_still_under_production_: 'This feature is currently still under production.',
    Please_look_forward_to_our_future_releases_: 'Please look forward to our future releases!',
    Cancel: 'Cancel',
    Go_Back: 'Go Back',
    Unknown: 'Unknown',
    Visual_Acuity__Uncorrected_: 'Visual Acuity (Uncorrected)',
    Visual_Acuity__Best_Corrected_: 'Visual Acuity (Best Corrected)',
    IOP__mmHg_: 'IOP (mmHg)',
    HbA1c____: 'HbA1c (%)',
    Blood_Pressure__mmHg_: 'Blood Pressure (mmHg)',
    Albuminuria__mg_mmol_: 'Albuminuria (mg/mmol)',
    Diabetes_Therapy: 'Diabetes Therapy',
    Postprandial_Blood_Glucose__mg_dL_: 'Postprandial Blood Glucose (mg/dL)',
    Triglyceride__mg_dL_: 'Triglyceride (mg/dL)',
    Low_Density_Lipoprotein__mg_dL_: 'Low Density Lipoprotein (mg/dL)',
    No_light_perception__NLP_: 'No light perception (NLP)',
    Light_perception__LP_: 'Light perception (LP)',
    Hand_movements__HM_: 'Hand movements (HM)',
    Counting_fingers_at_1_meters__CF_: 'Counting fingers at 1 meters (CF)',
    Overview: 'Overview',
    All_Patients: 'All Patients',
    Register_Patient: 'Register Patient',
    Subscription: 'Subscription',
    Settings: 'Settings',
    Pending_Reviews: 'Pending Reviews',
    Completed_Reviews: 'Completed Reviews',
    My_TeleConsults: 'My TeleConsults',
    My_Exams: 'My Exams',
    My_Profile: 'My Profile',
    Register_User: 'Register User',
    Invite_User: 'Invite User',
    All_Exams: 'All Exams',
    Approve_Users: 'Approve Users',
    Organisations: 'Organisations',
    According_to_Medicare: 'According to Medicare',
    Country_of_Service: 'Country of Service',
    Medicare_No_: 'Medicare No.',
    Post_Code: 'Post Code',
    Ethnicity: 'Ethnicity',
    NRIC__Last_4_Characters_: 'NRIC (Last 4 Characters)',
    According_to_ID_Passport: 'According to ID/Passport',
    ID_CARD__Personalausweis_: 'ID CARD (Personalausweis)',
    Individual_Health_Identifier__IHI__number: 'Individual Health Identifier (IHI) number',
    NHS_Number: 'NHS Number',
    Postcode: 'Postcode',
    ID_Card_Number: 'ID Card Number',
    REFER_TO_REPORT: 'REFER TO REPORT',
    AI_Grading___: 'AI Grading...',
    Not_Applicable: 'Not Applicable',
    Unable_to_Grade: 'Unable to Grade',
    Non_Referable: 'Non-Referable',
    Referable: 'Referable',
    Not_Urgent: 'Not Urgent',
    Urgent: 'Urgent',
    Resolved: 'Resolved',
    Normal: 'Normal',
    Suspected: 'Suspected',
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
    Non_Significant: 'Non-Significant',
    Significant: 'Significant',
    Mild_NPDR: 'Mild NPDR',
    Moderate_NPDR: 'Moderate NPDR',
    Severe_NPDR: 'Severe NPDR',
    PDR: 'PDR',
    Suspect_DR_Disease: 'Suspect DR Disease',
    Suspect_AMD_Disease: 'Suspect AMD Disease',
    Suspect_AMD_disease__High_probability_AMD__some_AMD_signs_:
      'Suspect AMD disease (High probability AMD, some AMD signs)',
    Suspect_DR_disease__High_probability__some_DR_Signs_: 'Suspect DR disease (High probability, some DR Signs)',
    Click_to_update: 'Click to update',
    RetinoScan: 'RetinoScan',
    RetinoScan_analyses_retinal_images_to_accurately_identify_patients__with_Diabetic_Retinopathy__Glaucoma_and_or_Age_Related_Macular_Degeneration_:
      "RetinoScan analyses retinal images to accurately identify patients' with Diabetic Retinopathy, Glaucoma and/or Age Related Macular Degeneration.",
    RetinoScan_Plus: 'RetinoScan+',
    For_patients_with_a_disease_diagnosis__RetinoScan_Advanced_determines_the_disease_severity_and_provide_lesion_monitoring_services_:
      'For patients with a disease diagnosis, RetinoScan Advanced determines the disease severity and provide lesion monitoring services.',
    Suspected_Cases: 'Suspected Cases',
    Review_by_Eye_Professional: 'Review by Eye Professional',
    Comprehensive_Patient_Report: 'Comprehensive Patient Report',
    Class_II_Medical_Device: 'Class II Medical Device',
    Clinically_Validated: 'Clinically Validated',
    Cloud_Storage: 'Cloud Storage',
    Patient_Data_Retention: 'Patient Data Retention',
    In_accordance_to_HIPPA_compliances__all_patients__data_will_be_stored_and_backed_up_for_15_years:
      "In accordance to HIPPA compliances, all patients' data will be stored and backed up for 15 years",
    '15_years': '15 years',
    Referral_Pathway: 'Referral Pathway',
    Coming_Soon: 'Coming Soon',
    Forgot_your_password_: 'Forgot your password?',
    We_can_send_you_an_email_with_the_link_to_reset_it_: 'We can send you an email with the link to reset it.',
    E_mail: 'E-mail',
    SEND_EMAIL: 'SEND EMAIL',
    Check_your_email: 'Check your email',
    If_your_email_exist_in_our_system_the_reset_link_will_be_sent_to_your_inbox_Please_do_also_check_if_it_s_in_your_spam_folder_:
      "If your email exist in our system,the reset link will be sent to your inbox.Please do also check if it's in your spam folder.",
    Invalid_password: 'Invalid password',
    Create_new_password: 'Create new password',
    Please_enter_a_new_password_to_reset_your_password_: 'Please enter a new password to reset your password.',
    New_Password: 'New Password',
    toggle_password_visibility: 'toggle password visibility',
    Confirm_Password: 'Confirm Password',
    Passwords_must_be_equal: 'Passwords must be equal',
    RESET_PASSWORD: 'RESET PASSWORD',
    Password_Changed: 'Password Changed',
    You_have_successfully_changed_your_password__Try_logging_in_now_:
      'You have successfully changed your password! Try logging in now!',
    Password_Change_Unsuccessful: 'Password Change Unsuccessful',
    Sorry__There_must_be_a_problem_on_our_side__Please_contact_customer_support_to_report_this_problem_:
      'Sorry! There must be a problem on our side. Please contact customer support to report this problem.',
    Wrong_digit_code__Try_Again: 'Wrong digit code. Try Again',
    Enter_your_Email_or_Mobile_number: 'Enter your Email or Mobile number',
    NEXT: 'NEXT',
    next: 'next',
    Log_in: 'Log in',
    Login_with_your_Email_or_Mobile_No_: 'Login with your Email or Mobile No.',
    Forgot_Password: 'Forgot Password',
    LOGIN: 'LOGIN',
    Don_t_have_an_account_: "Don't have an account?",
    Sign_Up: 'Sign Up',
    You_may_now_log_into_your_account_with_your_email_: 'You may now log into your account with your email.',
    You_are_already_logged_in_: 'You are already logged in.',
    If_you_have_already_logged_in__please_log_out_first_and_refresh_this_page_If_not__please_contact_our_customer_support_to_report_this_problem_:
      'If you have already logged in, please log out first and refresh this page.If not, please contact our customer support to report this problem.',
    LOG_OUT: 'LOG OUT',
    success: 'success',
    Activation_Success_: 'Activation Success!',
    Activation_Fail___: 'Activation Fail...',
    Sorry__You_might_have_already_been_activated__so_try_to_log_in__If_not_please_contact_our_customer_service_to_report_this_problem_:
      'Sorry! You might have already been activated, so try to log in. If not,please contact our customer service to report this problem.',
    Please_set_your_password_so_that_you_can_use_it_to_log_into_this_platform_:
      'Please set your password so that you can use it to log into this platform.',
    SET_PASSWORD: 'SET PASSWORD',
    Password_Successfully_Set_: 'Password Successfully Set!',
    You_may_now_log_into_your_account_with_your_credentials_:
      'You may now log into your account with your credentials.',
    Password_Set_Unsuccessful___: 'Password Set Unsuccessful...',
    OTP_Verification: 'OTP Verification',
    Enter_the_6_digit_code_sent_to_: 'Enter the 6 digit code sent to:',
    Didn_t_receive_the_code_: "Didn't receive the code?",
    Resend: 'Resend',
    I_am_a____: 'I am a ...',
    Already_have_an_account_: 'Already have an account?',
    Log_In: 'Log In',
    SIGN_UP: 'SIGN UP',
    EMAIL: 'EMAIL',
    MOBILE_NUMBER: 'MOBILE NUMBER',
    VERIFIED: 'VERIFIED',
    email_address: 'email address',
    mobile_number: 'mobile number',
    Your_TeleEye_MD_account_is_now_activated_: 'Your TeleEye MD account is now activated.',
    It_will_take_up_to_1_business_day_for_TeleMedC_admins_to_verify_your_details_and_activate_your_account_:
      'It will take up to 1 business day for TeleMedC admins to verify your details and activate your account.',
    Save: 'Save',
    Edit_Data: 'Edit Data',
    Account_Number: 'Account Number',
    Not_available: 'Not available',
    Patient_s_account_number_is_unique_and_not_available_for_modification_:
      "Patient's account number is unique and not available for modification.",
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    Date_of_Birth: 'Date of Birth',
    Gender: 'Gender',
    select_gender_label: 'select-gender-label',
    select_ethnicity_label: 'select-ethnicity-label',
    These_reviews_have_been_assigned_to_you__please_complete_them_as_soon_as_possible_:
      'These reviews have been assigned to you, please complete them as soon as possible.',
    There_are_currently_no_exams_available_for_review_right_now_:
      'There are currently no exams available for review right now.',
    Leave_this_Page_: 'Leave this Page?',
    The_changes_you_made_will_not_be_saved_and_the_review_will_be_returned_to_the_list_of_pending_reviews_:
      'The changes you made will not be saved and the review will be returned to the list of pending reviews.',
    Reviewed_Exam: 'Reviewed Exam',
    Review_Exam: 'Review Exam',
    This_Exam_Review_is_from_the_Pool__it_will_be_reassigned_if_this_page_is_closed_changed_:
      'This Exam Review is from the Pool, it will be reassigned if this page is closed/changed.',
    Please_fill_out_all_required_fields: 'Please fill out all required fields',
    View_completed_reviews_: 'View completed reviews.',
    View: 'View',
    There_are_currently_no_completed_reviews_: 'There are currently no completed reviews.',
    NON_URGENT: 'NON URGENT',
    URGENT: 'URGENT',
    Eye_exam_determined_as______: 'Eye exam determined as . . .',
    If_this_case_is_URGENT__we_recommend_you_to_contact_the_patient_immediately_:
      'If this case is URGENT, we recommend you to contact the patient immediately.',
    Requires_referral_to_ophthalmologist_additional_actions_:
      'Requires referral to ophthalmologist/additional actions:',
    follow_up_screening_label: 'follow-up-screening-label',
    Select: 'Select',
    Can_be_viewed_only_by_the_patient_s: 'Can be viewed only by the patient’s',
    Write_comment_here___: 'Write comment here...',
    Can_be_viewed_by_both_the_patient_and_practitioners_within_TeleEye_MD_and_the_report:
      'Can be viewed by both the patient and practitioners within TeleEye MD and the report',
    Can_be_viewed_within_TeleEye_MD_and_the_exam_report: 'Can be viewed within TeleEye MD and the exam report',
    Patient_s_Profile: "Patient's Profile",
    All_exam_reviews_are_taken__please_refresh_to_see_more_reviews_:
      'All exam reviews are taken, please refresh to see more reviews.',
    Filter_By_Group: 'Filter By Group',
    Total_reviews_pending: 'Total reviews pending',
    Review__for_suspected_cases_only_: 'Review (for suspected cases only)',
    Upload_Images: 'Upload Images',
    Order_Summary: 'Order Summary',
    Order_Eye_Exam: 'Order Eye Exam',
    Order_exam_without_eye_images: 'Order exam without eye images',
    Eye_Exam: 'Eye Exam',
    Reviewed_by: 'Reviewed by',
    The_next_available_eye_reviewer: 'The next available eye reviewer',
    Current_Balance: 'Current Balance',
    Balance_after_Order: 'Balance after Order',
    Eye_reviewer_s_Name: "Eye reviewer's Name",
    Eye_Reviewer_s_Name: "Eye Reviewer's Name",
    Only_suspected_cases_are_reviewed_: 'Only suspected cases are reviewed.',
    Any_Eye_Reviewer: 'Any Eye Reviewer',
    This_exam_will_be_submitted_to_the_pool_for_eye_professionals_review_as_soon_as_possible_:
      'This exam will be submitted to the pool for eye professionals review as soon as possible.',
    Bad_Quality_Images_may_not_be_properly_graded_by_DR_Lite__Please_select_DR_Grader_if_you_wish_to_continue_:
      'Bad Quality Images may not be properly graded by DR Lite. Please select DR Grader if you wish to continue.',
    Left_Right_not_assigned_to_image_: 'Left/Right not assigned to image.',
    Only_a_maximum_of_2_images_of_left_eye_is_allowed_for_submission_:
      'Only a maximum of 2 images of left eye is allowed for submission.',
    Only_a_maximum_of_2_images_of_right_eye_is_allowed_for_submission_:
      'Only a maximum of 2 images of right eye is allowed for submission.',
    Please_select_your_desired_services_for_the_eye_images_: 'Please select your desired services for the eye images.',
    Possible_reasons_for_bad_quality_image_: 'Possible reasons for bad quality image:',
    '1__Blurred_image': '1. Blurred image',
    '2__Low_illumination': '2. Low illumination',
    '3__Optic_lens_reflection': '3. Optic lens reflection',
    Could_be_affected_by_: 'Could be affected by:',
    '1__Optic_lens_opacity_issue': '1. Optic lens opacity issue',
    '2__Improper_operation': '2. Improper operation',
    '3__Dirty_camera_lens': '3. Dirty camera lens',
    '3__Small_pupil': '3. Small pupil',
    Create_Exam: 'Create Exam',
    ADDED_SERVICES: 'ADDED SERVICES',
    SELECT_REVIEWER: 'SELECT REVIEWER',
    UPLOAD_IMAGES__OPTIONAL_: 'UPLOAD IMAGES (OPTIONAL)',
    requirements: 'requirements',
    ADD_A_COMMENT__OPTIONAL_: 'ADD A COMMENT (OPTIONAL)',
    Color_fundus_images: 'Color fundus images',
    The_angle_of_retina_field_of_view__FOV__from_the_image_is_between_35_and_50_degrees:
      'The angle of retina field-of-view (FOV) from the image is between 35 and 50 degrees',
    Minimum_size_of_the_retina_FOV_to_be_1000_x_1000_pixels: 'Minimum size of the retina FOV to be 1000 x 1000 pixels',
    Macula_centred_images: 'Macula-centred images',
    PNG_or_JPG_format: 'PNG or JPG format',
    Auto_Select: 'Auto-Select',
    Deselect_All: 'Deselect All',
    You_can_only_upload_a_maximum_of_4_images_: 'You can only upload a maximum of 4 images.',
    Click_or_drop_an_image_here_to_upload: 'Click or drop an image here to upload',
    Failed_to_add_services_or_show_exam_details__Refresh_or_try_again_:
      'Failed to add services or show exam details. Refresh or try again.',
    Add_More_Services: 'Add More Services',
    You_may_choose_to_select_more_services_to_add_to_your_exam_:
      'You may choose to select more services to add to your exam.',
    AI_grading_is_taking_longer_than_expected______: 'AI grading is taking longer than expected . . .',
    You_might_want_to_check_your_network_and_resubmit_it_again_:
      'You might want to check your network and resubmit it again.',
    Please_contact_our_support_for_help: 'Please contact our support for help',
    PENDING_REVIEW: 'PENDING REVIEW',
    The_eye_exam_results_has_been_sent_to_an_eye_health_professional_to_review_:
      'The eye exam results has been sent to an eye health professional to review.',
    Please_check_back_after_a_while_: 'Please check back after a while.',
    Email_Sent: 'Email Sent',
    SMS_Sent: 'SMS Sent',
    Send_a_report_link_to_the_patient_through_the_chosen_method_:
      'Send a report link to the patient through the chosen method.',
    Email: 'Email',
    Invalid_email: 'Invalid email',
    Send_Email: 'Send Email',
    SMS: 'SMS',
    _Coming_Soon_: '(Coming Soon)',
    Send_SMS: 'Send SMS',
    Reviewed_on: 'Reviewed on',
    yyyy_MM_dd_hh_mmaaa: 'yyyy-MM-dd hh:mmaaa',
    Recommended_next_eye_exam_in: 'Recommended next eye exam in',
    Upgrade_to_proactively_participate_in_your_own_well_being_and_care_:
      'Upgrade to proactively participate in your own well-being and care.',
    Detect_the_severity_of_Diabetic_Retinopathy___Glaucoma__with_Cup_to_disc_Ratio_:
      'Detect the severity of Diabetic Retinopathy & Glaucoma (with Cup-to-disc Ratio)',
    Download_Mobile_App_to_Purchase: 'Download Mobile App to Purchase',
    Participate_in_telehealth_consultations_with_healthcare_providers_for_guidance_and_feedback_on_your_most_recent_eye_exam:
      'Participate in telehealth consultations with healthcare providers for guidance and feedback on your most recent eye exam',
    Go_to_My_TeleConsult: 'Go to My TeleConsult',
    Eye_Exam_Results: 'Eye Exam Results',
    Report_is_not_ready: 'Report is not ready',
    The_report_s__will_be_ready_for_download_after_all_exam_are_graded_and_reviewed__suspected_cases_only_:
      'The report(s) will be ready for download after all exam are graded and reviewed (suspected cases only)',
    Download_Final_Reports: 'Download Final Reports',
    Right_Eye: 'Right Eye',
    Left_Eye: 'Left Eye',
    No_Eyes_Captured: 'No Eyes Captured',
    No_image_to_show_: 'No image to show',
    right_eye: 'right-eye',
    left_eye: 'left-eye',
    Enter_Cup_to_Disc_Ratio: 'Enter Cup to Disc Ratio',
    Leave_field_blank_if_it_s_unable_to_grade: "Leave field blank if it's unable to grade",
    Status: 'Status',
    DR_Lite: 'DR Lite',
    DR_Grader: 'DR Grader',
    C_D_Ratio: 'C/D Ratio',
    Your_organisation_has_yet_to_create_any_exams_: 'Your organisation has yet to create any exams.',
    You_may_select_to_view_any_of_your_past_exams_in_detail_:
      'You may select to view any of your past exams in detail.',
    You_have_not_completed_any_exams_: 'You have not completed any exams.',
    Confirm_Submission: 'Confirm Submission',
    Submitting_images_for_grading_: 'Submitting images for grading.',
    Confirm_and_Grade: 'Confirm and Grade',
    Confirm_and_Order: 'Confirm and Order',
    Total_Image_s_: 'Total Image(s)',
    Ordered_Services: 'Ordered Services',
    Exam_will_be_audited_by: 'Exam will be audited by',
    The_next_available_auditor: 'The next available auditor',
    Please_select_images_to_continue: 'Please select images to continue',
    Ordered_Exam: 'Ordered Exam',
    EYE_REVIEWER: 'EYE REVIEWER',
    Any_eye_reviewer: 'Any eye reviewer',
    Next_Steps: 'Next Steps',
    UPLOAD_IMAGES_OR_WAIT_FOR_PATIENT_TO_VISIT_OPTICAL_STORE:
      'UPLOAD IMAGES OR WAIT FOR PATIENT TO VISIT OPTICAL STORE',
    Upload_and_select_images_to_be_sent_for_grading__with_several_:
      'Upload and select images to be sent for grading, with several',
    This_Report_is_Protected: 'This Report is Protected',
    To_unlock_and_view_report__enter_your_last_name_and_DOB: 'To unlock and view report, enter your last name and DOB',
    Unlock_Report: 'Unlock Report',
    Having_issues_unlocking_report__Contact_your_eye_screening_location_:
      'Having issues unlocking report? Contact your eye screening location.',
    There_are_no_urgent_cases_to_show_: 'There are no urgent cases to show.',
    Please_enter_a_number_between_0_to_300_inclusive: 'Please enter a number between 0 to 300 inclusive',
    Please_enter_a_number_between_0_to_700_inclusive: 'Please enter a number between 0 to 700 inclusive',
    Please_enter_a_number_between_0_to_400_inclusive: 'Please enter a number between 0 to 400 inclusive',
    Please_enter_a_number_between_0_to_50_inclusive: 'Please enter a number between 0 to 50 inclusive',
    Please_enter_a_number_between_0_to_250_inclusive: 'Please enter a number between 0 to 250 inclusive',
    Please_enter_a_number_between_0_to_20_inclusive: 'Please enter a number between 0 to 20 inclusive',
    Systolic_Blood_Pressure: 'Systolic Blood Pressure',
    Diastolic_Blood_Pressure: 'Diastolic Blood Pressure',
    Health_Data_Type: 'Health Data Type',
    select_diabetes_therapy_label: 'select-diabetes-therapy-label',
    Please_select_any_health_data_to_add_: 'Please select any health data to add.',
    Recorded_Date_and_Time: 'Recorded Date and Time',
    Now: 'Now',
    New_Data: 'New Data',
    Recorded_Date: 'Recorded Date',
    Data: 'Data',
    Medical_History: 'Medical History',
    Diabetes_Type: 'Diabetes Type',
    select___: 'select...',
    No_diabetes: 'No diabetes',
    Type_1_Diabetes: 'Type 1 Diabetes',
    Type_2_Diabetes: 'Type 2 Diabetes',
    Gestational_Diabetes: 'Gestational Diabetes',
    Diabetes_Onset: 'Diabetes Onset',
    When_were_you_first_diagnosed_with_diabetes_: 'When were you first diagnosed with diabetes?',
    Eye_Surgery_History: 'Eye Surgery History',
    Had_eye_surgery: 'Had eye surgery',
    Never_had_eye_surgery: 'Never had eye surgery',
    Heart_Disease: 'Heart Disease',
    Has_heart_disease: 'Has heart disease',
    No_heart_disease: 'No heart disease',
    High_Blood_Pressure: 'High Blood Pressure',
    Yes: 'Yes',
    No: 'No',
    High_Cholesterol: 'High Cholesterol',
    No_Disease: 'No Disease',
    Mild_Retinopathy: 'Mild Retinopathy',
    Moderate_Retinopathy: 'Moderate Retinopathy',
    Severe_Retinopathy: 'Severe Retinopathy',
    Proliferative_Retinopathy: 'Proliferative Retinopathy',
    Macular_Edema_Presence: 'Macular Edema Presence',
    select_macular_edema_presence_label: 'select-macular-edema-presence-label',
    Absent: 'Absent',
    Present: 'Present',
    Enter_account_number_to_retrieve_patient_: 'Enter account number to retrieve patient.',
    Dashboard: 'Dashboard',
    Disclaimer__statistics_are_non_realtime_and_are_updated_midnightly_:
      'Disclaimer: statistics are non-realtime and are updated midnightly.',
    Welcome_back_: 'Welcome back',
    Have_a_nice_day_at_work: 'Have a nice day at work',
    Total_Patients: 'Total Patients',
    Urgent_Cases: 'Urgent Cases',
    Total_Eye_Exams: 'Total Eye Exams',
    Completed: 'Completed',
    DISEASE_DETECTION_SUMMARY: 'DISEASE DETECTION SUMMARY',
    __Diseases_are_those_marked_as_moderate_or_greater__AMD_and_DR___or_suspect__DME__Glaucoma_and_Media_Opacity_:
      '* Diseases are those marked as moderate or greater (AMD and DR), or suspect (DME, Glaucoma and Media Opacity)',
    Total_number_of_images_graded_by_TeleEye_MD: 'Total number of images graded by TeleEye MD',
    URGENT_CASES: 'URGENT CASES',
    Select_Organisation: 'Select Organisation',
    Approve_User: 'Approve User',
    Create_User: 'Create User',
    Registration_Success_: 'Registration Success!',
    Create_another_user: 'Create another user',
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    Start_typing_for_suggestions: 'Start typing for suggestions',
    Type_of_Camera_used_: 'Type of Camera used:',
    Organisation_Name: 'Organisation Name',
    Which_hospital__clinic_or_establishment_do_you_belong_to_:
      'Which hospital, clinic or establishment do you belong to?',
    Role: 'Role',
    Where_do_you_intend_to_practice_: 'Where do you intend to practice?',
    Where_do_you_intend_to_get_screened_: 'Where do you intend to get screened?',
    Password: 'Password',
    I_agree_to_the: 'I agree to the',
    Via_Invite_Link: 'Via Invite Link',
    Copy_Link: 'Copy Link',
    OR: 'OR',
    Email_Address: 'Email Address',
    Type_a_valid_email_and_hit_enter_or_copy_and_paste_multiple_emails:
      'Type a valid email and hit enter or copy and paste multiple emails',
    Invite_Users: 'Invite Users',
    Invitation_Success_: 'Invitation Success!',
    Almost_there__Please_ask_the_user_to_check_their_email_in_their_inbox_or_spam_folder_and_click_on_the_link_to_complete_their_registration_process_:
      'Almost there! Please ask the user to check their email in their inbox or spam folder and click on the link to complete their registration process.',
    Add_New_Organisation: 'Add New Organisation',
    Email_or_Mobile_No_: 'Email or Mobile No.',
    Mobile_Number: 'Mobile Number',
    Invalid_Input: 'Invalid Input',
    Invalid_medicare_number: 'Invalid medicare number',
    Invalid_reference_number: 'Invalid reference number',
    Select_Country: 'Select Country',
    Country: 'Country',
    Passwords_do_not_match: 'Passwords do not match',
    Password_must_be_at_least_8_characters: 'Password must be at least 8 characters',
    Password_must_contain_at_least_one_letter: 'Password must contain at least one letter',
    Password_must_contain_at_least_one_special_character: 'Password must contain at least one special character',
    password_confirm: 'password_confirm',
    Please_fill_up_all_required_fields_and_check_for_any_errors:
      'Please fill up all required fields and check for any errors',
    Please_agree_to_all_terms_and_conditions: 'Please agree to all terms and conditions',
    edit: 'edit',
    I_declare_that_the_above_information_is_true_and_correct_:
      'I declare that the above information is true and correct.',
    Enter_your_details: 'Enter your details',
    Booking_Successful: 'Booking Successful',
    BACK_TO_MEETINGS: 'BACK TO MEETINGS',
    There_is_no_meeting_available_today__please_try_another_day_:
      'There is no meeting available today, please try another day.',
    First_name: 'First name',
    Last_name: 'Last name',
    invalid_email_address: 'invalid email address',
    Phone: 'Phone',
    Teleconsultation: 'Teleconsultation',
    Contact_Details: 'Contact Details',
    Teleconsultation_Details: 'Teleconsultation Details',
    Today__HH_mm: "Today' HH:mm",
    Tomorrow__HH_mm: "Tomorrow' HH:mm",
    no_available_time: 'no available time',
    loading___: 'loading...',
    Details: 'Details',
    SimplyBook_me: 'SimplyBook.me',
    Add_a_Note__optional_: 'Add a Note (optional)',
    Please_describe_your_symptoms_or_leave_your_questions: 'Please describe your symptoms or leave your questions',
    Confirm_Booking: 'Confirm Booking',
    Next: 'Next',
    Available_Meeting_Start_Times: 'Available Meeting Start Times',
    JOIN: 'JOIN',
    VIEW_REPORT: 'VIEW REPORT',
    VIEW_NOTE: 'VIEW NOTE',
    Select_a_status_to_display_: 'Select a status to display.',
    The_patient_did_not_leave_a_note: 'The patient did not leave a note',
    The_eye_specialist_has_not_left_a_note_yet: 'The eye specialist has not left a note yet',
    Enter_any_notes_from_the_meeting_here______: 'Enter any notes from the meeting here . . .',
    Eye_Specialist: 'Eye Specialist',
    Patient_Name: 'Patient Name',
    Notes: 'Notes',
    Eye_Exam_Reports: 'Eye Exam Reports',
    View_Report_s_: 'View Report(s)',
    The_patient_doesn_t_have_any_history_meetings: "The patient doesn't have any history meetings",
    You_don_t_have_any_history_meetings_: "You don't have any history meetings.",
    Enable_TeleConsult: 'Enable TeleConsult',
    TeleConsults_History: 'TeleConsults History',
    TeleConsults: 'TeleConsults',
    Feature_unavailable: 'Feature unavailable',
    The_feature_is_currently_unavailable: 'The feature is currently unavailable',
    You_need_to_finish_an_eye_exam_to_book_a_TeleConsult: 'You need to finish an eye exam to book a TeleConsult',
    BOOK: 'BOOK',
    Meeting_room_will_open_5_min_before_the_meeting_begins: 'Meeting room will open 5 min before the meeting begins',
    You_don_t_have_any_upcoming_meetings_: "You don't have any upcoming meetings.",
    contact_us_: 'contact us.',
    New_Patient_Creation: 'New Patient Creation',
    This_patient_does_not_have_any_existing_exams_: 'This patient does not have any existing exams.',
    Add_New_Exam: 'Add New Exam',
    Unable_to_add_new_exam: 'Unable to add new exam',
    Each_patient_can_only_have_a_maximum_of_50_exams_: 'Each patient can only have a maximum of 50 exams.',
    Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_:
      'Please complete and submit the most recent exam to add new exam.',
    Risk_Analysis: 'Risk Analysis',
    Risk_Analysis_Unavailable: 'Risk Analysis Unavailable',
    Please_add_at_least_one_Risk_Prediction_to_view_Risk_Analaysis:
      'Please add at least one Risk Prediction to view Risk Analaysis',
    This_feature_will_be_coming_soon__stay_tuned_: 'This feature will be coming soon, stay tuned!',
    Add_Risk_Prediction: 'Add Risk Prediction',
    Risk_Prediction_Services_Coming_Soon___: 'Risk Prediction Services Coming Soon...',
    Enter_name_s__and_or_date_of_birth_to_retrieve_patient_: 'Enter name(s) and/or date of birth to retrieve patient.',
    Please_enter_some_patient_information_to_search: 'Please enter some patient information to search',
    Use_Account_Number: 'Use Account Number',
    No_Account_Number: 'No Account Number',
    Your_organisation_has_yet_to_register_any_patients_: 'Your organisation has yet to register any patients.',
    UPCOMING_APPOINTMENTS: 'UPCOMING APPOINTMENTS',
    Credits: 'Credits',
    Add_Credits: 'Add Credits',
    Add_free_credits_to_specific_organisation_below_: 'Add free credits to specific organisation below.',
    Completed_By: 'Completed By',
    You_have_yet_to_make_any_purchases_: 'You have yet to make any purchases.',
    Payment_Transactions: 'Payment Transactions',
    First_Time_offer_: 'First Time offer!',
    You_pay: 'You pay',
    Your_payment_has_been_cancelled__If_there_was_a_problem_with_the_transaction__please_contact_our_customer_support_or_try_again_:
      'Your payment has been cancelled. If there was a problem with the transaction, please contact our customer support or try again.',
    Total_Credits: 'Total Credits',
    CREDITS: 'CREDITS',
    Credit_Transactions: 'Credit Transactions',
    Subscription_success_: 'Subscription success!',
    Unit_Price: 'Unit Price',
    Max__Exams: 'Max. Exams',
    Paid_By: 'Paid By',
    You_have_yet_to_make_any_payment_: 'You have yet to make any payment.',
    What_will_be_different_: 'What will be different?',
    Join: 'Join',
    Please_select_a_service_to_continue_: 'Please select a service to continue.',
    You_may_select_more_services_to_add_to_calculate_different_risks_:
      'You may select more services to add to calculate different risks.',
    Medical_History_and_Health_Data_required_to_run_each_service_:
      'Medical History and Health Data required to run each service:',
    Risk_Predictions: 'Risk Predictions',
    Resume_Subscription: 'Resume Subscription',
    Cancel_Subscription: 'Cancel Subscription',
    Current_Plan: 'Current Plan',
    Cancelled_subscription_ends_on: 'Cancelled subscription ends on',
    __Trial_ends_on: '- Trial ends on',
    Next_bill_of: 'Next bill of',
    to_be_issued_on: 'to be issued on',
    Plan_: 'Plan:',
    Add_On: 'Add-On',
    Start_your_free_trial_today: 'Start your free trial today',
    By_choosing_a_subscription_package_that_suits_you_: 'By choosing a subscription package that suits you.',
    Unable_to_view_Billing_History: 'Unable to view Billing History',
    Billing_history_is_currently_unavailable_but_will_be_coming_soon_:
      'Billing history is currently unavailable but will be coming soon.',
    Billing_History: 'Billing History',
    We_are_sad_to_see_you_go__If_you_choose_to_cancel_now_: 'We are sad to see you go. If you choose to cancel now,',
    Cancellation_will_be_effective_at_the_end_of_your_current_billing_cycle_on:
      'Cancellation will be effective at the end of your current billing cycle on',
    You_can_still_use_the_service_until_then_: 'You can still use the service until then.',
    We_are_here_to_welcome_you_back_at_anytime__If_you_choose_to_resume_now_:
      'We are here to welcome you back at anytime. If you choose to resume now,',
    Your_next_payment_will_be_processed_on: 'Your next payment will be processed on',
    Your_subscription_will_continue_as_before_: 'Your subscription will continue as before.',
    Select_No__of_Exams_: 'Select No. of Exams:',
    Add_On_Purchase_Not_Available: 'Add-On Purchase Not Available',
    Finish_using_current_available_Add_On_exams_before_purchasing_more:
      'Finish using current available Add-On exams before purchasing more',
    One_time_Purchase: 'One-time Purchase',
    Subscriptions: 'Subscriptions',
    Add_On_Unavailable: 'Add-On Unavailable',
    Add_On_is_only_available_once_you_purchase_a_subscription_plan:
      'Add-On is only available once you purchase a subscription plan',
    Purchase: 'Purchase',
    Switch_Plans_Coming_Soon: 'Switch Plans Coming Soon',
    _For_enterprise_pricings__please: '*For enterprise pricings, please',
    Error_during_service_worker_registration_: 'Error during service worker registration:',
    content_type: 'content-type',
    Upgrade_your_subscription_if_you_wish_to_select_this_service:
      'Upgrade your subscription if you wish to select this service',
    Basic_Plan_required_: 'Basic Plan required!',
    Standard_Plan_required_: 'Standard Plan required!',
    Premium_Plan_required_: 'Premium Plan required!',
    AMD: 'AMD',
    DME: 'DME',
    DR: 'DR',
    GLAUCOMA: 'GLAUCOMA',
    MEDIA_OPACITY: 'MEDIA OPACITY',
    Disclaimer__RetinoScan_Glaucoma_and_AMD_is_for: 'Disclaimer: RetinoScan Glaucoma and AMD is for',
    research_use: 'research use',
    only_and_should_not_be_used_for_clinical_decision_making_:
      'only and should not be used for clinical decision making.',
    Result: 'Result',
    Upload_and_select_up_to_4_images_to_be_sent_for_grading__with_several:
      'Upload and select up to 4 images to be sent for grading, with several',
    Create: 'Create',
    User: 'User',
    Health_Condition____Right_Eye____Left_Eye: "Health Condition', 'Right Eye', 'Left Eye",
    Health_Condition: 'Health Condition',
    health_practitioners: 'health practitioners',
    within_TeleEye_MD: 'within TeleEye MD',
    You_can_navigate_away_from_this_page_while_waiting_for_results__start_another_exam__and_return_at_any_time_:
      'You can navigate away from this page while waiting for results, start another exam, and return at any time.',
    Create_Patient: 'Create Patient',
    Total_Patients_Screened: 'Total Patients Screened',
    Exam_Date: 'Exam Date',
    PENDING_ACTION: 'PENDING ACTION',
    Filter: 'Filter',
    AWAITING_RESULT: 'AWAITING RESULT',
    Chinese: 'Chinese',
    Indian: 'Indian',
    Malay: 'Malay',
    Caucasian: 'Caucasian',
    Patient_Details: 'Patient Details',
    Latest_Health_Data: 'Latest Health Data',
    Severity: 'Severity',
    Add_Comment: 'Add Comment',
    Eye_Images: 'Eye Images',
    DR_Presence: 'DR Presence',
    By_logging_in__you_agree_to_our: 'By logging in, you agree to our',
    Reviewer: 'Reviewer',
    Reviews_within: 'Reviews within',
    Oldest_First: 'Oldest First',
    Newest_First: 'Newest First',
    Default__All_: 'Default (All)',
    Phone_Number: 'Phone Number',
    If_you_have_the_user_s_email_address_that_is_within_your_organisation__you_can_invite_them_by_entering_their_email_addres_below__Instructions_will_be_sent_to_their_email_and_once_completed__they_will_be_added_to_the_organisation_:
      "If you have the user's email address that is within your organisation, you can invite them by entering their email addres below. Instructions will be sent to their email and once completed, they will be added to the organisation.",
    Invite_another_user: 'Invite another user',
    Practitioner: 'Practitioner',
    Patient: 'Patient',
    Optometrist: 'Optometrist',
    Sign_Up_with_your_Email: 'Sign Up with your Email',
    Branch: 'Branch',
    Location: 'Location',
    Branch_Location: 'Branch/Location',
    Your_practice_branch_name_or_location: 'Your practice branch name or location',
    Age_Related_Macular_Degeneration: 'Age-Related Macular Degeneration',
    for_age: 'for age',
    Glaucoma: 'Glaucoma',
    No_Image: 'No Image',
    DR_Severity: 'DR Severity',
    Exam_Details: 'Exam Details',
    Cup_to_Disc_Ratio: 'Cup to Disc Ratio',
    Disc_Asymmetry: 'Disc Asymmetry',
    Risk_of_Glaucoma_if: 'Risk of Glaucoma if',
    Send_Report: 'Send Report',
    REPORT_READY: 'REPORT READY',
    Additional_Information: 'Additional Information',
    Comment_to_Health_Practitioners_Only: 'Comment to Health Practitioners Only',
    Comment_to_Patient: 'Comment to Patient',
    Accident_and_Emergency: 'Accident and Emergency',
    '2_weeks': '2 weeks',
    '1_month': '1 month',
    '3_months': '3 months',
    '6_months': '6 months',
    Normal_screening_results___continue_annual_screening: 'Normal screening results - continue annual screening',
    No_referable_conditions_in_screening_results___Continue_annual_screening:
      'No referable conditions in screening results - Continue annual screening',
    Extra_Parameters: 'Extra Parameters',
    Parameters: 'Parameters',
    Diabetic_Macular_Edema: 'Diabetic Macular Edema',
    Media_Opacity: 'Media Opacity',
    Review_Summary: 'Review Summary',
    Reviewed_at: 'Reviewed at',
    please_login_instead: 'please login instead',
    or: 'or',
    To_enhance_your_account_s_security_: "To enhance your account's security:",
    Street_Address: 'Street Address',
    Street: 'Street',
    Address_Line_2__Floor_and_or_unit_number: 'Address Line 2: Floor and/or unit number',
    Suburb_City: 'Suburb/City',
    City: 'City',
    Town_City: 'Town/City',
    State: 'State',
    Type: 'Type',
    An_account_already_exists_for_this: 'An account already exists for this',
    Neighbourhood: 'Neighbourhood',
    organisation_admin: 'organisation admin',
    Organisation_Admin: 'Organisation Admin',
    Detected_Diseases: 'Detected Diseases',
    UNLIMITED: 'UNLIMITED',
    unlimited: 'unlimited',
    Image_Requirements: 'Image Requirements',
    Start_New_Exam: 'Start New Exam',
    Review: 'Review',
    Leave: 'Leave',
    Stay: 'Stay',
    Health_Data: 'Health Data',
    Eye_Exams: 'Eye Exams',
    Add_Health_Data: 'Add Health Data',
    Right_Uncorrected: 'Right Uncorrected',
    Left_Uncorrected: 'Left Uncorrected',
    Right_Best_Corrected: 'Right Best Corrected',
    Left_Best_Corrected: 'Left Best Corrected',
    Comments__optional_: 'Comments (optional)',
    Comments: 'Comments',
    e_g__Images_too_blurry__eyes_bleeding___: 'e.g. Images too blurry, eyes bleeding...',
    Submit_Exam_: 'Submit Exam?',
    Submit_Review_: 'Submit Review?',
    Provider_Number: 'Provider Number',
    Aboriginal: 'Aboriginal',
    Torres_Strait_Islander: 'Torres Strait Islander',
    Aboriginal_and_Torres_Strait_Islander: 'Aboriginal and Torres Strait Islander',
  },
  de: {
    ...missingTranslation.de,
    Lost_connection_to_server__please_refresh_the_page:
      'Verbindung zum Server verloren, bitte aktualisieren Sie die Seite',
    Unable_to_load_pooled_reviews__please_try_again_by_refreshing_the_page_:
      'Die gepoolten Bewertungen können nicht geladen werden. Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren.',
    There_was_a_problem_retrieving_the_audit__Please_contact_us_if_this_persists_:
      'Beim Abrufen der Prüfung ist ein Problem aufgetreten. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
    There_was_a_problem_submitting_the_audit__Please_contact_us_if_this_persists_:
      'Bei der Übermittlung der Prüfung ist ein Problem aufgetreten. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
    There_was_a_problem_assigning_the_review__Please_try_to_review_other_exams:
      'Es gab ein Problem beim Zuordnen der Prüfung. Bitte versuchen Sie, andere Prüfungen zu bewerten',
    There_was_a_problem_logging_in__Please_try_again_later_:
      'Bei der Anmeldung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    The_session_is_already_ended_: 'Die Sitzung ist bereits beendet.',
    You_have_successfully_signed_up_: 'Sie haben sich erfolgreich angemeldet.',
    There_was_a_problem_signing_up__Please_try_again_later_:
      'Bei der Anmeldung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    No_email_or_phone_number_provided: 'Keine E-Mail oder Telefonnummer angegeben',
    There_was_a_problem_checking_the_account__Please_try_again_later_:
      'Bei der Überprüfung des Kontos ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_created_: 'Erfolgreich erstellt!',
    There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_:
      'Beim Abrufen der Daten zu den Allgemeinen Geschäftsbedingungen ist ein Problem aufgetreten. Bitte aktualisieren Sie, um es erneut zu versuchen.',
    Thank_you_for_accepting_the_updated_terms_of_use_:
      'Vielen Dank, dass Sie die aktualisierten Nutzungsbedingungen akzeptieren.',
    'There_was_a_problem_when_accepting_Terms_and_Conditions__Please_contact_us_if_it_persists`_':
      'Es gab ein Problem beim Akzeptieren der Allgemeinen Geschäftsbedingungen. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht`.',
    Invitation_Link_Copied__Valid_for_24_hours_: 'Einladungslink kopiert! Gültig für 24 Stunden.',
    There_was_a_problem_joining_this_organisation__please_contact_your_organisation_admin_:
      'Es gab ein Problem beim Beitritt zu dieser Organisation. Bitte wenden Sie sich an die Verwaltung Ihrer Organisation.',
    Email_Verified_: 'E-Mail verifiziert!',
    Email_or_Mobile_Number_is_required: 'E-Mail oder Handynummer ist erforderlich',
    There_was_a_problem_creating_an_exam__Please_try_again_later_:
      'Beim Erstellen einer Prüfung ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_exams__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Prüfungen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_page_number_for_exams__please_try_again_:
      'Beim Abrufen der Seitenzahl für Prüfungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Uploading_image: 'Bild hochladen',
    Successfully_uploaded: 'Erfolgreich hochgeladen',
    There_is_a_problem_with_uploading__Please_refresh_page_and_try_again_:
      'Beim Hochladen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
    There_was_a_problem_deleting_image__Please_try_again_later_:
      'Beim Löschen des Bildes ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    disconnected_websocket_for_exam: 'Getrennter Websocket für Untersuchung',
    There_was_a_problem_retrieving_an_image_s_information__Please_try_again_later_:
      'Beim Abrufen der Bildinformationen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_the_order_details_: 'Beim Abrufen der Bestelldaten ist ein Problem aufgetreten.',
    There_was_a_problem_when_getting_exam_details__Please_try_to_refresh_the_page_:
      'Beim Abrufen der Untersuchungsdaten ist ein Problem aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
    There_was_a_problem_retrieving_urgent_exams__Please_refresh_the_page_to_try_again_:
      'Es gab ein Problem beim Abrufen von dringenden Untersuchungen. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Please_select_at_least_one_image_to_submit_:
      'Bitte wählen Sie mindestens ein Bild aus, das Sie einreichen möchten.',
    Image_s__submitted_for_grading: 'Zur Benotung eingereichte(s) Bild(er)',
    There_was_a_problem_when_submitting_images__Please_try_again_:
      'Beim Einreichen der Bilder ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_assigning_the_laterality_for_the_image__please_try_again_:
      'Es gab ein Problem bei der Zuweisung der Lateralität für das Bild. Bitte versuchen Sie es erneut.',
    No_services_selected_: 'Keine Dienste ausgewählt.',
    Successfully_submitted_: 'Erfolgreich eingereicht! ',
    There_was_a_problem_submitting_for_services__Please_try_again_later_:
      'Bei der Anmeldung zu den Diensten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_resubmitted_: 'Erfolgreiche Wiedervorlage!',
    There_was_a_problem_when_resubmitting__Please_try_again_:
      'Bei der Wiedervorlage ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_submitting_comments__Please_try_again_:
      'Bei der Übermittlung der Kommentare ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Loading_report___: 'Bericht laden...',
    Report_successfully_loaded_: 'Bericht erfolgreich geladen!',
    There_was_an_error_retrieving_auditors__Please_try_again_:
      'Beim Abrufen der Prüfer ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_an_error_retrieving_health_data__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Gesundheitsdaten ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_is_a_problem_when_adding_new_health_data__Please_try_again_later_:
      'Beim Hinzufügen neuer Gesundheitsdaten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_is_a_problem_when_updating_medical_history__Please_try_again_later_:
      'Bei der Aktualisierung der Krankengeschichte ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_loading_users__Please_refresh_the_page_to_try_again_:
      'Beim Laden der Benutzer ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_loading_organisations__Please_refresh_the_page_to_try_again_:
      'Beim Laden der Organisationen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_adding_this_organisation__Please_try_again_later_:
      'Beim Hinzufügen dieser Organisation ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_approved_: 'Erfolgreich genehmigt.',
    There_was_a_problem_approving_this_user__Please_try_again_later_:
      'Bei der Zulassung dieses Benutzers ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    Successfully_added_free_credits_: 'Erfolgreich kostenlose Credits hinzugefügt.',
    There_was_a_problem_adding_free_credits__Please_try_again_later_:
      'Es gab ein Problem beim Hinzufügen von kostenlosen Credits. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_meeting_hosts__Please_try_again_:
      'Es gab ein Problem bei der Abfrage von Meeting-Hosts. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_your_contact_information__Please_try_to_refresh_page_:
      'Beim Abrufen Ihrer Kontaktinformationen ist ein Problem aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
    There_was_a_problem_updating_your_contact_information__Please_try_again_:
      'Bei der Aktualisierung Ihrer Kontaktinformationen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    failed_to_update_contact_information: 'Kontaktinformationen nicht aktualisiert',
    There_was_a_problem_retrieving_meetings__Please_try_again_:
      'Beim Abrufen von Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_history_meetings__Please_try_again_:
      'Beim Abrufen von Verlaufsbesprechungen ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_available_slots__Please_try_again_:
      'Es gab ein Problem beim Abrufen der verfügbaren Slots. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_provider_s_first_available_date__Please_try_again_:
      'Beim Abrufen des ersten verfügbaren Termins des Anbieters ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_provider_schedule__Please_try_again_:
      'Beim Abrufen des Providerplans ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Meeting_successfully_canceled: 'Besprechung erfolgreich abgesagt',
    There_was_a_problem_when_connecting_to_server__Please_refresh_page_and_try_again:
      'Bei der Verbindung zum Server ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
    There_was_a_problem_when_canceling_meeting__Please_try_again_:
      'Bei der Absage des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    The_time_is_not_available__please_choose_another_time:
      'Die Zeit ist nicht verfügbar, bitte wählen Sie eine andere Zeit',
    There_was_a_problem_when_fetch_new_meeting_details__Please_try_again_:
      'Beim Abrufen neuer Besprechungsdetails ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_booking_meeting__Please_try_again_:
      'Bei der Buchung des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Successfully_rescheduled_meeting: 'Erfolgreich verschobenes Treffen',
    There_was_a_problem_when_rescheduling_meeting__Please_try_again_:
      'Bei der Verlegung des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    Your_comment_has_been_successfully_submitted_: 'Ihr Kommentar wurde erfolgreich übermittelt.',
    There_something_wrong_when_submitting_comment__please_try_again_later:
      'Beim Absenden des Kommentars ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.',
    The_meeting_has_ended: 'Die Sitzung ist beendet',
    There_was_a_problem_when_enter_meeting__Please_try_again_:
      'Beim Betreten des Meetings ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_when_get_meeting_details__Please_try_again_:
      'Beim Abrufen der Besprechungsdetails ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    There_was_a_problem_retrieving_patients__Please_try_again_:
      'Beim Abrufen von Patienten ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
    No_patients_found_: 'Keine Patienten gefunden.',
    No_matching_patient_records_found_: 'Keine passenden Patienten gefunden.',
    There_was_a_problem_retrieving_patient_details__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Patientendaten ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Successfully_updated_your_details_: 'Ihre Daten wurden erfolgreich aktualisiert.',
    Successfully_updated_patient_details_: 'Die Patientendaten wurden erfolgreich aktualisiert.',
    There_is_a_problem_when_updating_patient_details__Please_try_again_later_:
      'Beim Aktualisieren der Patientendaten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_when_retrieving_patient_s_exams__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen der Patientenuntersuchungen ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_getting_your_details__Please_try_again_later_:
      'Bei der Erfassung Ihrer Daten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_creating_a_new_patient__Please_try_again_later_:
      'Beim Anlegen eines neuen Patienten ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_price_groups__Please_try_again_later_:
      'Beim Abrufen der Preisgruppen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
    There_was_a_problem_retrieving_your_credits_data__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Kreditdaten ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_the_service_prices__Please_refresh_the_page_to_try_again_:
      'Bei der Abfrage der Servicepreise ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_your_purchase_history__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihrer Kaufhistorie ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    There_was_a_problem_retrieving_your_payment_history__Please_refresh_the_page_to_try_again_:
      'Beim Abrufen Ihres Zahlungsverhaltens ist ein Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen.',
    Successfully_added_STDR_Risk_Prediction: 'STDR-Risikovorhersage erfolgreich hinzugefügt',
    Successfully_added_DR_Risk_Prediction: 'Erfolgreich hinzugefügt: DR Risk Prediction',
    DR_Risk_Prediction_report_ready: 'Bericht zur DR-Risikovorhersage fertig',
    Successfully_cancelled_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Das Abonnement wurde erfolgreich gekündigt. Bitte warten Sie ein paar Sekunden und die Seite wird automatisch neu geladen',
    Successfully_resumed_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Das Abonnement wurde erfolgreich wiederaufgenommen. Bitte warten Sie ein paar Sekunden und die Seite wird automatisch neu geladen',
    Confirm: 'Bestätigen',
    We_ve_Updated_Our_Terms: 'Wir haben unsere Bedingungen aktualisiert',
    In_order_to_continue_using_TeleEye_MD__you_will_need_to_agree_with_our_latest_Terms_of_Use_:
      'Um TeleEye MD weiterhin nutzen zu können, müssen Sie unseren aktuellen zustimmen',
    Terms_of_Use: 'Nutzungsbedingungen',
    progressbar: 'Fortschrittsanzeige',
    Login: 'Anmeldung',
    There_was_a_problem_connecting_to_the_server__Please_check_your_internet_connection_and_try_again_:
      'Es gab ein Problem bei der Verbindung zum Server. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
    _scheduling_simply_book_auth_get_tokens: '/scheduling/simply_book/auth/get_tokens',
    Edit_Comment: 'Kommentar bearbeiten',
    Submit: 'Einreichen',
    script: 'Skript',
    Patient_s_eyes_bleeding__Image_blurry__Let_the_eye_reviewer_know_by_adding_a_comment_:
      'Bluten die Augen des Patienten? Das Bild ist unscharf? Teilen Sie es dem Augenarzt mit, indem Sie einen Kommentar hinzufügen!',
    No_comment_left: 'Kein Kommentar übrig',
    Operator_s_Comments: 'Kommentare des Betreibers',
    Download_Report: 'Bericht herunterladen',
    QA: 'QS',
    No_Result: 'Kein Ergebnis',
    Which_Eye_: 'Welches Auge?',
    Loading___: 'Laden...',
    Left: 'Links',
    Right: 'Rechts',
    Upgrade_for: 'Upgrade für',
    for: 'für',
    Go_to_Subscriptions: 'Zu den Abonnements gehen',
    subtitle: 'Untertitel',
    Prediction_Occurrence_: 'Vorhersage Auftreten:',
    View_Report: 'Bericht ansehen',
    Submit_for_Grading: 'Zur Benotung einreichen',
    Order_Services: 'Dienstleistungen bestellen',
    Submission_not_allowed_: 'Einreichung nicht erlaubt.',
    Please_subscribe_to_a_plan_to_start_screening: 'Bitte abonnieren Sie einen Plan, um mit dem Screening zu beginnen',
    Images_uploaded_but_not_selected: 'Bilder hochgeladen, aber nicht ausgewählt',
    Please_select_images_to_submit_for_grading:
      'Bitte wählen Sie die Bilder aus, die Sie zur Benotung einreichen möchten',
    Free_Trial: 'Kostenlose Testversion',
    Free_Plan: 'Freier Plan',
    OPEN_MOBILE_APP: 'MOBILE APP ÖFFNEN',
    Log_Out: 'Abmelden',
    Patient_LookUp: 'Patientensuche',
    Available_Exams_: 'Verfügbare Prüfungen:',
    Account_No_: 'Konto-Nr.',
    Terms: 'AGB',
    Below_is_your: 'Nachstehend finden Sie Ihre',
    Treat_it_as_your_identification_number_when_going_for_any_eye_screening__You_don_t_need_to_remember_it__as_it_is_also_sent_to_your_email_:
      'Betrachten Sie sie als Ihre Identifikationsnummer, wenn Sie zu einem Augenscreening gehen! Sie brauchen sie sich nicht zu merken, da sie auch an Ihre E-Mail geschickt wird.',
    This_is_the_patient_s: 'Das ist der Patient',
    Sorry__This_page_does_not_exist_: 'Entschuldigung! Diese Seite existiert nicht.',
    Back_to_Home: 'Zurück zu Home ',
    Your_password_needs_to_contain_at_least_8_characters_: 'Ihr Passwort muss mindestens 8 Zeichen enthalten.',
    'Your_password_can’t_be_too_similar_to_your_other_personal_information_':
      'Ihr Passwort darf Ihren anderen persönlichen Daten nicht zu ähnlich sein.',
    'Your_password_can’t_be_a_commonly_used_password_': 'Ihr Passwort darf kein häufig verwendetes Passwort sein.',
    'Your_password_can’t_be_entirely_numeric_': 'Ihr Passwort darf nicht ausschließlich aus Zahlen bestehen.',
    Coming_soon: 'Demnächst verfügbar',
    This_feature_is_currently_still_under_production_: 'Diese Funktion befindet sich derzeit noch in der Produktion.',
    Please_look_forward_to_our_future_releases_: 'Freuen Sie sich auf unsere zukünftigen Veröffentlichungen!',
    Cancel: 'Abbrechen',
    Go_Back: 'Zurück',
    Unknown: 'Unbekannt',
    Visual_Acuity__Uncorrected_: 'Sehschärfe (unkorrigiert)',
    Visual_Acuity__Best_Corrected_: 'Sehschärfe (bestkorrigiert)',
    IOP__mmHg_: 'IOP (mmHg)',
    HbA1c____: 'HbA1c (%)',
    Blood_Pressure__mmHg_: 'Blutdruck (mmHg)',
    Diabetes_Therapy: 'Diabetes-Therapie',
    Postprandial_Blood_Glucose__mg_dL_: 'Postprandiale Blutzuckerwerte (mg/dL)',
    Triglyceride__mg_dL_: 'Triglyzerid (mg/dL)',
    No_light_perception__NLP_: 'Keine Lichtwahrnehmung (NLP)',
    Light_perception__LP_: 'Lichtwahrnehmung (LP)',
    Hand_movements__HM_: 'Handbewegungen (HM)',
    Counting_fingers_at_1_meters__CF_: 'Fingerzählen in 1 Meter Entfernung (CF)',
    Overview: 'Übersicht',
    All_Patients: 'Alle Patienten',
    Register_Patient: 'Patient anmelden',
    Subscription: 'Abonnement',
    Settings: 'Einstellungen',
    Pending_Reviews: 'Ausstehende Bewertungen',
    Completed_Reviews: 'Abgeschlossene Bewertungen',
    My_TeleConsults: 'Meine TeleConsults',
    My_Exams: 'Meine Prüfungen',
    My_Profile: 'Mein Profil',
    Register_User: 'Benutzer registrieren',
    Invite_User: 'Benutzer einladen',
    All_Exams: 'Alle Prüfungen',
    Approve_Users: 'Benutzer genehmigen',
    Organisations: 'Organisationen',
    According_to_Medicare: 'Nach Angaben von Medicare',
    Country_of_Service: 'Land der Dienstleistung',
    Medicare_No_: 'Medicare Nr.',
    Post_Code: 'Postleitzahl',
    Ethnicity: 'Ethnizität',
    NRIC__Last_4_Characters_: 'NRIC (Letzte 4 Zeichen)',
    According_to_ID_Passport: 'Laut ID/Pass',
    ID_CARD__Personalausweis_: 'ID CARD (Personalausweis)',
    Individual_Health_Identifier__IHI__number: 'Individuelle Gesundheitsidentifikationsnummer (IHI)',
    NHS_Number: 'NHS-Nummer',
    Postcode: 'Postleitzahl',
    ID_Card_Number: 'Ausweisnummer',
    REFER_TO_REPORT: 'SIEHE BERICHT',
    AI_Grading___: 'AI Benotung...',
    Not_Applicable: 'Nicht anwendbar',
    Unable_to_Grade: 'Nicht bewertbar',
    Non_Referable: 'Regulär',
    Referable: 'Kontrollbedürftig',
    Not_Urgent: 'Nicht dringend',
    Urgent: 'Dringend',
    Resolved: 'Gelöst',
    Normal: 'Normal',
    Suspected: 'Verdacht',
    Mild: 'Mild',
    Moderate: 'Mäßig',
    Severe: 'Schwer',
    Non_Significant: 'Unwesentlich',
    Significant: 'Bedeutsam',
    Mild_NPDR: 'Milde NPDR',
    Moderate_NPDR: 'Mäßige NPDR',
    Severe_NPDR: 'Schwere NPDR',
    PDR: 'PDR',
    Suspect_DR_Disease: 'Verdacht auf DR-Krankheit',
    Suspect_AMD_Disease: 'Verdacht auf AMD-Krankheit',
    Suspect_AMD_disease__High_probability_AMD__some_AMD_signs_:
      'Verdacht auf AMD-Erkrankung (hohe Wahrscheinlichkeit für AMD, einige AMD-Anzeichen)',
    Suspect_DR_disease__High_probability__some_DR_Signs_:
      'Verdacht auf DR-Krankheit (hohe Wahrscheinlichkeit, einige DR-Zeichen)',
    Click_to_update: 'Zum Aktualisieren anklicken',
    RetinoScan: 'RetinoScan',
    RetinoScan_analyses_retinal_images_to_accurately_identify_patients__with_Diabetic_Retinopathy__Glaucoma_and_or_Age_Related_Macular_Degeneration_:
      'RetinoScan analysiert Netzhautbilder, um Patienten mit diabetischer Retinopathie, Glaukom und/oder altersbedingter Makuladegeneration genau zu identifizieren.',
    RetinoScan_Plus: 'RetinoScan+',
    For_patients_with_a_disease_diagnosis__RetinoScan_Advanced_determines_the_disease_severity_and_provide_lesion_monitoring_services_:
      'Bei Patienten mit einer Krankheitsdiagnose bestimmt RetinoScan Advanced den Schweregrad der Erkrankung und bietet Dienste zur Überwachung der Läsionen.',
    Suspected_Cases: 'Verdachtsfälle',
    Review_by_Eye_Professional: 'Bewertung durch Augenprofi',
    Comprehensive_Patient_Report: 'Umfassender Patientenbericht',
    Class_II_Medical_Device: 'Medizinprodukt der Klasse II',
    Clinically_Validated: 'Klinisch validiert',
    Cloud_Storage: 'Cloud-Speicher',
    Patient_Data_Retention: 'Aufbewahrung von Patientendaten',
    In_accordance_to_HIPPA_compliances__all_patients__data_will_be_stored_and_backed_up_for_15_years:
      'In Übereinstimmung mit den HIPPA-Vorschriften werden alle Patientendaten 15 Jahre lang gespeichert und gesichert.',
    '15_years': '15 Jahre',
    Referral_Pathway: 'Verweisungspfad',
    Coming_Soon: 'Demnächst verfügbar',
    Forgot_your_password_: 'Haben Sie Ihr Passwort vergessen?',
    We_can_send_you_an_email_with_the_link_to_reset_it_:
      'Wir können Ihnen eine E-Mail mit dem Link zum Zurücksetzen der Daten schicken.',
    E_mail: 'E-mail',
    SEND_EMAIL: 'EMAIL SENDEN',
    Check_your_email: 'Prüfen Sie Ihre E-Mail',
    If_your_email_exist_in_our_system_the_reset_link_will_be_sent_to_your_inbox_Please_do_also_check_if_it_s_in_your_spam_folder_:
      'Wenn Ihre E-Mail in unserem System vorhanden ist, wird der Link zum Zurücksetzen an Ihren Posteingang gesendet. Bitte überprüfen Sie auch, ob er sich in Ihrem Spam-Ordner befindet.',
    Your_password_can_t_be_entirely_numeric_: 'Ihr Passwort darf nicht ausschließlich aus Zahlen bestehen.',
    Invalid_password: 'Ungültiges Passwort',
    Create_new_password: 'Neues Passwort erstellen',
    Please_enter_a_new_password_to_reset_your_password_:
      'Bitte geben Sie ein neues Passwort ein, um Ihr Passwort zurückzusetzen.',
    New_Password: 'Neues Passwort',
    toggle_password_visibility: 'Passwort-Sichtbarkeit umschalten',
    Confirm_Password: 'Bestätigen Sie Ihr Passwort',
    Passwords_must_be_equal: 'Passwörter müssen gleich sein',
    RESET_PASSWORD: 'PASSWORT ZURÜCKSETZEN',
    Password_Changed: 'Passwort geändert',
    You_have_successfully_changed_your_password__Try_logging_in_now_:
      'Sie haben Ihr Passwort erfolgreich geändert! Versuchen Sie jetzt, sich anzumelden!',
    Password_Change_Unsuccessful: 'Passwortänderung erfolglos',
    Sorry__There_must_be_a_problem_on_our_side__Please_contact_customer_support_to_report_this_problem_:
      'Entschuldigung! Es muss ein Problem auf unserer Seite vorliegen. Bitte kontaktieren Sie den Kundendienst, um dieses Problem zu melden.',
    Wrong_digit_code__Try_Again: 'Falscher Zahlencode. Erneut versuchen',
    Enter_your_Email_or_Mobile_number: 'Geben Sie Ihre E-Mail oder Handynummer ein',
    NEXT: 'WEITER',
    next: 'weiter',
    Log_in: 'Anmelden',
    Login_with_your_Email_or_Mobile_No_: 'Melden Sie sich mit Ihrer E-Mail- oder Handynummer an.',
    Forgot_Password: 'Passwort vergessen',
    LOGIN: 'ANMELDUNG',
    Don_t_have_an_account_: 'Sie haben noch kein Konto?',
    Sign_Up: 'Abonnieren',
    You_may_now_log_into_your_account_with_your_email_:
      'Sie können sich jetzt mit Ihrer E-Mail-Adresse in Ihr Konto anmelden.',
    You_are_already_logged_in_: 'Sie sind bereits angemeldet.',
    If_you_have_already_logged_in__please_log_out_first_and_refresh_this_page_If_not__please_contact_our_customer_support_to_report_this_problem_:
      'Wenn Sie bereits angemeldet sind, melden Sie sich bitte ab und aktualisieren Sie diese Seite, andernfalls wenden Sie sich bitte an unseren Kundensupport, um das Problem zu melden.',
    LOG_OUT: 'ABMELDEN',
    success: 'Erfolg',
    Activation_Success_: 'Aktivierungserfolg!',
    Activation_Fail___: 'Aktivierung fehlgeschlagen...',
    Sorry__You_might_have_already_been_activated__so_try_to_log_in__If_not_please_contact_our_customer_service_to_report_this_problem_:
      'Entschuldigung! Möglicherweise wurden Sie bereits aktiviert. Versuchen Sie, sich anzumelden. Falls nicht, wenden Sie sich bitte an unseren Kundendienst, um dieses Problem zu melden.',
    Please_set_your_password_so_that_you_can_use_it_to_log_into_this_platform_:
      'Bitte legen Sie Ihr Passwort so fest, dass Sie sich damit auf dieser Plattform anmelden können.',
    SET_PASSWORD: 'PASSWORT SETZEN',
    Password_Successfully_Set_: 'Passwort erfolgreich gesetzt!',
    You_may_now_log_into_your_account_with_your_credentials_:
      'Sie können sich nun mit Ihren Anmeldedaten bei Ihrem Konto anmelden.',
    Password_Set_Unsuccessful___: 'Passwort setzen erfolglos...',
    OTP_Verification: 'OTP-Verifizierung',
    Enter_the_6_digit_code_sent_to_: 'Geben Sie den 6-stelligen Code ein, der an gesendet wurde:',
    Didn_t_receive_the_code_: 'Sie haben den Code nicht erhalten?',
    Resend: 'Erneut senden',
    I_am_a____: 'Ich bin ein ...',
    Already_have_an_account_: 'Sie haben bereits ein Konto?',
    Log_In: 'Anmelden',
    SIGN_UP: 'ABONNIEREN',
    EMAIL: 'E-MAIL',
    MOBILE_NUMBER: 'MOBILNUMMER',
    VERIFIED: 'VERIFIZIERT',
    email_address: 'Email-adresse',
    mobile_number: 'Mobilnummer',
    Your_TeleEye_MD_account_is_now_activated_: 'Ihr TeleEye MD-Konto ist nun aktiviert.',
    It_will_take_up_to_1_business_day_for_TeleMedC_admins_to_verify_your_details_and_activate_your_account_:
      'Es kann bis zu 1 Arbeitstag dauern, bis die TeleMedC-Administratoren Ihre Angaben überprüft und Ihr Konto aktiviert haben.',
    Save: 'Speichern',
    Edit_Data: 'Daten bearbeiten',
    Account_Number: 'Kontonummer',
    Not_available: 'Nicht verfügbar',
    Patient_s_account_number_is_unique_and_not_available_for_modification_:
      'Die Kontonummer des Patienten ist eindeutig und kann nicht geändert werden.',
    First_Name: 'Vorname',
    Last_Name: 'Nachname',
    Date_of_Birth: 'Geburtsdatum',
    Gender: 'Geschlecht',
    select_gender_label: 'select-gender-label',
    select_ethnicity_label: 'select-ethnicity-label',
    These_reviews_have_been_assigned_to_you__please_complete_them_as_soon_as_possible_:
      'Diese Übersichten wurden Ihnen zugewiesen, bitte erledigen Sie sie so bald wie möglich.',
    There_are_currently_no_exams_available_for_review_right_now_:
      'Zurzeit sind keine Untersuchungen zur Übersicht verfügbar.',
    Leave_this_Page_: 'Verlassen Sie diese Seite?',
    The_changes_you_made_will_not_be_saved_and_the_review_will_be_returned_to_the_list_of_pending_reviews_:
      'Die von Ihnen vorgenommenen Änderungen werden nicht gespeichert und die Bewertung wird wieder in die Liste der ausstehenden Bewertungen aufgenommen.',
    Reviewed_Exam: 'Überprüfte Untersuchung',
    Review_Exam: 'Untersuchung überprüfen',
    This_Exam_Review_is_from_the_Pool__it_will_be_reassigned_if_this_page_is_closed_changed_:
      'Dieser Prüfungsbericht stammt aus dem Pool, er wird neu zugewiesen, wenn diese Seite geschlossen/geändert wird.',
    Please_fill_out_all_required_fields: 'Bitte füllen Sie alle erforderlichen Felder aus',
    View_completed_reviews_: 'Abgeschlossene Überprüfungen anzeigen.',
    View: 'Ansicht',
    There_are_currently_no_completed_reviews_: 'Derzeit gibt es keine abgeschlossenen Überprüfungen.',
    NON_URGENT: 'NICHT DRINGEND',
    URGENT: 'DRINGEND',
    Eye_exam_determined_as______: 'Augenuntersuchung bestimmt als...',
    If_this_case_is_URGENT__we_recommend_you_to_contact_the_patient_immediately_:
      'Wenn es sich um einen DRINGENDEN Fall handelt, empfehlen wir Ihnen, den Patienten sofort zu kontaktieren.',
    Requires_referral_to_ophthalmologist_additional_actions_:
      'Erfordert Überweisung an einen Augenarzt/zusätzliche Maßnahmen:',
    follow_up_screening_label: 'follow-up-screening-label',
    Select: 'Auswählen',
    Can_be_viewed_only_by_the_patient_s: 'Kann nur von den',
    Write_comment_here___: 'Hier Kommentar schreiben...',
    Can_be_viewed_by_both_the_patient_and_practitioners_within_TeleEye_MD_and_the_report:
      'Der Bericht kann sowohl vom Patienten als auch vom behandelnden Arzt in TeleEye MD eingesehen werden.',
    Can_be_viewed_within_TeleEye_MD_and_the_exam_report:
      'Kann in TeleEye MD und im Untersuchungsbericht angezeigt werden',
    Patient_s_Profile: 'Profil des Patienten',
    All_exam_reviews_are_taken__please_refresh_to_see_more_reviews_:
      'Alle Untersuchungskritiken sind aufgenommen, bitte aktualisieren Sie, um weitere Kritiken zu sehen.',
    Filter_By_Group: 'Nach Gruppe filtern',
    Total_reviews_pending: 'Anhängige Überprüfungen insgesamt',
    Review__for_suspected_cases_only_: 'Überprüfung (nur bei Verdachtsfällen)',
    Upload_Images: 'Bilder hochladen',
    Order_Summary: 'Zusammenfassung der Bestellung',
    Order_Eye_Exam: 'Augenuntersuchung bestellen',
    Order_exam_without_eye_images: 'Untersuchung ohne Augenbilder bestellen',
    Eye_Exam: 'Augenuntersuchung',
    Reviewed_by: 'Überprüft von',
    The_next_available_eye_reviewer: 'Der nächste verfügbare Augenarzt',
    Current_Balance: 'Aktueller Saldo',
    Balance_after_Order: 'Saldo nach Bestellung',
    Eye_reviewer_s_Name: 'Name des Augenprüfers',
    Eye_Reviewer_s_Name: 'Name des Augenprüfers',
    Only_suspected_cases_are_reviewed_: 'Es werden nur Verdachtsfälle überprüft.',
    Any_Eye_Reviewer: 'Jeder Augenarzt',
    This_exam_will_be_submitted_to_the_pool_for_eye_professionals_review_as_soon_as_possible_:
      'Diese Untersuchung wird so bald wie möglich an den Pool zur Überprüfung durch die Augenärzte weitergeleitet.',
    Bad_Quality_Images_may_not_be_properly_graded_by_DR_Lite__Please_select_DR_Grader_if_you_wish_to_continue_:
      'Bilder von schlechter Qualität werden von DR Lite möglicherweise nicht richtig bewertet. Bitte wählen Sie DR Grader, wenn Sie fortfahren möchten.',
    Left_Right_not_assigned_to_image_: 'Links/Rechts nicht dem Bild zugeordnet.',
    Only_a_maximum_of_2_images_of_left_eye_is_allowed_for_submission_:
      'Es dürfen nur maximal 2 Bilder des linken Auges eingereicht werden.',
    Only_a_maximum_of_2_images_of_right_eye_is_allowed_for_submission_:
      'Es dürfen nur maximal 2 Bilder des rechten Auges eingereicht werden.',
    Please_select_your_desired_services_for_the_eye_images_:
      'Bitte wählen Sie die gewünschten Dienstleistungen für die Augenbilder aus.',
    Possible_reasons_for_bad_quality_image_: 'Mögliche Gründe für eine schlechte Bildqualität:',
    '1__Blurred_image': '1. Unscharfes Bild',
    '2__Low_illumination': '2. Geringe Beleuchtungsstärke',
    '3__Optic_lens_reflection': '3. Reflexion der optischen Linse',
    Could_be_affected_by_: 'Könnte betroffen sein von:',
    '1__Optic_lens_opacity_issue': '1. Problem der Linsentrübung',
    '2__Improper_operation': '2. Unsachgemäßer Betrieb',
    '3__Dirty_camera_lens': '3. Verschmutztes Kameraobjektiv',
    '3__Small_pupil': '3. Kleine Schülerin',
    Create_Exam: 'Untersuchung erstellen',
    ADDED_SERVICES: 'ZUSÄTZLICHE DIENSTLEISTUNGEN ',
    SELECT_REVIEWER: 'GUTACHTER AUSWÄHLEN',
    UPLOAD_IMAGES__OPTIONAL_: 'BILDER HOCHLADEN (OPTIONAL)',
    requirements: 'Anforderungen',
    ADD_A_COMMENT__OPTIONAL_: 'EINEN KOMMENTAR HINZUFÜGEN (OPTIONAL)',
    Color_fundus_images: 'Farbige Fundusbilder',
    The_angle_of_retina_field_of_view__FOV__from_the_image_is_between_35_and_50_degrees:
      'Der Winkel des Sichtfelds (FOV) der Netzhaut zum Bild liegt zwischen 35 und 50 Grad.',
    Minimum_size_of_the_retina_FOV_to_be_1000_x_1000_pixels: 'Mindestgröße des FOV der Netzhaut: 1000 x 1000 Pixel',
    Macula_centred_images: 'Makula-zentrierte Bilder',
    PNG_or_JPG_format: 'PNG- oder JPG-Format',
    Auto_Select: 'Automatische Auswahl',
    Deselect_All: 'Alle abwählen',
    You_can_only_upload_a_maximum_of_4_images_: 'Sie können nur maximal 4 Bilder hochladen.',
    Click_or_drop_an_image_here_to_upload: 'Klicken Sie zum Hochladen auf ein Bild oder legen Sie es hier ab',
    Failed_to_add_services_or_show_exam_details__Refresh_or_try_again_:
      'Das Hinzufügen von Leistungen oder das Anzeigen von Untersuchungsdetails ist fehlgeschlagen. Aktualisieren Sie oder versuchen Sie es erneut.',
    Add_More_Services: 'Weitere Dienste hinzufügen',
    You_may_choose_to_select_more_services_to_add_to_your_exam_:
      'Sie haben die Möglichkeit, weitere Dienste zu wählen, um Ihre Untersuchung zu ergänzen.',
    AI_grading_is_taking_longer_than_expected______: 'Die AI-Einstufung dauert länger als erwartet...',
    You_might_want_to_check_your_network_and_resubmit_it_again_:
      'Überprüfen Sie Ihr Netzwerk und senden Sie es erneut ab.',
    Please_contact_our_support_for_help: 'Bitte kontaktieren Sie unseren Support für Hilfe',
    PENDING_REVIEW: 'AUSSTEHENDE ÜBERPRÜFUNG',
    The_eye_exam_results_has_been_sent_to_an_eye_health_professional_to_review_:
      'Die Ergebnisse der Augenuntersuchung wurden zur Überprüfung an einen Augenarzt geschickt.',
    Please_check_back_after_a_while_: 'Bitte schauen Sie nach einer Weile wieder vorbei.',
    Email_Sent: 'E-Mail gesendet',
    SMS_Sent: 'SMS gesendet',
    Send_a_report_link_to_the_patient_through_the_chosen_method_:
      'Senden Sie dem Patienten über die gewählte Methode einen Berichtslink.',
    Email: 'E-mail',
    Invalid_email: 'Ungültige E-Mail',
    Send_Email: 'EMAIL SENDEN',
    SMS: 'SMS',
    _Coming_Soon_: '(Bald verfügbar)',
    Send_SMS: 'SMS versenden',
    Reviewed_on: 'Überprüft am',
    yyyy_MM_dd_hh_mmaaa: 'jjjj-MM-tt hh:mmaaa',
    Recommended_next_eye_exam_in: 'Empfohlene nächste Augenuntersuchung in',
    Upgrade_to_proactively_participate_in_your_own_well_being_and_care_:
      'Upgrade zur proaktiven Beteiligung an Ihrem eigenen Wohlbefinden und Ihrer Pflege.',
    Detect_the_severity_of_Diabetic_Retinopathy___Glaucoma__with_Cup_to_disc_Ratio_:
      'Erkennung des Schweregrads der diabetischen Retinopathie und des Glaukoms (mit Cup-to-Disc-Ratio)',
    Download_Mobile_App_to_Purchase: 'Mobile App zum Kauf herunterladen',
    Participate_in_telehealth_consultations_with_healthcare_providers_for_guidance_and_feedback_on_your_most_recent_eye_exam:
      'Nehmen Sie an telemedizinischen Konsultationen mit Gesundheitsdienstleistern teil, um Beratung und Feedback zu Ihrer letzten Augenuntersuchung zu erhalten.',
    Go_to_My_TeleConsult: 'Zu Mein TeleConsult gehen',
    Eye_Exam_Results: 'Ergebnisse der Augenuntersuchung',
    Report_is_not_ready: 'Der Bericht ist nicht fertig',
    The_report_s__will_be_ready_for_download_after_all_exam_are_graded_and_reviewed__suspected_cases_only_:
      'Der/die Bericht(e) kann/können heruntergeladen werden, nachdem alle Untersuchungen bewertet und überprüft wurden (nur bei Verdachtsfällen)',
    Download_Final_Reports: 'Download Abschlussberichte',
    Right_Eye: 'Rechtes Auge',
    Left_Eye: 'Linkes Auge',
    No_Eyes_Captured: 'Keine Augen gefangen',
    No_image_to_show_: 'Kein Bild zu zeigen.',
    right_eye: 'rechtes Auge',
    left_eye: 'linkes Auge',
    Enter_Cup_to_Disc_Ratio: 'Verhältnis von Becher zu Scheibe eingeben',
    Leave_field_blank_if_it_s_unable_to_grade: 'Lassen Sie das Feld leer, wenn es nicht bewertet werden kann.',
    Status: 'Status',
    DR_Lite: 'DR-Lite',
    DR_Grader: 'DR-Grader',
    C_D_Ratio: 'C/D Ratio',
    Your_organisation_has_yet_to_create_any_exams_: 'Ihre Organisation hat noch keine Prüfungen abgelegt.',
    You_may_select_to_view_any_of_your_past_exams_in_detail_:
      'Sie können jede Ihrer vergangenen Prüfungen im Detail ansehen.',
    You_have_not_completed_any_exams_: 'Sie haben keine Untersuchungen abgelegt.',
    Confirm_Submission: 'Einreichung bestätigen',
    Submitting_images_for_grading_: 'Einreichen von Bildern zur Bewertung.',
    Confirm_and_Grade: 'Bestätigen und benoten',
    Confirm_and_Order: 'Bestätigen und Bestellen',
    Total_Image_s_: 'Gesamte(s) Bild(er)',
    Ordered_Services: 'Bestellte Dienstleistungen',
    Exam_will_be_audited_by: 'Die Untersuchung wird abgenommen von',
    The_next_available_auditor: 'Der nächste verfügbare Prüfer',
    Please_select_images_to_continue: 'Bitte wählen Sie Bilder aus, um fortzufahren',
    Ordered_Exam: 'Bestellte Untersuchung',
    EYE_REVIEWER: 'AUGENARZT',
    Any_eye_reviewer: 'Jeder Augenarzt',
    Next_Steps: 'Nächste Schritte',
    UPLOAD_IMAGES_OR_WAIT_FOR_PATIENT_TO_VISIT_OPTICAL_STORE:
      'BILDER HOCHLADEN ODER WARTEN, BIS DER PATIENT EINEN OPTIKER AUFSUCHT',
    'Upload_and_select_images_to_be_sent_for_grading__with_several_nbsp;':
      'Hochladen und Auswählen von Bildern, die zur Bewertung gesendet werden sollen, mit mehreren&nbsp;',
    This_Report_is_Protected: 'Dieser Bericht ist geschützt',
    To_unlock_and_view_report__enter_your_last_name_and_DOB:
      'Um den Bericht zu entsperren und anzuzeigen, geben Sie Ihren Nachnamen und Ihr Geburtsdatum ein.',
    Unlock_Report: 'Bericht freischalten',
    Having_issues_unlocking_report__Contact_your_eye_screening_location_:
      'Haben Sie Probleme beim Entsperren des Berichts? Wenden Sie sich an Ihre Augenuntersuchungsstelle.',
    There_are_no_urgent_cases_to_show_: 'Es gibt keine dringenden Fälle zu zeigen.',
    Please_enter_a_number_between_0_to_300_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 300 ein.',
    Please_enter_a_number_between_0_to_700_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 700 ein.',
    Please_enter_a_number_between_0_to_400_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 400 ein.',
    Please_enter_a_number_between_0_to_50_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 50 ein.',
    Please_enter_a_number_between_0_to_250_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 250 ein.',
    Please_enter_a_number_between_0_to_20_inclusive: 'Bitte geben Sie eine Zahl zwischen 0 und 20 ein.',
    Systolic_Blood_Pressure: 'Systolischer Blutdruck',
    Diastolic_Blood_Pressure: 'Diastolischer Blutdruck',
    Health_Data_Type: 'Gesundheit Datentyp',
    select_diabetes_therapy_label: 'auswahl-diabetes-therapielabel',
    Please_select_any_health_data_to_add_: 'Bitte wählen Sie alle hinzuzufügenden Gesundheitsdaten aus.',
    Recorded_Date_and_Time: 'Datum und Uhrzeit der Aufzeichnung',
    Now: 'Jetzt',
    New_Data: 'Neue Daten',
    Recorded_Date: 'Aufnahmedatum',
    Data: 'Daten',
    Medical_History: 'Anamnese',
    Diabetes_Type: 'Diabetes-Typ',
    select___: 'auswählen...',
    No_diabetes: 'Kein Diabetes',
    Type_1_Diabetes: 'Typ-1-Diabetes',
    Type_2_Diabetes: 'Typ-2-Diabetes',
    Gestational_Diabetes: 'Schwangerschaftsdiabetes',
    Diabetes_Onset: 'Ausbruch von Diabetes',
    When_were_you_first_diagnosed_with_diabetes_: 'Wann wurde bei Ihnen erstmals Diabetes diagnostiziert?',
    Eye_Surgery_History: 'Geschichte der Augenoperation',
    Had_eye_surgery: 'Hatte eine Augenoperation',
    Never_had_eye_surgery: 'Hatte noch keine Augenoperation',
    Heart_Disease: 'Herzkrankheit',
    Has_heart_disease: 'Hat eine Herzkrankheit',
    No_heart_disease: 'Keine Herzkrankheit',
    High_Blood_Pressure: 'Hoher Blutdruck',
    Yes: 'Ja',
    No: 'Nein',
    High_Cholesterol: 'Hoher Cholesterinspiegel',
    No_Disease: 'Keine Krankheit',
    Mild_Retinopathy: 'Leichte Retinopathie',
    Moderate_Retinopathy: 'Mäßige Retinopathie',
    Severe_Retinopathy: 'Schwere Retinopathie',
    Proliferative_Retinopathy: 'Proliferative Retinopathie',
    Macular_Edema_Presence: 'Vorhandensein eines Makulaödems',
    select_macular_edema_presence_label: 'selektiere-makuläres-Ödem-vorhanden-Etikett',
    Absent: 'Abwesend',
    Present: 'Gegenwart',
    Enter_account_number_to_retrieve_patient_: 'Geben Sie die Kontonummer ein, um den Patienten abzurufen.',
    Dashboard: 'Dashboard',
    Disclaimer__statistics_are_non_realtime_and_are_updated_midnightly_:
      'Haftungsausschluss: Die Statistiken sind nicht echtzeitfähig und werden um Mitternacht aktualisiert.',
    Welcome_back_: 'Willkommen zurück',
    Have_a_nice_day_at_work: 'Einen schönen Tag bei der Arbeit',
    Total_Patients: 'Patienten insgesamt',
    Urgent_Cases: 'Dringende Fälle',
    Total_Eye_Exams: 'Augenuntersuchungen insgesamt',
    Completed: 'Abgeschlossen',
    DISEASE_DETECTION_SUMMARY: 'ZUSAMMENFASSUNG DER KRANKHEITSERKENNUNG',
    __Diseases_are_those_marked_as_moderate_or_greater__AMD_and_DR___or_suspect__DME__Glaucoma_and_Media_Opacity_:
      '* Krankheiten, die als mittelschwer oder schwerer (AMD und DR) oder als verdächtig (DME, Glaukom und Media opacity) gekennzeichnet sind.',
    Total_number_of_images_graded_by_TeleEye_MD: 'Gesamtzahl der von TeleEye MD bewerteten Bilder',
    URGENT_CASES: 'DRINGENDE FÄLLE',
    Select_Organisation: 'Organisation auswählen',
    Approve_User: 'Benutzer genehmigen',
    Create_User: 'Benutzer erstellen',
    Registration_Success_: 'Anmeldung erfolgreich!',
    Create_another_user: 'Einen weiteren Benutzer anlegen',
    Male: 'Männlich',
    Female: 'Weiblich',
    Other: 'Andere',
    Start_typing_for_suggestions: 'Tippen Sie für Vorschläge',
    Type_of_Camera_used_: 'Art der verwendeten Kamera:',
    Organisation_Name: 'Name der Organisation',
    Which_hospital__clinic_or_establishment_do_you_belong_to_:
      'Zu welchem Krankenhaus, welcher Klinik oder Einrichtung gehören Sie?',
    Role: 'Rolle',
    Where_do_you_intend_to_practice_: 'Wo beabsichtigen Sie zu praktizieren?',
    Where_do_you_intend_to_get_screened_: 'Wo wollen Sie sich untersuchen lassen?',
    Password: 'Passwort',
    I_agree_to_the: 'Ich akzeptiere die',
    Via_Invite_Link: 'Über den Einladungslink',
    Copy_Link: 'Link kopieren',
    OR: 'ODER',
    Email_Address: 'Email-adresse',
    Type_a_valid_email_and_hit_enter_or_copy_and_paste_multiple_emails:
      'Geben Sie eine gültige E-Mail ein und drücken Sie die Eingabetaste oder kopieren Sie mehrere E-Mails und fügen Sie sie ein.',
    Invite_Users: 'Benutzer einladen',
    Invitation_Success_: 'Die Einladung war erfolgreich!',
    Almost_there__Please_ask_the_user_to_check_their_email_in_their_inbox_or_spam_folder_and_click_on_the_link_to_complete_their_registration_process_:
      'Fast fertig! Bitten Sie den Benutzer, seine E-Mail in seinem Posteingang oder Spam-Ordner zu überprüfen und auf den Link zu klicken, um die Registrierung abzuschließen.',
    Add_New_Organisation: 'Neue Organisation hinzufügen',
    Email_or_Mobile_No_: 'E-Mail oder Handy-Nr.',
    Mobile_Number: 'Mobilnummer',
    Invalid_Input: 'Ungültige Eingabe',
    Invalid_medicare_number: 'Ungültige Medicare-Nummer',
    Invalid_reference_number: 'Ungültige Referenznummer',
    Select_Country: 'Land auswählen',
    Country: 'Land',
    Passwords_do_not_match: 'Passwörter stimmen nicht überein',
    Password_must_be_at_least_8_characters: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    Password_must_contain_at_least_one_letter: 'Das Passwort muss mindestens einen Buchstaben enthalten',
    Password_must_contain_at_least_one_special_character: 'Das Passwort muss mindestens ein Sonderzeichen enthalten',
    password_confirm: 'password_confirm',
    Please_fill_up_all_required_fields_and_check_for_any_errors:
      'Bitte füllen Sie alle erforderlichen Felder aus und überprüfen Sie diese auf eventuelle Fehler.',
    Please_agree_to_all_terms_and_conditions: 'Bitte stimmen Sie allen Allgemeinen Geschäftsbedingungen zu',
    edit: 'bearbeiten',
    I_declare_that_the_above_information_is_true_and_correct_:
      'Ich erkläre, dass die vorstehenden Angaben wahrheitsgemäß und richtig sind.',
    Enter_your_details: 'Ihre Details eingeben',
    Booking_Successful: 'Buchung erfolgreich',
    BACK_TO_MEETINGS: 'ZURÜCK ZU DEN TERMINEN',
    There_is_no_meeting_available_today__please_try_another_day_:
      'Es gibt keine freien Termine. Bitte versuchen Sie mit einem anderen Datum.',
    First_name: 'Vorname',
    Last_name: 'Nachname',
    invalid_email_address: 'Ungültige E-Mail Adresse',
    Phone: 'Telefon',
    Teleconsultation: 'Telekonsultation',
    Contact_Details: 'Kontaktangaben',
    Teleconsultation_Details: 'Teleconsultation Details',
    Today__HH_mm: "Heute' HH:mm",
    Tomorrow__HH_mm: "Morgen' HH:mm",
    no_available_time: 'keine verfügbaren Termine',
    loading___: 'laden...',
    Details: 'Einzelheiten',
    SimplyBook_me: 'SimplyBook.me',
    Add_a_Note__optional_: 'Eine Notiz hinzufügen (optional)',
    Please_describe_your_symptoms_or_leave_your_questions:
      'Bitte beschreiben Sie Ihre Symptome oder hinterlassen Sie Ihre Fragen',
    Confirm_Booking: 'Bestätigen Sie die Buchung',
    Next: 'Weiter',
    Available_Meeting_Start_Times: 'Verfügbare Startzeiten für Sitzungen',
    JOIN: 'BEITRETEN',
    VIEW_REPORT: 'BERICHT ANSEHEN',
    VIEW_NOTE: 'ANMERKUNG ANSEHEN',
    Select_a_status_to_display_: 'Wählen Sie einen Status aus, der angezeigt werden soll.',
    The_patient_did_not_leave_a_note: 'Der Patient hat keine Nachricht hinterlassen',
    The_eye_specialist_has_not_left_a_note_yet: 'Der Augenarzt hat noch keine Nachricht hinterlassen',
    Enter_any_notes_from_the_meeting_here______: 'Geben Sie hier eventuelle Notizen aus der Besprechung ein. . .',
    Eye_Specialist: 'Augenarzt',
    Patient_Name: 'Name des Patienten',
    Notes: 'Anmerkungen',
    Eye_Exam_Reports: 'Berichte über Augenuntersuchungen',
    View_Report_s_: 'Bericht(e) ansehen',
    The_patient_doesn_t_have_any_history_meetings: 'Der Patient hat keine Anamnesegespräche',
    You_don_t_have_any_history_meetings_: 'Sie haben keine Treffen zur Geschichte.',
    Enable_TeleConsult: 'TeleConsult einschalten',
    TeleConsults_History: 'TeleConsults Geschichte',
    TeleConsults: 'TeleConsults',
    Feature_unavailable: 'Funktion nicht verfügbar',
    The_feature_is_currently_unavailable: 'Die Funktion ist derzeit nicht verfügbar',
    You_need_to_finish_an_eye_exam_to_book_a_TeleConsult:
      'Sie müssen eine Augenuntersuchung abgeschlossen haben, um einen TeleConsult zu buchen',
    BOOK: 'BUCHEN',
    Meeting_room_will_open_5_min_before_the_meeting_begins: 'Der Sitzungssaal wird 5 Minuten vor dem Termin geöffnet.',
    You_don_t_have_any_upcoming_meetings_: 'Sie haben keine nächsten Termine.',
    contact_us_: 'kontaktieren Sie uns.',
    New_Patient_Creation: 'Neue Patienten anlegen',
    This_patient_does_not_have_any_existing_exams_: 'Bei diesem Patienten liegen keine Untersuchungen vor.',
    Add_New_Exam: 'Neue Untersuchung hinzufügen',
    Unable_to_add_new_exam: 'Eine neue Untersuchung kann nicht hinzugefügt werden',
    Each_patient_can_only_have_a_maximum_of_50_exams_: 'Jeder Patient kann nur maximal 50 Untersuchungen erhalten.',
    Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_:
      'Bitte füllen Sie die letzte Prüfung aus und reichen Sie diese ein, um eine neue Untersuchung hinzuzufügen.',
    Risk_Analysis: 'Risikoanalyse',
    Risk_Analysis_Unavailable: 'Risikoanalyse nicht verfügbar',
    Please_add_at_least_one_Risk_Prediction_to_view_Risk_Analaysis:
      'Bitte fügen Sie mindestens eine Risikovorhersage hinzu, um die Risikoanalyse anzuzeigen.',
    This_feature_will_be_coming_soon__stay_tuned_: 'Diese Funktion wird bald verfügbar sein, bleiben Sie dran!',
    Add_Risk_Prediction: 'Risikovorhersage hinzufügen',
    Risk_Prediction_Services_Coming_Soon___: 'Risikovorhersagedienste demnächst...',
    Enter_name_s__and_or_date_of_birth_to_retrieve_patient_:
      'Geben Sie den/die Namen und/oder das Geburtsdatum ein, um den Patienten zu finden.',
    Please_enter_some_patient_information_to_search: 'Bitte geben Sie einige Patienteninformationen zur Suche ein',
    Use_Account_Number: 'Kontonummer verwenden',
    No_Account_Number: 'Keine Kontonummer',
    Your_organisation_has_yet_to_register_any_patients_: 'Ihre Organisation hat noch keine Patienten registriert.',
    UPCOMING_APPOINTMENTS: 'ANSTEHENDE TERMINE',
    Credits: 'Credits',
    Add_Credits: 'Credits hinzufügen',
    Add_free_credits_to_specific_organisation_below_:
      'Fügen Sie einer bestimmten Organisation unten kostenlose Credits hinzu.',
    Completed_By: 'Abgeschlossen von',
    You_have_yet_to_make_any_purchases_: 'Sie haben noch keine Käufe getätigt.',
    Payment_Transactions: 'Zahlungsverkehr',
    First_Time_offer_: 'Erstmaliges Angebot!',
    You_pay: 'Sie zahlen',
    Your_payment_has_been_cancelled__If_there_was_a_problem_with_the_transaction__please_contact_our_customer_support_or_try_again_:
      'Ihre Zahlung wurde storniert. Wenn es ein Problem mit der Transaktion gab, kontaktieren Sie bitte unseren Kundendienst oder versuchen Sie es erneut.',
    Total_Credits: 'Credits insgesamt',
    CREDITS: 'CREDITS',
    Credit_Transactions: 'Credit-Transaktionen',
    Subscription_success_: 'Erfolgreiches Abonnement!',
    Unit_Price: 'Preis pro Einheit',
    Max__Exams: 'Max. Prüfungen',
    Paid_By: 'Bezahlt von',
    You_have_yet_to_make_any_payment_: 'Sie haben noch keine Zahlung geleistet.',
    What_will_be_different_: 'Was wird anders sein?',
    Join: 'Beitreten',
    Please_select_a_service_to_continue_: 'Bitte wählen Sie einen Dienst aus, um fortzufahren.',
    You_may_select_more_services_to_add_to_calculate_different_risks_:
      'Sie können weitere Dienste auswählen, um verschiedene Risiken zu berechnen.',
    Medical_History_and_Health_Data_required_to_run_each_service_:
      'Anamnese und Gesundheitsdaten, die für die Ausführung der einzelnen Dienste erforderlich sind:',
    Risk_Predictions: 'Risiko-Prognosen',
    Resume_Subscription: 'Lebenslauf Abonnement',
    Cancel_Subscription: 'Abonnement kündigen',
    Current_Plan: 'Aktueller Plan',
    Cancelled_subscription_ends_on: 'Das gekündigte Abonnement endet am',
    __Trial_ends_on: '#NAME?',
    Next_bill_of: 'Nächste Rechnung von',
    to_be_issued_on: 'auszustellen am',
    Plan_: 'Plan:',
    Add_On: 'Add-on',
    Start_your_free_trial_today: 'Starten Sie noch heute Ihren kostenlosen Test',
    By_choosing_a_subscription_package_that_suits_you_: 'Indem Sie ein Abonnementpaket wählen, das zu Ihnen passt.',
    Unable_to_view_Billing_History: 'Abrechnungshistorie kann nicht angezeigt werden',
    Billing_history_is_currently_unavailable_but_will_be_coming_soon_:
      'Die Abrechnungshistorie ist derzeit nicht verfügbar, wird aber in Kürze verfügbar sein.',
    Billing_History: 'Abrechnungshistorie',
    We_are_sad_to_see_you_go__If_you_choose_to_cancel_now_:
      'Es ist schade, dass Sie Ihr Abonnement jetzt beenden möchten. Wenn Sie sich entscheiden, jetzt zu kündigen,',
    Cancellation_will_be_effective_at_the_end_of_your_current_billing_cycle_on:
      'Die Kündigung wird zum Ende des laufenden Abrechnungszeitraums am',
    You_can_still_use_the_service_until_then_: 'Bis dahin können Sie den Dienst weiterhin nutzen.',
    We_are_here_to_welcome_you_back_at_anytime__If_you_choose_to_resume_now_:
      'Wir sind jederzeit bereit, Sie wieder aufzunehmen. Wenn Sie jetzt weitermachen wollen,',
    Your_next_payment_will_be_processed_on: 'Ihre nächste Zahlung wird bearbeitet am',
    Your_subscription_will_continue_as_before_: 'Ihr Abonnement wird wie bisher fortgesetzt.',
    Select_No__of_Exams_: 'Wählen Sie Anzahl der Prüfungen:',
    Add_On_Purchase_Not_Available: 'Add-On Kauf nicht verfügbar',
    Finish_using_current_available_Add_On_exams_before_purchasing_more:
      'Beenden Sie die Nutzung der aktuell verfügbaren Add-On-Untersuchungen, bevor Sie weitere erwerben.',
    One_time_Purchase: 'Einmaliger Kauf',
    Subscriptions: 'Abonnements',
    Add_On_Unavailable: 'Add-On nicht verfügbar',
    Add_On_is_only_available_once_you_purchase_a_subscription_plan:
      'Das Add-On ist nur verfügbar, wenn Sie einen Abonnementplan erwerben.',
    Purchase: 'Kaufen',
    Switch_Plans_Coming_Soon: 'Switch-Pläne demnächst verfügbar',
    _For_enterprise_pricings__please: '*Für Unternehmenspreise, bitte',
    Error_during_service_worker_registration_: 'Fehler bei der Registrierung des Service Workers:',
    content_type: 'Inhaltstyp',
    Upgrade_your_subscription_if_you_wish_to_select_this_service:
      'Upgraden Sie Ihr Abonnement, wenn Sie diesen Service wählen möchten',
    Basic_Plan_required_: 'Basic Plan erforderlich!',
    Standard_Plan_required_: 'Standardplan erforderlich!',
    Premium_Plan_required_: 'Premium Plan erforderlich!',
    AMD: 'AMD',
    DME: 'DME',
    DR: 'DR',
    GLAUCOMA: 'GLAUKOM',
    MEDIA_OPACITY: 'MEDIA OPACITY',
    Disclaimer__RetinoScan_Glaucoma_and_AMD_is_for: 'Haftungsausschluss: RetinoScan Glaucoma und AMD ist nur für',
    research_use: 'Forschungszwecke',
    only_and_should_not_be_used_for_clinical_decision_making_:
      'gedacht und sollte nicht für klinische Entscheidungen verwendet werden.',
    Result: 'Ergebnis',
    Upload_and_select_up_to_4_images_to_be_sent_for_grading__with_several:
      'Laden Sie bis zu 4 Bilder hoch und wählen Sie sie aus, um sie zur Bewertung zu senden, mit mehreren',
    Create: 'Erstellen',
    User: 'Benutzer',
    Health_Condition____Right_Eye____Left_Eye: "Gesundheitszustand', 'Rechtes Auge', 'Linkes Auge",
    Health_Condition: 'Gesundheitszustand',
    health_practitioners: 'Arztpraxen',
    within_TeleEye_MD: 'des Patienten innerhalb von TeleEye MD eingesehen werden',
    You_can_navigate_away_from_this_page_while_waiting_for_results__start_another_exam__and_return_at_any_time_:
      'Sie können diese Seite verlassen, während Sie auf Ergebnisse warten, eine weitere Untersuchung starten und jederzeit zurückkehren.',
    Create_Patient: 'Patient anlegen',
    Total_Patients_Screened: 'Gesamtzahl gescreenter Patienten',
    Exam_Date: 'Untersuchungsdatum',
    PENDING_ACTION: 'AUSSTEHENDE AKTION',
    Filter: 'Filter',
    AWAITING_RESULT: 'ERGEBNIS AUSSTEHEND',
    Chinese: 'Chinesisch',
    Indian: 'Indien',
    Malay: 'Malaysia',
    Caucasian: 'Kaukasich',
    Patient_Details: 'Patientendetails',
    Latest_Health_Data: 'Neueste Gesundheitsdaten',
    Severity: 'Schweregrad',
    'Diabetic\n_Retinopathy': 'Diabetische Retinopathie',
    Add_Comment: 'Kommentar hinzufügen',
    Eye_Images: 'Augenbilder',
    DR_Presence: 'Vorhandensein von DR',
    By_logging_in__you_agree_to_our: 'Mit dem Login stimmen Sie unseren',
    Reviewer: 'Überprüfer',
    Reviews_within: 'Überprüfungen innerhalb',
    Oldest_First: 'Älteste zuerst',
    Newest_First: 'Neueste zuerst',
    Default__All_: 'Standard (Alle)',
    Phone_Number: 'Telefonnummer',
    Organisation_Admin: 'Organisationsadministrator',
    If_you_have_the_user_s_email_address_that_is_within_your_organisation__you_can_invite_them_by_entering_their_email_addres_below__Instructions_will_be_sent_to_their_email_and_once_completed__they_will_be_added_to_the_organisation_:
      'Wenn Sie die E-Mail-Adresse des Benutzers haben, die zu Ihrer Organisation gehört, können Sie ihn einladen, indem Sie seine E-Mail-Adresse unten eingeben. Anweisungen werden an seine E-Mail gesendet und nach Abschluss wird er zur Organisation hinzugefügt.',
    Invite_another_user: 'Einen weiteren Benutzer einladen',
    Practitioner: 'Behandelnder Arzt',
    Patient: 'Patient',
    Optometrist: 'Optometrist',
    Sign_Up_with_your_Email: 'Registrieren Sie sich mit Ihrer E-Mail',
    Branch: 'Filiale',
    Location: 'Standort',
    Branch_Location: 'Filiale/Standort',
    Your_practice_branch_name_or_location: 'Name oder Standort Ihrer Praxisfiliale',
    Age_Related_Macular_Degeneration: 'Altersbedingte Makuladegeneration',
    for_age: 'für Alter',
    Glaucoma: 'Glaukom',
    No_Image: 'Kein Bild',
    DR_Severity: 'Schweregrad der DR',
    Exam_Details: 'Untersuchungsdetails',
    Cup_to_Disc_Ratio: 'Papillen-Exkavations-Verhältnis',
    Disc_Asymmetry: 'Scheibenasymmetrie',
    Risk_of_Glaucoma_if: 'Risiko für Glaukom, falls',
    Send_Report: 'Bericht senden',
    REPORT_READY: 'BERICHT FERTIG',
    Additional_Information: 'Zusätzliche Informationen',
    Comment_to_Health_Practitioners_Only: 'Kommentar nur für Gesundheitsdienstleister',
    Comment_to_Patient: 'Kommentar zum Patienten',
    Accident_and_Emergency: 'Unfall und Notfall',
    '2_weeks': '2 Wochen',
    '1_month': '1 Monat',
    '3_months': '3 Monate',
    '6_months': '6 Monate',
    Normal_screening_results___continue_annual_screening:
      'Normale Screening-Ergebnisse – jährliches Screening fortsetzen',
    No_referable_conditions_in_screening_results___Continue_annual_screening:
      'Keine überweisungspflichtigen Bedingungen in Screening-Ergebnissen – Jährliches Screening fortsetzen',
    Extra_Parameters: 'Zusätzliche Parameter',
    Parameters: 'Parameter',
    Diabetic_Macular_Edema: 'Diabetisches Makulaödem',
    Media_Opacity: 'Medientrübung',
    Review_Summary: 'Überprüfungszusammenfassung',
    Reviewed_at: 'Überprüft am',
    please_login_instead: 'bitte stattdessen einloggen',
    or: 'oder',
    To_enhance_your_account_s_security_: 'Um die Sicherheit Ihres Kontos zu erhöhen:',
    Street_Address: 'Straßenadresse',
    Street: 'Straße',
    Address_Line_2__Floor_and_or_unit_number: 'Adresszeile 2: Etage und/oder Einheitsnummer',
    Suburb_City: 'Vorort/Stadt',
    City: 'Stadt',
    Town_City: 'Stadt',
    State: 'Land',
    Type: 'Typ',
    An_account_already_exists_for_this: 'Hierfür existiert bereits ein Konto',
    Neighbourhood: 'Nachbarschaft',
    organisation_admin: 'organization admin',
    Detected_Diseases: 'Erkannte Krankheiten',
    UNLIMITED: 'UNBEGRENZT',
    unlimited: 'unbegrenzt',
    Image_Requirements: 'Bildanforderungen',
    Start_New_Exam: 'Neue Untersuchung beginnen',
    Review: 'Befundung',
    Leave: 'Verlassen',
    Stay: 'Bleiben',
    Health_Data: 'Gesundheit Daten',
    Eye_Exams: 'Augenuntersuchungen',
    Add_Health_Data: 'Gesundheitsdaten hinzufügen',
    Right_Uncorrected: 'Rechts unkorrigiert',
    Left_Uncorrected: 'Links unkorrigiert',
    Right_Best_Corrected: 'Rechts bestkorrigiert',
    Left_Best_Corrected: 'Links bestkorrigiert',
    Comments__optional_: 'Kommentar (optional)',
    Comments: 'Kommentar',
    e_g__Images_too_blurry__eyes_bleeding___: 'z.B. Bilder zu unscharf, Augen bluten...',
    Submit_Exam_: 'Prüfung einreichen?',
    Submit_Review_: 'Bewertung Abschicken?',
    Provider_Number: 'Provider Number',
    Aboriginal: 'Aboriginal',
    Torres_Strait_Islander: 'Torres Strait Islander',
    Aboriginal_and_Torres_Strait_Islander: 'Aboriginal and Torres Strait Islander',
  },
};
