import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import PropTypes from 'prop-types';

import Card from '../../../components/layout/atoms/Card';
import SmallButton from '../../../components/layout/atoms/SmallButton';
import InfoToolTip from '../../../components/layout/atoms/InfoToolTip';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import Loading from '../../../components/layout/organisms/Loading/Loading';
import ConfirmPurchase from '../../exams/components/ConfirmExamModal';
import AddRiskService from '../../risk_services/AddRiskService';

import { setSnackbar, handleModalOpen, handleModalClose } from '../../../actions/controls';
// import { getServicePrices } from '../../../actions/payments';
import { submitServices, checkServiceInput } from '../../../actions/riskServices';
import { getPatientDetails, getPatientRiskServices } from '../../../actions/patients';

import { ActivityIcon, ArrowRightIcon, PlusIcon, UserCheckIcon } from '../../../resources/icons';
import maleAvatar from '../../../resources/icons/files/male-avatar.svg';
import femaleAvatar from '../../../resources/icons/files/female-avatar.svg';
import otherAvatar from '../../../resources/icons/files/other-avatar.svg';
import { DR_RISK, GENDER_FEMALE, GENDER_MALE, STDR_RISK } from '../../../constants/constants';

const StyledButtonGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-end',
  },
}));

// FUNCTIONAL COMPONENT
const PatientRiskServices = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  // ROUTER LINKING
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [checkedServices, setCheckedServices] = React.useState(new Set());

  // FUNCTIONS

  // Handle the risk analysis button by opening risk analysis page
  const handleRiskAnalysis = () => {
    history.push(`/patient/${props.patientDetails.account_number}/risk_analysis/`);
  };

  // Handle add new risk service button by checking input parameters and opening pop up.
  const handleNewRiskService = () => {
    props.checkServiceInput(props.patientDetails.id);
    props.handleModalOpen('AddRiskService');
  };

  // Get the service prices when risk services has been created
  React.useEffect(() => {
    // const { getServicePrices } = props;
    // getServicePrices();
  }, [props.patientDetails.risk_services]);

  // Re-render the add services pop up when either DR or STDR is disabled or enabled
  React.useEffect(() => {
    renderAddServices();
  }, [props.disableDR]);

  React.useEffect(() => {
    renderAddServices();
  }, [props.disableSTDR]);

  // Handle a change in the services selection pop up, by removing/adding them from/to the set
  const handleServicesChange = (event, service) => {
    event.preventDefault();

    const servicesSet = new Set(checkedServices);

    if (event.target.checked) {
      servicesSet.add(service);
    } else {
      servicesSet.delete(service);
    }
    setCheckedServices(servicesSet);
  };

  // Handle the submit services button by opening the confirm purchase pop up
  const handleSubmit = (event) => {
    props.handleModalOpen('ConfirmPurchase');
  };

  // Handle the purchase button by closing all popups, submitting services and clearing the service list.
  const handlePurchase = (event) => {
    props.handleModalClose('ConfirmPurchase');
    props.handleModalClose('AddRiskService');
    props.submitServices(props.patientDetails.account_number, history, props.patientDetails.id, checkedServices);
    setCheckedServices(new Set());
  };

  // Only enable the risk analysis button if the patient has run a risk prediction
  // service in the past
  const renderRiskAnalysisButton = () => {
    if (props.patientDetails.risk_services && props.patientDetails.risk_services.length) {
      return (
        <SmallButton onClick={handleRiskAnalysis} type="button" startIcon={<ActivityIcon />} noMargin>
          {staticWords.Risk_Analysis}
        </SmallButton>
      );
    } else {
      return (
        <InfoToolTip
          title={staticWords.Risk_Analysis_Unavailable}
          content={staticWords.Please_add_at_least_one_Risk_Prediction_to_view_Risk_Analaysis}
          placement="left"
        >
          <Box>
            <SmallButton
              onClick={handleRiskAnalysis}
              type="button"
              color="blue"
              startIcon={<ActivityIcon />}
              disabled
              noMargin
            >
              {staticWords.Risk_Analysis}
            </SmallButton>
          </Box>
        </InfoToolTip>
      );
    }
  };

  const renderAddRiskServiceButton = () => {
    return (
      <InfoToolTip
        title={staticWords.Risk_Analysis_Unavailable}
        content="This feature will be coming soon, stay tuned!"
        placement="left"
      >
        <div>
          <SmallButton onClick={handleNewRiskService} type="button" startIcon={<PlusIcon />} noMargin disabled>
            {staticWords.Add_Risk_Prediction}
          </SmallButton>
        </div>
      </InfoToolTip>
    );
  };

  // Render the add risk service pop up, input the set of disabled services along with their associated messages.
  const renderAddServices = () => {
    return (
      <AddRiskService
        handleChange={handleServicesChange}
        checkedServices={checkedServices}
        disabledServices={new Set()}
        servicePrices={props.servicePrices}
        submit={handleSubmit}
        serviceDisable={new Set([props.disableDR ? DR_RISK : false, props.disableSTDR ? STDR_RISK : false])}
        serviceDisableMessage={
          new Map([
            props.disableDR != null ? [DR_RISK, props.messageDR] : [null],
            props.disableSTDR != null ? [STDR_RISK, props.messageSTDR] : [null],
          ])
        }
      />
    );
  };

  // Display either DR Risk or STDR Risk based on what was the most recent service
  const renderServiceText = () => {
    return props.patientDetails.risk_services[0].service_name === 'DR Risk Prediction' ? (
      <Typography variant="h3" color="text.hint">
        &nbsp; has a {props.patientDetails.risk_services[0].risk}% chance of developing Diabetic Retinopathy
      </Typography>
    ) : (
      <Typography variant="h3" color="text.hint">
        &nbsp; has a {props.patientDetails.risk_services[0].risk}% chance of developing Sight Threatening Diabetic
        Retinopathy
      </Typography>
    );
  };

  // Render the confirm purchase pop up
  const renderAddPurchase = () => {
    return <ConfirmPurchase services={checkedServices} servicePrices={props.servicePrices} onSubmit={handlePurchase} />;
  };

  // DISPLAY
  return (
    <>
      {/* CARD WITH WHITE BACKGROUND */}
      <Card size="fill" sx={{ padding: (theme) => theme.spacing(3) }}>
        {/* SECTION TITLE */}
        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
          <Grid item container spacing={3} alignItems="center" wrap="nowrap" xs={12} lg={7.5}>
            <Grid item>
              {/* Choose the avatar based on the gender */}
              <Avatar
                alt="avatar"
                src={
                  props.patientDetails.demographics.gender === GENDER_MALE
                    ? maleAvatar
                    : props.patientDetails.demographics.gender === GENDER_FEMALE
                      ? femaleAvatar
                      : otherAvatar
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h3">{props.patientDetails.user.user_contact.first_name}</Typography>
            </Grid>
            {/* Display information about most recent service, if a service has not been run inform the user */}
            {props.patientDetails.risk_services && props.patientDetails.risk_services.length ? (
              <Grid item>{renderServiceText()}</Grid>
            ) : (
              <Grid item>
                {/* Display that the patient has not run a risk prediction service */}
                <Typography variant="subtitle1">
                  {staticWords.Risk_Prediction_Services_Coming_Soon___}
                  {/* has yet to run a risk prediction service */}
                </Typography>
              </Grid>
            )}
          </Grid>
          <StyledButtonGrid container item wrap="nowrap" spacing={1} alignItems="center" xs={12} lg={4.5}>
            <Grid item>{renderRiskAnalysisButton()}</Grid>
            <Grid item>{renderAddRiskServiceButton()}</Grid>
          </StyledButtonGrid>
        </Grid>
      </Card>
      {renderAddServices()}
      {renderAddPurchase()}
    </>
  );
};

PatientRiskServices.propTypes = {
  patients: PropTypes.array,
};
// MAP REDUX STATE TO COMPONENT PROPS
// To retrieve patients from props, use -> props.patients
const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  servicePrices: state.payments.servicePrices,
  disableDR: state.controls.disableDR,
  messageDR: state.controls.messageDR,
  disableSTDR: state.controls.disableSTDR,
  messageSTDR: state.controls.messageSTDR,
});

export default connect(mapStateToProps, {
  getPatientRiskServices,
  setSnackbar,
  handleModalOpen,
  handleModalClose,
  // getServicePrices,
  submitServices,
  checkServiceInput,
})(PatientRiskServices);
