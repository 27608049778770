import { trackPromise } from 'react-promise-tracker';
import { t } from 'i18next';

import axiosInstance from '../axios';
import { getBackendURL, getBackendHost } from '../utils/urls';
import { transferValueGetKey } from '../utils/helpers';
import logger from '../utils/debugHelpers';
import {
  ASSIGN_LATERALITY,
  CLEAR_EXAM_DETAILS,
  CREATE_EXAM,
  FORM_FIELDS_ADDED,
  GET_EXAM_DETAILS,
  GET_EXAM_DETAILS_NO_REFRESH_IMAGES,
  GET_EXAMS_BY_OPERATOR,
  GET_EXISTING_EXAMS_BY_OPERATOR,
  GET_IMAGE_DETAILS,
  GET_NUMBER_OF_EXAM_PAGES,
  GET_REPORT_SENT_STATS,
  GET_URGENT_EXAMS,
  GRADING_SUBMITTED,
  IMAGE_DELETED,
  IMAGE_GRADED,
  IMAGE_UPLOADED,
  IMAGES_SUBMITTED,
  LIST_AUDITORS,
  REFRESH_EXAM_DETAILS,
  SERVICE_RESUBMITTED,
  SET_EXAM_PAGINATION_INFO,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_INFO,
  SET_SNACKBAR_SUCCESS,
  UPDATE_EXAM_STATUS,
  UPDATE_ORDER_DETAILS,
  UPDATE_PATIENT_EXAMS,
  WEBSOCKET_CONNECTED_EXAMS,
  WEBSOCKET_DISCONNECTED_EXAMS,
} from './types';
import {
  AWAITING_RESULT,
  COLOUR_FUNDUS_PHOTOGRAPH,
  DEFAULT_PAGE_SIZE,
  EXAM_ORDERED,
  EXAM_SUBMITTED,
  LR,
  PENDING_ACTION,
  PENDING_REVIEW,
  QA,
  REPORT_READY,
} from '../constants/constants';

// CREATE EXAM
export const createExam = (patient, history) => (dispatch) => {
  axiosInstance
    .post('/eye_screening/create/', { patient_id: patient.id })
    .then((result) => {
      dispatch({
        type: CREATE_EXAM,
        payload: result.data.id,
      });
      dispatch({
        type: UPDATE_PATIENT_EXAMS,
        payload: result.data,
      });
      history?.push(`/patient/${patient.account_number}/create_exam/${result.data.id}/`);
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There was a problem creating an exam. Please try again later.')),
      });
    });
};

// GET EXAMS
export const getExams =
  (
    page = 1,
    overall_statuses = [PENDING_ACTION, AWAITING_RESULT, PENDING_REVIEW, REPORT_READY],
    order = null,
    initial = false,
    pageSize = DEFAULT_PAGE_SIZE
  ) =>
  (dispatch) => {
    return trackPromise(
      axiosInstance
        .get(
          `/eye_screening/list/operator/?page_size=${pageSize}&page=${page}&overall_statuses=${overall_statuses}${
            order !== null ? `&date_order=${order}` : '&date_order=-process__created_at'
          }`
        )
        .then((result) => {
          dispatch({
            type: GET_EXAMS_BY_OPERATOR,
            payload: result.data,
            request_type: initial === true ? 'filter_sort' : null,
            statuses: overall_statuses,
            order: order,
          });
          const { count, pages_count: pagesCount, page } = result.data;
          dispatch({
            type: SET_EXAM_PAGINATION_INFO,
            payload: { count, pagesCount, page },
          });
          return { count, pagesCount, page };
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              t(transferValueGetKey('There was a problem retrieving exams. Please refresh the page to try again.')),
          });
          return Promise.reject();
        }),
      'all-exams-area'
    );
  };

export const getExistingExamsPage =
  (page = 1) =>
  (dispatch) => {
    dispatch({
      type: GET_EXISTING_EXAMS_BY_OPERATOR,
      payload: page,
    });
  };

export const getNumberOfExamPages =
  (overall_statuses = [PENDING_ACTION, AWAITING_RESULT, PENDING_REVIEW, REPORT_READY]) =>
  (dispatch) => {
    trackPromise(
      axiosInstance
        .get(`/eye_screening/exams/operator/number-of-pages/&overall_statuses=${overall_statuses}`)
        .then((result) => {
          dispatch({
            type: GET_NUMBER_OF_EXAM_PAGES,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              t(transferValueGetKey('There was a problem retrieving page number for exams, please try again.')),
          });
        })
    );
  };
// UPLOAD IMAGE
export const uploadImage = (exam_id, image_type, image_file) => (dispatch) => {
  const image = new FormData();
  image.append('eye_screening_id', exam_id);
  image.append('image_type', image_type);
  image.append('image', image_file);
  image.append('image_modality', COLOUR_FUNDUS_PHOTOGRAPH);
  dispatch({
    type: SET_SNACKBAR_INFO,
    payload: t(transferValueGetKey('Uploading image')) + ' ' + image_file.name,
  });
  axiosInstance
    .post('/eye_screening/image/upload/', image)
    .then((result) => {
      dispatch({
        type: IMAGE_UPLOADED,
        payload: result.data,
      });
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: t(transferValueGetKey('Successfully uploaded')) + ' ' + image_file.name,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There is a problem with uploading. Please refresh page and try again.')),
      });
    });
};

// DELETE IMAGE
export const deleteImage = (exam_id, image) => (dispatch) => {
  axiosInstance
    .delete(`/eye_screening/image/delete/${exam_id}/`, {
      data: {
        eye_screening_image_ids: [image.id],
      },
    })
    .then((result) => {
      dispatch({
        type: IMAGE_DELETED,
        payload: image,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There was a problem deleting image. Please try again later.')),
      });
    });
};
let connectedExamWebSockets = {};
// CONNECT TO WEB SOCKET FOR EYE SCREENING EXAMS
export const connectWebSocketExams = (id) => (dispatch) => {
  let websocketProtocol = 'ws';
  if (window.location.protocol === 'https:') websocketProtocol = 'wss';
  // const ws = new WebSocket(`${websocketProtocol}://${window.location.host}/ws/eye_screening/service/${id}/`);
  if (!connectedExamWebSockets[id]) {
    const ws = new WebSocket(websocketProtocol + `://${getBackendHost()}/ws/eye_screening/service/${id}/`);

    ws.onopen = () => {
      connectedExamWebSockets[id] = ws;
      // on connecting, do nothing but log it to the console
      dispatch({
        type: WEBSOCKET_CONNECTED_EXAMS,
        payload: Object.keys(connectedExamWebSockets),
      });
    };

    ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      const result = JSON.parse(evt.data);
      // if (result.error == 'True') {
      //   dispatch({
      //     type: SET_SNACKBAR_ERROR,
      //     payload: SERVICES_ERR_STR[parseInt(result.error_code)],
      //   });
      // }

      const { services_completion_info, eye_screening_id, eye_screening_image_id } = result;
      if (eye_screening_image_id) {
        dispatch({
          type: IMAGE_GRADED,
          payload: result,
        });
      }
      services_completion_info &&
        eye_screening_id &&
        ![QA, LR].includes[result.service_type] &&
        dispatch({
          type: REFRESH_EXAM_DETAILS,
          payload: services_completion_info,
        });
    };

    ws.onclose = () => {
      delete connectedExamWebSockets[id];
      dispatch({
        type: WEBSOCKET_DISCONNECTED_EXAMS,
        payload: 'disconnected websocket for exam ' + id,
      });
    };
  }
};

// HELPER FUNCTION FOR SHOWEXAM()
const assignImagesToExam = async (result, dispatch) => {
  if (Array.isArray(result.data.eye_screening_image) && result.data.eye_screening_image.length) {
    const images = result.data.eye_screening_image.map(async (image) => {
      await trackPromise(
        axiosInstance.get(`/eye_screening/image/detail/${image.id}/`).then((result) => {
          dispatch({
            type: GET_IMAGE_DETAILS,
            payload: result.data,
          });
        })
      ).catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(transferValueGetKey("There was a problem retrieving an image's information. Please try again later.")),
        });
      });
      return true;
    });
    await Promise.all(images);
  }
  return result;
};

export const updateOrderDetails = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/eye_screening/ordered/detail/${id}/`)
      .then((result) => {
        dispatch({
          type: UPDATE_ORDER_DETAILS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: error?.response?.data?.errors[0].message ?? 'There was a problem retrieving the order details.',
        });
      });
  });
};

// DISPLAY EXAM DETAILS
export const showExam =
  (id = null, clearOldExamDetails = true) =>
  (dispatch) => {
    clearOldExamDetails &&
      dispatch({
        type: CLEAR_EXAM_DETAILS,
      });
    return trackPromise(
      axiosInstance
        .get(`/eye_screening/detail/${id}/`)
        .then((result) => {
          dispatch({
            type: GET_EXAM_DETAILS,
            payload: result.data,
          });
          // assignImagesToExam(result, dispatch)
          //   .then((result) => {
          //     return resolve(result.data);
          //   })
          //   .catch((error) => reject(error.response));
          return result.data;
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              t(transferValueGetKey('There was a problem when getting exam details. Please try to refresh the page.')),
          });
          throw error;
        })
    );
  };

// get exam details without refreshing eye images
export const getExamDetails = (exam_id) => async (dispatch) => {
  return axiosInstance
    .get(`/eye_screening/detail/${exam_id}/`)
    .then((result) => {
      dispatch({
        type: GET_EXAM_DETAILS_NO_REFRESH_IMAGES,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There was a problem when getting exam details. Please try to refresh the page.')),
      });
      throw error;
    });
};

// GET URGENT EXAMS - TO DISPLAY ON DASHBOARD
export const getUrgentExams = () => (dispatch) => {
  trackPromise(
    axiosInstance
      .get('/eye_screening/urgent/detail/')
      .then((result) => {
        dispatch({
          type: GET_URGENT_EXAMS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(
              transferValueGetKey('There was a problem retrieving urgent exams. Please refresh the page to try again.')
            ),
        });
      })
  );
};

// SUBMIT IMAGES FOR GRADING
export const submitImages = (images, exam_id, history) => (dispatch) => {
  if (!images.length) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload: t(transferValueGetKey('Please upload images to continue')),
    });
    return;
  }
  dispatch({
    type: SET_SNACKBAR_INFO,
    payload: 'Submitting images...',
  });
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`eye_screening/image/submit/${exam_id}/`, {
        eye_screening_image_ids: images.map((image) => image.id),
      })
      .then((result) => {
        dispatch({
          type: IMAGES_SUBMITTED,
          payload: { images, exam_id },
        });
        resolve(result);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t(transferValueGetKey('Image(s) submitted for grading')),
        });
      })
      .catch((error) => {
        logger.log('submitting images', error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(transferValueGetKey('There was a problem when submitting images. Please try again.')),
        });
        reject(error);
      });
  });
};

export const assignLateralityToImage = (image_id, lr_service_id, laterality) => async (dispatch) => {
  return await new Promise((resolve, reject) => {
    axiosInstance
      .post(`eye_screening/image/assign-laterality/${image_id}/${lr_service_id}`, { laterality: laterality })
      .then((result) => {
        dispatch({
          type: ASSIGN_LATERALITY,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(transferValueGetKey('There was a problem assigning the laterality for the image, please try again.')),
        });

        reject(error);
      });
  });
};

export const submitForServices =
  (patient, services, exam_id, history = null, uploadedFiles, audit_access_type, selected_auditor_id, ordered_exam) =>
  async (dispatch) => {
    // if (!services.length) {
    //   dispatch({
    //     type: SET_SNACKBAR_ERROR,
    //     payload: t(transferValueGetKey('No services selected.')),
    //   });
    //   return;
    // }

    try {
      await axiosInstance.post(`eye_screening/submit/services/${exam_id}/`).then((result) => {
        const totalFiles = uploadedFiles?.length || 0;
        dispatch({
          type: UPDATE_EXAM_STATUS,
          payload: { id: exam_id, status: totalFiles.length > 0 ? EXAM_SUBMITTED : EXAM_ORDERED },
        });

        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: t(transferValueGetKey('Successfully submitted!')),
        });

        dispatch({
          type: GRADING_SUBMITTED,
          payload: { services },
        });

        if (totalFiles > 0) {
          history.replace(`/patient/${patient.user.account_number}/exam_details/${exam_id}/`);
        }
      });
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error?.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There was a problem submitting for services. Please try again later.')),
      });
      throw error;
    }

    //only for ordered exam
    if (ordered_exam) {
      dispatch(updateOrderDetails(exam_id));
    }
  };

export const resubmitServices = (exam_id) => (dispatch) => {
  return axiosInstance
    .post(`eye_screening/resubmit/services/${exam_id}/`)
    .then((result) => {
      dispatch({
        type: SERVICE_RESUBMITTED,
        payload: result.data,
      });
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: 'Successfully resubmitted!',
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(transferValueGetKey('There was a problem when resubmitting. Please try again.')),
      });
      throw error;
    });
};

export const submitExamComments = (comments, exam_id) => (dispatch) => {
  if (comments === null || comments === undefined || !exam_id) return;

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`eye_screening/submit/comment/${exam_id}/`, comments)
      .then((result) => {
        dispatch({
          type: FORM_FIELDS_ADDED,
          payload: comments,
        });
        resolve(result);
        // dispatch({
        //   type: SET_SNACKBAR_SUCCESS,
        //   payload: 'Successfully submitted!',
        // });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(transferValueGetKey('There was a problem when submitting comments. Please try again.')),
        });
        reject(error);
      });
  });
};

export const getReport =
  (exam_id, force_create = false) =>
  (dispatch) => {
    dispatch({
      type: SET_SNACKBAR_INFO,
      payload: t(transferValueGetKey('Loading report...')),
    });
    window.open(`${getBackendURL()}/api/eye_screening/report/pdf/${exam_id}?force_create=${force_create}`, '_blank');
    dispatch({
      type: SET_SNACKBAR_SUCCESS,
      payload: t(transferValueGetKey('Report successfully loaded!')),
    });
  };

export const getAuditors =
  (patientId = null) =>
  (dispatch) => {
    axiosInstance
      .get(`users/operators/ophthalmologists/list/?patient_id=${patientId}`)
      .then((result) => {
        dispatch({
          type: LIST_AUDITORS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(transferValueGetKey('There was an error retrieving auditors. Please try again.')),
        });
      });
  };

export const applyOrders =
  (patient, eye_screening_id = null, history) =>
  (dispatch) => {
    axiosInstance
      .post(`eye_screening/ordered/apply/${eye_screening_id}/`)
      .then((result) => {
        if (history) {
          history.replace({
            pathname: `/patient/${patient.user.account_number}/exam_details/${eye_screening_id}/`,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: error,
        });
      });
  };

// SendReport
export const sendReportEmail = (exam_id, email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/eye_screening/${exam_id}/report/send_email/${email}`)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: `Successfully Sent Report to ${email}!`,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            `There was a problem sending report to ${email}. Please try again.`,
        });
      });
  });
};

export const sendReportSMS = (exam_id, phone_number) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/eye_screening/${exam_id}/report/send_sms/${phone_number}`)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: `Successfully Sent Report to ${phone_number}!`,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            `There was a problem sending report to ${phone_number}. Please try again.`,
        });
      });
  });
};

export const getReportSendStats = (exam_id, silent) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/eye_screening/${exam_id}/report/sent_stats`)
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: GET_REPORT_SENT_STATS,
          payload: result.data,
        });
      })
      .catch((error) => {
        if (!silent) {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              `There was a problem retrieving report send stats. Please try again.`,
          });
        }
      });
  });
};

// retrieve report
export const retrieveReportWithoutLogin = (exam_ref_code, patient_last_name, dob) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/eye_screening/report/pdf/${exam_ref_code}?force_create=true`, { last_name: patient_last_name, dob: dob })
      .then((result) => {
        resolve(result.data);
        dispatch({
          type: SET_SNACKBAR_SUCCESS,
          payload: `Successfully Downloaded Report`,
        });
        // wrap window.open in setTimeout to avoid Safari blocker
        setTimeout(() => {
          window.open(result.data, '_blank');
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ?? `There was a problem downloading report. Please try again.`,
        });
      });
  });
};
