import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import SmallButton from '../atoms/SmallButton';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { ArrowRightIcon, ChevronDownIcon, DownloadCloudIcon } from '../../../resources/icons';

const PREFIX = 'ReviewsAccordion';

const classes = {
  summary: `${PREFIX}-summary`,
  date: `${PREFIX}-date`,
  duration: `${PREFIX}-duration`,
  name: `${PREFIX}-name`,
  accordion: `${PREFIX}-accordion`,
  urgent: `${PREFIX}-urgent`,
  avatar: `${PREFIX}-avatar`,
  button: `${PREFIX}-detailsButton`,
  expanded: `${PREFIX}-expanded`,
};

const Root = styled('div', {
  shouldForwardProp(prop) {
    return !['expandable'].includes(prop);
  },
})(({ theme, expandable }) => ({
  margin: `${theme.spacing(2)} 0`,

  '& .MuiAccordion-root': {
    '& .MuiButtonBase-root.MuiAccordionSummary-root': {
      cursor: expandable ? 'pointer' : 'default',
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: expandable ? 'flex' : 'none',
      },
    },
  },
  [`& .${classes.summary}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.accordion}`]: {
    boxShadow: theme.shadows[8],

    '&$expanded': {
      boxShadow: theme.shadows[8],
      margin: 'auto',
    },
  },

  [`& .${classes.button}`]: {
    padding: 0,
    margin: 0,
  },
}));

const ReviewsAccordion = ({ expandable = true, ...props }) => {
  const { styles: lanStyle } = useSelector((state) => state.handlingTranslation);
  return (
    <Root expandable={expandable}>
      <Accordion
        classes={{
          root: classes.accordion,
          expanded: classes.expanded,
        }}
        expanded={expandable ? undefined : false}
      >
        <AccordionSummary
          expandIcon={props.urgent || props.durationFromSubmission ? null : <ChevronDownIcon />}
          classes={{ content: classes.summary }}
          aria-controls="exam-content"
          id="exam-header"
          disabled={props.auditorIsViewing}
        >
          <Grid container wrap="nowrap" m={1}>
            <Grid container item justifyContent="space-between" alignItems="center">
              <Grid container item alignItems="center" xs={lanStyle.component_ReviewsAccordion?.sectionOne}>
                {props.createdAt?.includes('Audited') ? (
                  <Grid container item alignItems="center">
                    <Grid item mr={1}>
                      <Typography component="p" noWrap={true}>
                        {props.createdAt.slice(0, 11)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {props.patientFullName && (
                        <Typography variant="h5" noWrap>
                          {props.patientFullName}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container item mt={1} mb={1} alignItems="center">
                    {props.completedAt && (
                      <Grid item xs={12} md={3} alignItems="center">
                        {/* Date */}
                        <Typography variant="subtitle1">{props.completedAt}</Typography>
                      </Grid>
                    )}
                    {props.createdAt && (
                      <Grid container item display="flex" alignItems="center" xs={12} md={props.completedAt ? 3 : 3.5}>
                        {/* Date */}
                        <Grid item>
                          <Typography variant="subtitle1">{props.createdAt}</Typography>
                        </Grid>
                        {props.durationFromSubmission && (
                          <Grid item>
                            <Typography variant="subtitle1" fontSize={14}>
                              &nbsp;({props.durationFromSubmission})
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {props.patientAccountNumber && (
                      <Grid item xs={12} md={props.completedAt ? 3 : 3.5}>
                        {/* Account Number */}
                        <Typography fontWeight="bold" noWrap>
                          {props.patientAccountNumber}
                        </Typography>
                      </Grid>
                    )}
                    {props.patientFullName && (
                      <Grid item xs={12} md={props.completedAt ? 3 : 5}>
                        {/* Full Name */}
                        <Typography fontWeight="bold">{props.patientFullName || '-'}</Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item justifyContent="flex-end">
                <Box>
                  <SmallButton
                    noMargin
                    fullWidth
                    urgent={props.urgent}
                    showLoading={props.showButtonLoading}
                    onClick={props.onButtonClick}
                    endIcon={<ArrowRightIcon />}
                  >
                    {props.linkLabel}
                  </SmallButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="center">
            <SmallButton disabled noMargin endIcon={<DownloadCloudIcon />}>
              Download Report
            </SmallButton>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

ReviewsAccordion.propTypes = {
  createdAt: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  patientAccountNumber: PropTypes.string,
  patientFullName: PropTypes.string,
};

export default ReviewsAccordion;
