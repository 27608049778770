import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import FadeIn from 'react-fade-in';

import { styled } from '@mui/material/styles';
import { Grid, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// LOCAL UI IMPORTS
import SmallButton from '../../../components/layout/atoms/SmallButton';
import InfoToolTip from '../../../components/layout/atoms/InfoToolTip';
import ResultsToolTip from '../../../components/layout/molecules/ResultsToolTip';
import ExamsAccordion from '../../../components/layout/molecules/ExamsAccordion';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import Loading from '../../../components/layout/organisms/Loading/Loading';

// ICON IMPORTS
import { PlusIcon, UserCheckIcon } from '../../../resources/icons';

import { createExam } from '../../../actions/exams';
import { setSnackbar } from '../../../actions/controls';
import { getPatientExams } from '../../../actions/patients';
import { convertToCustomDateString } from '../../../utils/date';
import { EXAM_ORDERED, EXAM_PENDING, EXAM_SUBMITTED } from '../../../constants/constants';

const StyledExamsGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '359px',
  padding: '0 4px',
  overflowY: 'auto',
}));

// FUNCTIONAL COMPONENT
const PatientExams = (props) => {
  const { patientDetails, exams, createExam, getPatientExams } = props;
  // ROUTER LINKING
  // To link to another route, use -> props.history.push('/route_name/')
  const history = useHistory();
  const [selectedExam, setSelectedExam] = useState(null);
  const { words: staticWords, styles: lanStyle } = useSelector((state) => state.handlingTranslation);
  const { promiseInProgress } = usePromiseTracker({ area: 'patient-exams-area' });
  // COMPONENT STATE
  // const [values, setValues] = React.useState({ value: 'value' });

  // FUNCTIONS
  // const handleChange = (event) => {
  //     setValues({ ...values, [event.target.name]: event.target.value });
  // }

  const handleNewExamClick = () => {
    createExam(patientDetails, history);
  };

  const handleExamClick = (event, exam) => {
    event.preventDefault();
    const { id } = exam;
    const { status } = exam.process;

    if (status === EXAM_PENDING) {
      history.push(`/patient/${patientDetails?.id}/create_exam/${id}/`);
    } else if (status === EXAM_SUBMITTED) {
      history.push(`/patient/${patientDetails?.id}/exam_details/${id}/`);
    }
  };

  useEffect(() => {
    if (patientDetails?.exams && exams?.exam_details?.id) {
      const targetExam = patientDetails?.exams?.find((exam) => exam.id === exams.exam_details.id);
      if (
        targetExam?.process?.overall_status !== exams.exam_details.overall_status ||
        targetExam?.process?.status !== exams.exam_details.process?.status
      ) {
        getPatientExams(patientDetails.id);
      }
    }
  }, [exams?.exam_details?.id, patientDetails?.id]);
  useEffect(() => {
    getPatientExams(patientDetails.id);
  }, [patientDetails?.exams?.length]);

  // FUNCTIONS END

  // DISPLAY EXAMS
  const renderExamsList = () => {
    return (
      <FadeIn>
        <Grid container mt={2}>
          <Grid item container display="flex" justifyContent="space-between" alignItems="center" xs={12} pr={3} pl={3}>
            <Grid
              item
              container
              xs={lanStyle.component_ExamAccordion.All_Exams?.xs?.sectionOne}
              xl={lanStyle.component_ExamAccordion.All_Exams?.xl?.sectionOne}
            >
              <Grid item xs={lanStyle.component_PatientExams.label_1.width}>
                <Typography textOverflow="ellipsis" overflow="hidden">
                  {staticWords.Exam_Date}
                </Typography>
              </Grid>
              <Grid item xs={lanStyle.component_PatientExams.label_2.width}>
                <Typography>{staticWords.Practitioner}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              display="flex"
              alignItems="center"
              gap={1}
              xs={lanStyle.component_ExamAccordion.All_Exams?.xs?.sectionTwo}
              xl={lanStyle.component_ExamAccordion.All_Exams?.xl?.sectionTwo}
            >
              <ResultsToolTip />
              <Box component="div">
                <Typography>{staticWords.Status}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {patientDetails.exams.map((exam) => {
              return (
                <ExamsAccordion
                  key={exam.id}
                  title={'All Exams'}
                  status={exam.process?.overall_status}
                  operator={exam.operator}
                  dateString={convertToCustomDateString(exam.process?.last_submitted_at, 'dd/MM/yyyy')}
                  selected={selectedExam === exam.id}
                  onMouseDown={() => setSelectedExam(exam.id)}
                  onMouseLeave={() => setSelectedExam(null)}
                  onClick={(event) => handleExamClick(event, exam)}
                  expandable={false}
                />
              );
            })}
          </Grid>
        </Grid>
      </FadeIn>
    );
  };

  const renderAddExamButton = () => {
    if (patientDetails.exams) {
      return (
        <SmallButton
          onClick={handleNewExamClick}
          startIcon={<PlusIcon />}
          disabled={
            patientDetails?.exams &&
            (patientDetails?.exams[0]?.process?.status === EXAM_PENDING || patientDetails?.exams?.length >= 50)
          }
        >
          {staticWords.Start_New_Exam}
        </SmallButton>
      );
    }
  };

  return (
    <Grid container>
      {/* SECTION TITLE */}
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <IconHeading title={'All Exams'} icon={<UserCheckIcon />} />
        </Grid>
        <Grid item>
          {(patientDetails?.exams && patientDetails?.exams[0]?.process?.status) === EXAM_PENDING ||
          patientDetails?.exams?.length >= 50 ? (
            <InfoToolTip
              placement="left"
              title={staticWords.Unable_to_add_new_exam}
              content={
                patientDetails?.exams?.length >= 50
                  ? staticWords.Each_patient_can_only_have_a_maximum_of_50_exams_
                  : staticWords.Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_
              }
            >
              <div>{renderAddExamButton()}</div>
            </InfoToolTip>
          ) : (
            renderAddExamButton()
          )}
        </Grid>
        {/* EXAM LISTS */}
        <StyledExamsGrid>
          {promiseInProgress ? (
            <Grid mt={12}>
              <Loading area="patient-exams-area" />
            </Grid>
          ) : patientDetails?.exams === undefined ? null : patientDetails?.exams?.length ? (
            renderExamsList()
          ) : (
            <Typography variant="subtitle1" align="center" mt={12}>
              {staticWords.This_patient_does_not_have_any_existing_exams_}
            </Typography>
          )}
        </StyledExamsGrid>
      </Grid>
    </Grid>
  );
};

PatientExams.propTypes = {
  patientDetails: PropTypes.object,
  exams: PropTypes.object,
  createExam: PropTypes.func,
  getPatientExams: PropTypes.func,
};

// MAP REDUX STATE TO COMPONENT PROPS
// To retrieve patients from props, use -> props.patients
const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  createExam,
  setSnackbar,
  getPatientExams,
})(PatientExams);
