import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { HelmetProvider } from 'react-helmet-async';

// if (process.env.REACT_APP_STAGE === 'test') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

if (['development', 'test'].includes('development')) {
  ReactDOM.render(
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    </Provider>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>,
    document.getElementById('root')
  );
}

if (module.hot) {
  module.hot.accept(); // for webpack HMR
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
