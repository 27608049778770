import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconLabel from '../atoms/IconLabel';
import { Grid, Typography } from '@mui/material';
import { transferValueGetKey } from '../../../utils/helpers';
import { useSelector } from 'react-redux';

const StyledGrid = styled(Grid, { shouldForwardProp: (propName) => propName !== 'noMargin' })(
  ({ theme, noMargin }) => ({
    margin: noMargin ? 0 : theme.spacing(2, 0),
  })
);

const StyledTypography = styled(Typography)(({ theme, smallTitle }) => ({
  margin: smallTitle ? theme.spacing(0, 0.3) : theme.spacing(0, 1.5),
  ['&.purchase']: {
    ...theme.typography.h3,
    color: theme.palette.text.primary,
  },
}));

const IconHeading = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  return (
    <StyledGrid container justifyContent="flex-start" alignItems="center" noMargin>
      <Grid item>
        <IconLabel icon={props.icon} className={props.className_2} style={props.style_2} />
      </Grid>
      <Grid item>
        <StyledTypography component="h3" variant="h4" className={props.className}>
          {staticWords[transferValueGetKey(props.title)] || props.alternateTitle}
        </StyledTypography>
      </Grid>
    </StyledGrid>
  );
};

IconHeading.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  noMargin: PropTypes.bool,
};

export default IconHeading;
