import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { handleModalClose } from '../../../actions/controls';
import PropTypes from 'prop-types';

import LargeButton from '../atoms/LargeButton';
import { ArrowRightIcon } from '../../../resources/icons';
import IconHeading from '../molecules/IconHeading';

import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';

const PREFIX = 'Modal';

const classes = {
  form: `${PREFIX}-form`,
  centerDialogActions: `${PREFIX}-centerDialogActions`,
};

const Root = styled('div', {
  shouldForwardProp(propName) {
    return !['centerDialog'].includes(propName);
  },
})(({ theme, centerDialog }) => ({
  [`& .${classes.centerDialogActions}`]: {
    justifyContent: centerDialog && 'center',
  },
}));

const Modal = (props) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(event);
    props.handleModalClose(props.modalName);
  };

  const handleCloseModal = (event) => {
    props.handleModalClose(props.modalName);
  };

  return (
    <Root centerDialog={props.centerDialog}>
      <Dialog
        maxWidth={props.maxWidth}
        open={props.open.includes(props.modalName)}
        onClose={() => props.handleModalClose(props.modalName)}
        aria-labelledby="form-dialog-title"
        sx={props.sx}
      >
        <form style={{ margin: theme.spacing(2) }} onSubmit={handleSubmit}>
          <DialogTitle>
            <IconHeading title={props.title} icon={props.icon} />
            {props.showCloseButton ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => props.handleModalClose(props.modalName)}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            {props.description && (
              <DialogContentText className={props.descriptionSpacing}>{props.description}</DialogContentText>
            )}
            {props.children}
          </DialogContent>
          <DialogActions className={classes.centerDialogActions}>
            <Grid container spacing={1}>
              {props.secondaryButtonText && (
                <Grid item sm={props.buttonText ? 6 : 12}>
                  <LargeButton onClick={handleCloseModal} buttonType="secondary" fullWidth>
                    {props.secondaryButtonText}
                  </LargeButton>
                </Grid>
              )}
              {props.buttonText && (
                <Grid item sm={props.secondaryButtonText ? 6 : 12}>
                  <LargeButton
                    buttonType="primary"
                    color="blue"
                    type="submit"
                    endIcon={props.noButtonIcon ? '' : <ArrowRightIcon />}
                    fullWidth
                  >
                    {props.buttonText}
                  </LargeButton>
                </Grid>
              )}
            </Grid>
          </DialogActions>
          {props.bottomMessage && (
            <Box
              paddingX={2}
              paddingBottom={2}
              marginTop={'-8px'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="subtitle1" color={theme.palette.warning.dark}>
                {props.bottomMessage}
              </Typography>
            </Box>
          )}
        </form>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  open: state.controls.modalOpen,
});

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  icon: PropTypes.element.isRequired,
  open: PropTypes.array.isRequired,
  modalName: PropTypes.string.isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  descriptionSpacing: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

export default connect(mapStateToProps, { handleModalClose })(Modal);
