import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Box, Typography } from '@mui/material';

import { InfoIcon } from '../../../resources/icons';
import StatusTag from '../atoms/StatusTag';
import InfoToolTip from '../atoms/InfoToolTip';
import { transferValueGetKey } from '../../../utils/helpers';
import {
  PENDING_ACTION,
  AWAITING_RESULT,
  REPORT_READY,
  STATUSES_DESCR,
  PENDING_REVIEW,
} from '../../../constants/constants';

const ToolTipItem = ({ status, description }) => {
  return (
    <Grid container key={status} wrap={'nowrap'} alignItems={'center'}>
      <Grid item xs={4.5}>
        <StatusTag small status={status} />
      </Grid>
      <Grid item xs={7.5}>
        <Typography fontSize={12} ml={1}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ResultsToolTip = () => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  return (
    <InfoToolTip
      content={
        <Grid container display="flex" direction="column" pt={1} gap={1}>
          {[PENDING_ACTION, AWAITING_RESULT, PENDING_REVIEW, REPORT_READY].map((status, index) => {
            return (
              <ToolTipItem
                key={index}
                status={status}
                description={staticWords[transferValueGetKey(STATUSES_DESCR[status])]}
              />
            );
          })}
        </Grid>
      }
      sx={{ display: 'flex', alignItems: 'center' }}
      maxWidth="400px"
    >
      <Box component="div">
        <InfoIcon width={16} height={16} />
      </Box>
    </InfoToolTip>
  );
};

export default ResultsToolTip;
