import React from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';
import { PlusSquareIcon, MinusSquareFilledIcon } from '../../../resources/icons';

const PREFIX = 'YellowCheckbox';

const classes = {
  checkbox: `${PREFIX}-checkbox`,
  checked: `${PREFIX}-checked`,
  mandatory: `${PREFIX}-mandatory`,
};

const StyledMinusSquareFilledIcon = styled(MinusSquareFilledIcon)(({ theme }) => ({
  [`& .${classes.checkbox}`]: {
    '&$checked': {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.checked}`]: {
    //has to be empty for fill to work
  },

  [`& .${classes.mandatory}`]: {
    '&$checked': {
      color: theme.palette.primary.light,
      opacity: 0.35,
    },
  },
}));

const YellowCheckbox = (props) => {
  return (
    <Checkbox
      icon={props.addOn && <PlusSquareIcon />}
      checkedIcon={props.addOn && <StyledMinusSquareFilledIcon />}
      classes={{
        root: props.checked && props.disabled ? classes.mandatory : classes.checkbox,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default YellowCheckbox;
