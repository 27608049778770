import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  SERVICE_NOT_PURCHASED,
  EXAM_SUBMITTED,
  EXAM_PENDING,
  PENDING_REVIEW,
  // AWAITING_RESULTS,
  REPORT_READY,
  // DONE,
  PENDING_ACTION,
  AWAITING_RESULT,
  PATIENT,
} from '../../../constants/constants';

const getVariantStyle = (theme, variant) => {
  switch (variant) {
    case 'Incomplete':
      return {
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.grey[300],
      };
    case 'Warning':
      return {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error.light,
      };
    case 'Pending':
      return {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
      };
    case 'Complete':
      return {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      };
    default:
      return {
        color: theme.palette.grey.A200,
        backgroundColor: theme.palette.grey.A100,
      };
  }
};

const Root = styled('div')(({ theme, variant, width, small }) => ({
  width: width || '100%',
  minWidth: small ? '75px' : '150px',
  padding: small ? theme.spacing(0.5) : theme.spacing(1),
  textTransform: 'uppercase',
  borderRadius: small ? theme.shape.borderRadiusSmall : theme.shape.borderRadiusMedium,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ...getVariantStyle(theme, variant),
}));

const StatusTag = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const { t, i18n } = useTranslation();
  const { status, user, width, small = false } = props;

  const [variant, setVariant] = useState('');
  const [tagText, setTagText] = useState('');

  useEffect(() => {
    switch (status) {
      case SERVICE_NOT_PURCHASED:
        setVariant('');
        setTagText(staticWords.NOT_ADDED);
        break;
      case PENDING_ACTION:
        if (user === PATIENT) {
          setVariant('Incomplete');
          setTagText(staticWords.PENDING);
        } else {
          setVariant('Warning');
          setTagText(staticWords.PENDING_ACTION);
        }
        break;
      case PENDING_REVIEW:
        if (user === PATIENT) {
          setVariant('Incomplete');
          setTagText(staticWords.PENDING);
        } else {
          setVariant('Pending');
          setTagText(staticWords.PENDING_REVIEW);
        }
        break;
      case AWAITING_RESULT:
        setVariant('Incomplete');
        user === PATIENT ? setTagText(staticWords.PENDING) : setTagText(staticWords.AWAITING_RESULT);
        break;
      case REPORT_READY:
        setVariant('Complete');
        setTagText(staticWords.REPORT_READY);
        break;
      default:
        setVariant('');
        setTagText('');
        break;
    }
  }, [status, user.role, staticWords]);

  return (
    <Root width={width} variant={variant} small={small}>
      <Typography variant="h5" fontSize={small ? '9px' : '13px'} textAlign="center">
        {tagText}
      </Typography>
    </Root>
  );
};

StatusTag.propTypes = {
  status: PropTypes.oneOf([SERVICE_NOT_PURCHASED, PENDING_ACTION, AWAITING_RESULT, PENDING_REVIEW, REPORT_READY])
    .isRequired,
  user: PropTypes.any.isRequired,
  width: PropTypes.any,
  small: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StatusTag);
