import axiosInstance from '../axios';
import {
  SET_SNACKBAR_SUCCESS,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_INFO,
  GET_PATIENT_RISK_SERVICES,
  STDR_RISK_PARAM,
  DR_RISK_PARAM,
} from './types';
import { DR_RISK, STDR_RISK } from '../constants/constants';
import { getBackendURL } from '../utils/urls';
import { t } from 'i18next';
import { transferValueGetKey } from '../utils/helpers';

// To check whether the patient information is enough for either
// risk prediction service. Changes prop information to tell
// other page that services are unable to run with incorrect parameters
// in the error message.
export const checkServiceInput = (patient_id) => async (dispatch) => {
  try {
    await axiosInstance.post(`services/dr_risk_service/check_input/${patient_id}/`).then((result) => {
      dispatch({
        type: DR_RISK_PARAM,
        payload: result.data,
      });
    });
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload: error.response?.data?.errors?.[0]?.message,
    });
  }

  try {
    await axiosInstance.post(`services/retina_risk_service/check_input/${patient_id}/`).then((result) => {
      dispatch({
        type: STDR_RISK_PARAM,
        payload: result.data,
      });
    });
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload: error.response?.data?.errors?.[0]?.message,
    });
  }
};

// To submit either/both risk prediction services for processing. It sets a snackbar if successful and
// pushes to the risk analysis page. Also updates the current list of services and the credits to ensure
// the page that is loaded is correct and up to date.
export const submitServices =
  (account_number, history = null, patient_id, services) =>
  async (dispatch) => {
    if (history) {
      history.push(`/patient/${account_number}/risk_analysis/`);
    }

    // submit Retina Risk service processing if called and push to the risk analysis page
    if (services.has(STDR_RISK)) {
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: 'Successfully added STDR Risk Prediction',
      });
      try {
        await axiosInstance.post(`services/retina_risk_service/update/${patient_id}/`).then((result) => {
          dispatch({
            type: SET_SNACKBAR_INFO,
            // payload: `You have a ${result.data.risk_value}% risk of developing sight threatening diabetic retinopathy in the next 12 months
            // and should have an eye screening every ${result.data.screening_interval} months. ${result.data.message}`
            payload: 'STDR Risk Prediction report ready',
          });
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: error.response?.data?.errors?.[0]?.message,
        });
      }
    }
    // submit DR Risk service processing if called and push to the risk analysis page
    if (services.has(DR_RISK)) {
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: 'Successfully added DR Risk Prediction',
      });
      try {
        await axiosInstance.post(`services/dr_risk_service/update/${patient_id}/`).then((result) => {
          dispatch({
            type: SET_SNACKBAR_INFO,
            payload: 'DR Risk Prediction report ready',
          });
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: error.response?.data?.errors?.[0]?.message,
        });
      }
    }

    // update the list of risk prediction services that for the patient
    axiosInstance
      .get(`/services/risk_prediction_service/list/${patient_id}/`)
      .then((result) => {
        dispatch({
          type: GET_PATIENT_RISK_SERVICES,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(
              transferValueGetKey(
                "There was a problem when retrieving patient's exams. Please refresh the page to try again."
              )
            ),
        });
      });
  };

// Gets the risk prediction service report related to a specific service. This function opens a new window to the
// generated report.
export const getRiskReport =
  (patient_id, service_type, download = false, disableDownload, service_id) =>
  (dispatch) => {
    dispatch({
      type: SET_SNACKBAR_INFO,
      payload: 'Loading report...',
    });

    if (disableDownload) {
      axiosInstance
        .get(`${getBackendURL()}/api/services/report/pdf/${patient_id}/${service_type}/${service_id}`)
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              'There was a problem downloading report. Please try again later.',
          });
        });
    } else {
      window.open(`${getBackendURL()}/api/services/report/pdf/${patient_id}/${service_type}/${service_id}`, '_blank');
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: 'Report successfully loaded!',
      });
    }
  };
